import "./Transition.scss";
import { useEffect, useRef } from "react";
import gsap from "gsap";
import useStore from "../../store";

function Transition(props) {
  const tl = useRef();
  const el = useRef();
  const bar = useRef();

  const loadProgress = useStore((state) => state.loadProgress);
  const loaded = useStore((state) => state.loaded);
  const setLoaded = useStore((state) => state.setLoaded);
  const fontsLoaded = useStore((state) => state.fontsLoaded);
  const setFontsLoaded = useStore((state) => state.setFontsLoaded);
  const contentLoaded = useStore((state) => state.contentLoaded);

  useEffect(() => {
    const fontLoading = (e) => {
      console.log("Font loading:", e);
    };

    const fontLoaded = (e) => {
      console.log("Font loaded:", e);
    };

    // document.fonts.addEventListener("loading", fontLoading);
    // document.fonts.addEventListener("loadingdone", fontLoaded);

    document.fonts.ready.then((fontFaceSet) => {
      // Any operation that needs to be done only after all used fonts
      // have finished loading can go here.
      const fontFaces = [...fontFaceSet];
      // set fontsLoaded
      console.log("fonts loaded");
      setFontsLoaded(true);
      //console.log(fontFaces);
      // // some fonts may still be unloaded if they aren't used on the site
      // console.log(fontFaces.map((f) => f.status));
    });

    tl.current = gsap.timeline({ delay: 0.0 });
    tl.current.fromTo(
      bar.current,
      {
        width: "0%",
      },
      { width: "100%", duration: 10.0, ease: "circ.in" }
    );

    return () => {
      // document.fonts.removeEventListener("loading", fontLoading);
      // document.fonts.removeEventListener("loadingdone", fontLoaded);
    };
  }, []);

  useEffect(() => {
    if (fontsLoaded && contentLoaded) setLoaded(true);
  }, [fontsLoaded, contentLoaded]);

  useEffect(() => {
    if (loaded) {
      if (tl.current) tl.current.pause();
      tl.current = gsap.timeline({ delay: 0.5 });
      tl.current.to(bar.current, {
        width: "100%",
        duration: 0.5,
        ease: "circ.in",
      });
      tl.current.to(bar.current, { autoAlpha: 0 });
      tl.current.fromTo(
        el.current,
        {
          background:
            "linear-gradient(0deg, #F4E9E1 50%, transparent 50%, transparent 50%, #F4E9E1 50%)",
        },
        {
          background:
            "linear-gradient(0deg, #F4E9E1 0%, transparent 0.1%, transparent 99.9%, #F4E9E1 100%)",
          duration: 1.0,
          ease: "circ.in",
        }
      );
      tl.current.set(el.current, { display: "none" });
    }
  }, [loaded]);

  return (
    <div ref={el} className="transition">
      <div ref={bar} className="bar"></div>
    </div>
  );
}

export default Transition;
