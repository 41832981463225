import "./CaseStudyProjectAria.scss";
import { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import MediaBlock from "../MediaBlock/MediaBlock";
import BigDivider from "../BigDivider/BigDivider";
import useStore from "../../store";
import ImageRow from "../ImageRow/ImageRow";
import RoundedDivider from "../RoundedDivider/RoundedDivider";
import Quotes from "../Quotes/Quotes";
import ColorDivider from "../ColorDivider/ColorDivider";
import BigDividerVolta from "../BigDividerVolta/BigDividerVolta";
import MediaBlockAnimatedVolta from "../MediaBlockAnimatedVolta/MediaBlockAnimatedVolta";
import VoltaTitleBlock from "../VoltaTitleBlock/VoltaTitleBlock";
import VoltaMediaGrid from "../VoltaMediaGrid/VoltaMediaGrid";
import BigDividerVoltaAdvanced from "../BigDividerVoltaAdvanced/BigDividerVoltaAdvanced";
import BigDividerVoltaSplit from "../BigDividerVoltaSplit/BigDividerVoltaSplit";
import VoltaBarGraph from "../VoltaBarGraph/VoltaBarGraph";
import MetaTitleBlock from "../MetaTitleBlock/MetaTitleBlock";
import VideoPlayerBlock from "../VideoPlayerBlock/VideoPlayerBlock";
import ImageSequenceBlock from "../ImageSequenceBlock/ImageSequenceBlock";

function CaseStudyProjectAria(props) {
  const activeCaseStudy = useStore((state) => state.activeCaseStudy);

  const tl = useRef();
  const el = useRef();

  const sectionStart = useRef();
  const sectionEnd = useRef();

  const [isActive, setIsActive] = useState(false);

  useEffect(() => {}, []);

  useEffect(() => {
    //console.log("active Case Study", activeCaseStudy);
    if (tl.current) tl.current.pause();
    // console.log("activeCaseStudy", activeCaseStudy, props.index);
    tl.current = gsap.timeline({
      onComplete: () => {
        ScrollTrigger.refresh();
      },
    });
    if (activeCaseStudy === null) {
      tl.current.call(() => {
        document.body.classList.remove("greyBackground");
      });
    }

    if (activeCaseStudy === props.index) {
      tl.current.call(() => {
        document.body.classList.add("greyBackground");
      });
      tl.current.to([el.current], {
        height: "auto",
        overflow: "unset",
        ease: "circ.in",
        duration: 0,
      });
      tl.current.to(props.el.current, {
        duration: 0.0,
        scrollTo: el.current.offsetTop - (window.innerHeight / 100) * 36,
        ease: "Power1.easeInOut",
      });
    } else {
      //tl.current.set([el.current], { height: "0" });
      if (
        el.current.offsetHeight > 0 &&
        props.el.current.scrollTop > el.current.offsetTop
      ) {
        tl.current.set(
          props.el.current,
          {
            scrollTo: props.el.current.scrollTop - el.current.offsetHeight,
          },
          0
        );
        tl.current.set(
          el.current,
          {
            height: 0,
            overflow: "hidden",
          },
          0
        );
      } else {
        tl.current.to(
          [el.current],
          {
            height: 0,
            ease: "circ.out",
            duration: 0.5,
            overflow: "hidden",
            // delay: 0.5,
          },
          0
        );
      }
    }
  }, [activeCaseStudy]);

  // useEffect(() => {
  //   console.log(isActive, props.index, activeCaseStudy);
  //   if (isActive && props.index === activeCaseStudy) {
  //     document.body.classList.add("greyBackground");
  //   } else {
  //     document.body.classList.remove("greyBackground");
  //   }
  // }, [isActive]);

  // useEffect(() => {
  //   ScrollTrigger.create({
  //     scroller: props.el.current,
  //     // markers: true,
  //     trigger: sectionStart.current,
  //     start: "top 22%",
  //     endTrigger: sectionEnd.current,
  //     end: "bottom top",
  //     invalidateOnRefresh: true,
  //     onToggle: (self) => {
  //       // console.log("toggled, isActive:", self.isActive);
  //       setIsActive(self.isActive);
  //     },
  //     onUpdate: (self) => {
  //       // console.log("progress:", self.progress.toFixed(3));
  //       //setProgress(parseFloat(self.progress.toFixed(3)));
  //     },
  //   });
  // }, []);

  return (
    <div
      id={"case-study-" + props.data.id}
      ref={el}
      className={`case-study-project-aria`}
    >
      <div ref={sectionStart} className="section-start"></div>
      <MediaBlock
        data={props.data.blocks[0]}
        section={props.data.title}
        count={1}
        color={"white"}
        background={"metaBlue"}
        style={""}
        hideSectionLabels={true}
        total={10}
      />
      <ImageRow data={props.data.blocks[1]} />
      {/* <RoundedDivider
        data={props.data.blocks[2]}
        background={"white"}
        color={"black"}
      /> */}
      <MediaBlock
        data={props.data.blocks[3]}
        section={props.data.title}
        count={2}
        color={"black"}
        background={"white"}
        style={""}
        hideSectionLabels={false}
        total={10}
      />
      <MetaTitleBlock
        data={props.data.blocks[4]}
        section={props.data.title}
        count={3}
        background={"metaBlue"}
        color={"black"}
        total={10}
        specialIndex={1}
      />
      <VideoPlayerBlock
        data={props.data.blocks[5]}
        section={props.data.title}
        count={4}
        color={"black"}
        background={""}
        style={""}
        total={10}
      />
      <BigDivider
        data={props.data.blocks[6]}
        background={"metaBlue"}
        color={"black"}
        font={"SFPro"}
      />
      <BigDivider
        data={props.data.blocks[7]}
        background={"metaBlue"}
        color={"black"}
        font={"SFPro"}
      />
      <ImageSequenceBlock
        data={props.data.blocks[8]}
        section={props.data.title}
        count={5}
        color={"black"}
        background={"white"}
        style={""}
        hideSectionLabels={false}
        total={10}
        el={props.el}
      />
      <RoundedDivider
        data={props.data.blocks[9]}
        background={"white"}
        color={"black"}
      />
      <ImageRow data={props.data.blocks[10]} />
      <MetaTitleBlock
        data={props.data.blocks[11]}
        section={props.data.title}
        count={6}
        background={"metaBlue"}
        color={"black"}
        total={10}
        specialIndex={2}
      />
      <MediaBlock
        data={props.data.blocks[12]}
        section={props.data.title}
        count={7}
        color={"white"}
        background={""}
        style={"full-bleed tight"}
        hideSectionLabels={true}
        total={10}
      />
      <MetaTitleBlock
        data={props.data.blocks[13]}
        section={props.data.title}
        count={8}
        background={"metaBlue"}
        color={"black"}
        total={10}
        specialIndex={3}
      />
      <MediaBlock
        data={props.data.blocks[14]}
        section={props.data.title}
        count={9}
        color={"white"}
        background={""}
        style={"full-bleed tight"}
        hideSectionLabels={true}
        total={10}
      />
      <MetaTitleBlock
        data={props.data.blocks[15]}
        section={props.data.title}
        count={10}
        background={"metaBlue"}
        color={"black"}
        total={10}
        specialIndex={4}
      />
      <Quotes
        data={props.data.blocks[16]}
        background={"beige"}
        color={"black"}
        highlight={"ariatBlue"}
        count={11}
        total={props.data.blocks.length + 1}
      />
      <div ref={sectionEnd} className="section-end"></div>
    </div>
  );
}

export default CaseStudyProjectAria;
