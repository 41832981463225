import "./PeopleRow.scss";
import { useEffect, useRef } from "react";
import gsap from "gsap";

function PeopleRow(props) {
  const tl = useRef();
  const arrowsLeft = useRef();
  const arrowsRight = useRef();

  useEffect(() => {
    // tl.current = gsap.timeline({});
    // tl.current.fromTo(
    //   [arrowsLeft.current, arrowsRight.current],
    //   { y: -6, autoAlpha: 0 },
    //   {
    //     y: 6,
    //     autoAlpha: 1,
    //     repeat: -1,
    //     duration: 1,
    //     ease: "sine.out",
    //   }
    // );
  }, []);

  return (
    <div id={props.id} className={`people-row ${props.color}`}>
      {props.data.people.map((p, i) => {
        return (
          <div className="people-card" key={"people-card-" + i}>
            <h3>{p.role}</h3>
            {p.media && p.mediaType === "video" && (
              <div className={`media video`}>
                <video
                  autoPlay
                  loop
                  muted
                  playsInline
                  src={p.media}
                  type="video/mp4"
                />
              </div>
            )}
            {p.mediaType === "image" && (
              <img src={p.image} alt={p.role + " - " + p.name} />
            )}
            <h4 className={`${props.highlight}`}>{p.name}</h4>
          </div>
        );
      })}
    </div>
  );
}

export default PeopleRow;
