import "./ImageRow.scss";
import { useEffect, useRef } from "react";
import gsap from "gsap";
import { Player } from "@lottiefiles/react-lottie-player";

function ImageRow(props) {
  const tl = useRef();
  const arrowsLeft = useRef();
  const arrowsRight = useRef();

  useEffect(() => {
    // tl.current = gsap.timeline({});
    // tl.current.fromTo(
    //   [arrowsLeft.current, arrowsRight.current],
    //   { y: -6, autoAlpha: 0 },
    //   {
    //     y: 6,
    //     autoAlpha: 1,
    //     repeat: -1,
    //     duration: 1,
    //     ease: "sine.out",
    //   }
    // );
  }, []);

  return (
    <div
      id={props.id}
      className={`image-row ${props.color} ${props.data.style}`}
    >
      {props.data.images.map((p, i) => {
        return (
          <div
            className={`image-card ${p.background}Background ${p.style}`}
            key={"image-card-" + i}
          >
            <img src={p.image} alt="" className={`${p.style}`} />
            {p.media && p.mediaType === "lottie" && (
              <div className={`media lottie ${p.mediaStyle}`}>
                <Player src={p.media} loop autoplay />
              </div>
            )}
            {p.media && p.mediaType === "video" && (
              <div className={`media video ${p.mediaStyle}`}>
                <video
                  autoPlay
                  loop
                  muted
                  playsInline
                  // src={p.media}
                  // type="video/mp4"
                >
                  <source src={p.media} type={p.mediaFileType || ""} />
                  {/* {p.media2 && (
                    <source src={p.media2} type={p.mediaFileType2 || ""} />
                  )} */}
                </video>
              </div>
            )}
            {p.media && p.mediaType === "complex" && (
              <div className={`media complex ${p.style}`}>
                <img src={p.media} alt="" className={`media-image`} />

                <img src={p.media2} alt="" className={`media-image-2`} />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default ImageRow;
