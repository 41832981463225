import "./SectionIntro.scss";
import { useEffect, useRef } from "react";
import gsap from "gsap";

function SectionIntro(props) {
  const tl = useRef();

  useEffect(() => {
    // tl.current = gsap.timeline({});
    // tl.current.fromTo(
    //   logo.current,
    //   { autoAlpha: 0, y: 20 },
    //   { autoAlpha: 1, duration: 2, y: 0 }
    // );
  }, []);

  return (
    <div
      id={props.id}
      className={`section-intro ${props.background}Background ${props.color}`}
    >
      <div
        className={`section-number ${props.color}`}
      >{`0${props.data.index}`}</div>
      <h1 className="title">{props.data.title1}</h1>
    </div>
  );
}

export default SectionIntro;
