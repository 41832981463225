import "./Divider.scss";
import { Fragment, useEffect, useRef } from "react";
import gsap from "gsap";
import parse from "html-react-parser";
import { Player } from "@lottiefiles/react-lottie-player";

function Divider(props) {
  const tl = useRef();
  const arrowsLeft = useRef([]);
  const arrowsRight = useRef([]);

  useEffect(() => {
    if (!arrowsLeft.current[0]) return;
    tl.current = gsap.timeline({ repeat: -1, repeatDelay: 1 });
    tl.current.call(
      () => {
        arrowsLeft.current[0].play();
        arrowsRight.current[0].play();
      },
      null,
      0
    );
    tl.current.call(
      () => {
        arrowsLeft.current[1].play();
        arrowsRight.current[1].play();
      },
      null,
      0.1
    );
    tl.current.call(
      () => {
        arrowsLeft.current[2].play();
        arrowsRight.current[2].play();
      },
      null,
      0.2
    );
  }, [arrowsLeft.current[0]]);

  return (
    <div
      id={props.id}
      className={`divider ${props.background}Background ${props.color}`}
    >
      <span ref={arrowsLeft} className={`arrows left ${props.arrowColor}`}>
        {!props.hideArrows && (
          <Fragment>
            <Player
              ref={(el) => (arrowsLeft.current[0] = el)}
              src={props.data.arrow}
              className={`arrow first`}
            />
            <Player
              ref={(el) => (arrowsLeft.current[1] = el)}
              src={props.data.arrow}
              className="arrow"
            />
            <Player
              ref={(el) => (arrowsLeft.current[2] = el)}
              src={props.data.arrow}
              className={`arrow last`}
            />
          </Fragment>
        )}
      </span>

      <h1 className="title">{parse(props.data.title)}</h1>

      <span ref={arrowsRight} className={`arrows right ${props.arrowColor}`}>
        {!props.hideArrows && (
          <Fragment>
            <Player
              ref={(el) => (arrowsRight.current[0] = el)}
              src={props.data.arrow}
              className={`arrow first`}
            />
            <Player
              ref={(el) => (arrowsRight.current[1] = el)}
              src={props.data.arrow}
              className="arrow"
            />
            <Player
              ref={(el) => (arrowsRight.current[2] = el)}
              src={props.data.arrow}
              className={`arrow last`}
            />
          </Fragment>
        )}
      </span>
    </div>
  );
}

export default Divider;
