import "./Content.scss";
import { Fragment, useEffect, useRef } from "react";
import { data } from "../../data/data.js";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Landing from "../Landing/Landing";
import SectionDivider from "../SectionDivider/SectionDivider";
import SectionIntro from "../SectionIntro/SectionIntro";

import Work from "../Work/Work";
import useStore from "../../store";
import TitleBlock from "../TitleBlock/TitleBlock";
import TextBlock from "../TextBlock/TextBlock";
import MissionBlock from "../MissionBlock/MissionBlock";
import HelloMediaBlock from "../HelloMediaBlock/HelloMediaBlock";
import Divider from "../Divider/Divider";
import PeopleRow from "../PeopleRow/PeopleRow";
import DividerAlt from "../DividerAlt/DividerAlt";
import Quotes from "../Quotes/Quotes";
import MediaBlock from "../MediaBlock/MediaBlock";
import TabbedBlock from "../TabbedBlock/TabbedBlock";
import CapabilitiesBlock from "../CapabilitiesBlock/CapabilitiesBlock";
import WorkTitleBlock from "../WorkTitleBlock/WorkTitleBlock";
import CaseStudyPreviewCard from "../CaseStudyPreviewCard/CaseStudyPreviewCard";
import TalentTitleBlock from "../TalentTitleBlock/TalentTitleBlock";
import TalentPeopleRow from "../TalentPeopleRow/TalentPeopleRow";
import CareersTitleBlock from "../CareersTitleBlock/CareersTitleBlock";
import CareersListBlock from "../CareersListBlock/CareersListBlock";
import ContactPeopleRow from "../ContactPeopleRow/ContactPeopleRow";
import UnusualSubscribeBlock from "../UnusualSubscribeBlock/UnusualSubscribeBlock";
import Footer from "../Footer/Footer";
import BigDivider from "../BigDivider/BigDivider";
import CaseStudy7ElevenRewards from "../CaseStudy7ElevenRewards/CaseStudy7ElevenRewards";
import CaseStudyVoltaCharging from "../CaseStudyVoltaCharging/CaseStudyVoltaCharging";
import CaseStudyProjectAria from "../CaseStudyProjectAria/CaseStudyProjectAria";
import CaseStudyMetaAI from "../CaseStudyMetaAI/CaseStudyMetaAI.js";
import CaseStudyHowToQuit from "../CaseStudyHowToQuit/CaseStudyHowToQuit.js";

function Content() {
  const el = useRef();
  const tl = useRef();
  const logo = useRef();

  const sectionStart = useRef([]);
  const sectionEnd = useRef([]);

  // const progress = useStore((state) => state.progress);
  const mobileProgress = useStore((state) => state.mobileProgress);
  const setProgress = useStore((state) => state.setProgress);
  const setMobileContentProgress = useStore(
    (state) => state.setMobileContentProgress
  );
  const mobileNavActive = useStore((state) => state.mobileNavActive);
  const setMobileNavActive = useStore((state) => state.setMobileNavActive);
  const setActiveSection = useStore((state) => state.setActiveSection);
  const navTo = useStore((state) => state.navTo);

  const activeCaseStudy = useStore((state) => state.activeCaseStudy);

  useEffect(() => {
    //console.log("nav called", navTo);
    if (navTo === null || navTo === undefined) return;
    gsap.set(el.current, {
      scrollTop: sectionStart.current[navTo].offsetTop - 24,
    });
  }, [navTo]);

  useEffect(() => {
    if (!mobileNavActive) return;
    //console.log(mobileProgress);
    el.current.scroll(0, el.current.scrollHeight * mobileProgress);
  }, [mobileProgress]);

  useEffect(() => {
    // set up scroll triggers for sections and progress
    setTimeout(() => {
      data.navigation.forEach((n, i) => {
        ScrollTrigger.create({
          scroller: el.current,
          //markers: true,
          trigger: sectionStart.current[i],
          start: "top 30%",
          endTrigger: sectionEnd.current[i],
          end: "bottom 30%",
          invalidateOnRefresh: true,
          onToggle: (self) => {
            // console.log("toggled, isActive:", self.isActive, i);
            if (self.isActive) setActiveSection(i);
          },
          onUpdate: (self) => {
            // console.log("progress:", self.progress.toFixed(3), i);
            setProgress(parseFloat(self.progress.toFixed(3)));
          },
        });
      });

      ScrollTrigger.create({
        scroller: el.current,
        invalidateOnRefresh: true,
        onUpdate: (self) => {
          //console.log("mobile content progress:", self.progress.toFixed(3));
          setMobileContentProgress(parseFloat(self.progress.toFixed(3)));
        },
      });
    }, 2000);

    setTimeout(() => {
      ScrollTrigger.refresh();
    }, 5000);
    setTimeout(() => {
      ScrollTrigger.refresh();
    }, 10000);

    gsap.config({ nullTargetWarn: false });
  }, []);

  const scrolling = (e) => {
    setMobileNavActive(false);
  };

  return (
    <div ref={el} className="content" onTouchStart={scrolling}>
      {/* LANDING */}
      <div className="landing-section section">
        <div
          ref={(el) => (sectionStart.current[0] = el)}
          className="section-start"
        ></div>
        <Landing />
        <div
          ref={(el) => (sectionEnd.current[0] = el)}
          className="section-end"
        ></div>
      </div>
      {/* HELLO */}
      <div className="hello-section section">
        <div
          ref={(el) => (sectionStart.current[1] = el)}
          className="section-start"
        ></div>
        <SectionDivider
          data={data.hello}
          background={"orange"}
          color={"offwhite"}
        />
        <SectionIntro
          data={data.hello}
          background={"orange"}
          color={"offwhite"}
        />

        <TitleBlock
          data={data.hello.blocks[0]}
          section={data.hello.title1}
          count={1}
          color={"orange"}
          total={2}
        />
        <TextBlock
          data={data.hello.blocks[1]}
          section={data.hello.title1}
          count={1}
          color={"offwhite"}
          background={"orange"}
          total={3}
        />

        <MissionBlock
          data={data.hello.blocks[2]}
          section={data.hello.title1}
          count={1}
          color={"offwhite"}
          highlight={"orange"}
          total={4}
        />
        <HelloMediaBlock
          data={data.hello.blocks[3]}
          section={data.hello.title1}
          count={1}
          color={"offwhite"}
          highlight={"offwhite"}
          background={"orange"}
          total={5}
        />
        <Divider
          data={data.hello.blocks[4]}
          background={"orange"}
          color={"offwhite"}
          arrowColor={"yellow"}
        />

        <PeopleRow
          data={data.hello.blocks[5]}
          color={"black"}
          highlight={"orange"}
          count={6}
          total={data.hello.blocks.length + 1}
        />
        <DividerAlt
          data={data.hello.blocks[6]}
          color={"black"}
          highlight={"orange"}
        />
        <Quotes
          data={data.hello.blocks[7]}
          color={"black"}
          highlight={"orange"}
          count={6}
          total={data.hello.blocks.length + 1}
        />
        <div
          ref={(el) => (sectionEnd.current[1] = el)}
          className="section-end"
        ></div>
      </div>
      {/* APPROACH */}
      <div className="approach-section section">
        <div
          ref={(el) => (sectionStart.current[2] = el)}
          className="section-start"
        ></div>
        <SectionDivider
          data={data.approach}
          background={"purple"}
          color={"offwhite"}
        />
        <SectionIntro
          data={data.approach}
          background={"purple"}
          color={"offwhite"}
        />
        <MediaBlock
          data={data.approach.blocks[0]}
          section={data.approach.title1}
          count={2}
          color={"offwhite"}
          background={"purple"}
          total={2}
        />
        <MissionBlock
          data={data.approach.blocks[1]}
          section={data.approach.title1}
          count={2}
          color={"offwhite"}
          highlight={"purple"}
          total={3}
        />
        {/* <MediaBlock
        data={data.approach.blocks[2]}
        section={data.approach.title1}
        count={3}
        color={"offwhite"}
        background={"purple"}
        style={"full"}
        total={data.approach.blocks.length + 1}
      /> */}
        <BigDivider
          data={data.approach.blocks[2]}
          background={"purple"}
          color={"offwhite"}
          highlightColor={"black"}
          hideSmallText={true}
        />
        <BigDivider
          data={data.approach.blocks[3]}
          background={"purple"}
          color={"offwhite"}
          highlightColor={"black"}
        />
        <BigDivider
          data={data.approach.blocks[4]}
          background={"purple"}
          color={"offwhite"}
          highlightColor={"black"}
        />
        <Divider
          data={data.approach.blocks[5]}
          background={"purple"}
          color={"offwhite"}
          arrowColor={"green"}
        />
        <TabbedBlock
          data={data.approach.blocks[6]}
          section={data.approach.title1}
          count={2}
          color={"black"}
          highlight={"purple"}
          highlightAlt={"offwhite"}
          total={4}
        />
        <BigDivider
          data={data.approach.blocks[7]}
          count={7}
          background={"purple"}
          color={"offwhite"}
          highlightColor={"black"}
        />
        <CapabilitiesBlock
          data={data.approach.blocks[8]}
          section={data.approach.title1}
          count={8}
          color={"black"}
          highlight={"purple"}
          highlightAlt={"offwhite"}
          total={data.approach.blocks.length + 1}
        />

        <div
          ref={(el) => (sectionEnd.current[2] = el)}
          className="section-end"
        ></div>
      </div>
      {/* WORK */}
      <div className="work-section section">
        <div
          ref={(el) => (sectionStart.current[3] = el)}
          className="section-start"
        ></div>
        <SectionDivider
          data={data.work}
          background={"black"}
          color={"offwhite"}
          altColor={"grey"}
        />
        <SectionIntro
          data={data.work}
          background={"black"}
          color={"offwhite"}
        />
        {/* <WorkTitleBlock
        data={data.work.blocks[0]}
        section={data.work.title1}
        count={1}
        color={"offwhite"}
        highlight={"black"}
        highlightAlt={"offwhite"}
        total={data.work.blocks.length + 1}
      /> */}
        {data.work.projects.map((p, i) => {
          // TODO: Add More Case Studies here as they are built out
          return (
            <Fragment key={"casestudypreview" + i}>
              <CaseStudyPreviewCard
                data={p}
                index={i}
                el={el}
                elBottom={sectionEnd.current[3]}
              />
              {p.id === "7-eleven-mobile" && (
                <CaseStudy7ElevenRewards data={p} index={i} el={el} />
              )}
              {p.id === "volta-charging" && (
                <CaseStudyVoltaCharging data={p} index={i} el={el} />
              )}
              {p.id === "meta-ai" && (
                <CaseStudyMetaAI data={p} index={i} el={el} />
              )}
              {p.id === "project-aria" && (
                <CaseStudyProjectAria data={p} index={i} el={el} />
              )}
              {p.id === "how-to-quit" && (
                <CaseStudyHowToQuit data={p} index={i} el={el} />
              )}
            </Fragment>
          );
        })}
        <div
          ref={(el) => (sectionEnd.current[3] = el)}
          className="section-end"
        ></div>
      </div>
      {/* TALENT */}
      <div className="talent-section section">
        <div
          ref={(el) => (sectionStart.current[4] = el)}
          className="section-start"
        ></div>
        <SectionDivider
          data={data.talent}
          background={"blue"}
          color={"offwhite"}
        />
        <SectionIntro
          data={data.talent}
          background={"blue"}
          color={"offwhite"}
        />
        <MediaBlock
          data={data.talent.blocks[0]}
          section={data.talent.title1}
          count={4}
          color={"offwhite"}
          background={"blue"}
          highlight={"offwhite"}
          total={2}
        />
        <TextBlock
          data={data.talent.blocks[1]}
          section={data.talent.title1}
          count={4}
          color={"offwhite"}
          background={"blue"}
          total={3}
        />
        <TalentTitleBlock
          data={data.talent.blocks[2]}
          section={data.talent.title1}
          count={4}
          color={"blue"}
          total={4}
        />
        {/* <TalentPeopleRow
        data={data.talent.blocks[3]}
        color={"black"}
        highlight={"black"}
        count={4}
        total={data.talent.blocks.length + 1}
      /> */}
        <Divider
          data={data.talent.blocks[4]}
          background={"blue"}
          color={"offwhite"}
          arrowColor={"red"}
        />
        <TabbedBlock
          data={data.talent.blocks[5]}
          section={data.talent.title1}
          count={4}
          color={"black"}
          highlight={"blue"}
          highlightAlt={"offwhite"}
          total={5}
        />
        <div
          ref={(el) => (sectionEnd.current[4] = el)}
          className="section-end"
        ></div>
      </div>
      {/* CAREERS */}
      <div className="careers-section section">
        <div
          ref={(el) => (sectionStart.current[5] = el)}
          className="section-start"
        ></div>
        <SectionDivider
          data={data.careers}
          background={"red"}
          color={"offwhite"}
        />
        <SectionIntro
          data={data.careers}
          background={"red"}
          color={"offwhite"}
        />
        <CareersTitleBlock
          data={data.careers.blocks[0]}
          section={data.careers.title1}
          count={1}
          color={"red"}
          total={data.careers.blocks.length + 1}
        />
        <Divider
          data={data.careers.blocks[1]}
          background={"red"}
          color={"offwhite"}
          arrowColor={"black"}
        />
        <CareersListBlock
          data={data.careers.blocks[2]}
          section={data.careers.title1}
          count={2}
          color={"red"}
          total={data.careers.blocks.length + 1}
        />
        <div
          ref={(el) => (sectionEnd.current[5] = el)}
          className="section-end"
        ></div>
      </div>
      {/* CONTACT */}
      <div className="contact-section section">
        <div
          ref={(el) => (sectionStart.current[6] = el)}
          className="section-start"
        ></div>
        <SectionDivider
          data={data.contact}
          background={"yellow"}
          color={"black"}
        />
        <SectionIntro
          data={data.contact}
          background={"yellow"}
          color={"black"}
        />
        <TitleBlock
          data={data.contact.blocks[0]}
          section={data.contact.title1}
          count={6}
          color={"black"}
          total={2}
        />
        <ContactPeopleRow
          data={data.contact.blocks[1]}
          color={"black"}
          highlight={"yellow"}
          count={2}
          total={data.contact.blocks.length + 1}
        />
        <Divider
          data={data.contact.blocks[2]}
          background={"yellow"}
          color={"black"}
          arrowColor={"orange"}
          hideArrows={true}
        />
        <div
          ref={(el) => (sectionEnd.current[6] = el)}
          className="section-end"
        ></div>
      </div>
      {/* UNUSUAL INDEX */}
      <div className="unusual-section section">
        <div
          ref={(el) => (sectionStart.current[7] = el)}
          className="section-start"
        ></div>
        <SectionDivider
          data={data.unusualIndex}
          background={"green"}
          color={"black"}
        />
        <SectionIntro
          data={data.unusualIndex}
          background={"green"}
          color={"black"}
        />
        <MediaBlock
          data={data.unusualIndex.blocks[0]}
          section={data.unusualIndex.title1}
          count={7}
          color={"black"}
          highlight={"black"}
          total={2}
          hideSectionLabels={true}
        />
        <TextBlock
          data={data.unusualIndex.blocks[1]}
          section={data.unusualIndex.title1}
          count={7}
          color={"black"}
          background={"green"}
          total={3}
        />
        <Divider
          data={data.unusualIndex.blocks[2]}
          background={"green"}
          color={"black"}
          arrowColor={"black"}
        />
        <UnusualSubscribeBlock
          data={data.unusualIndex.blocks[3]}
          section={data.unusualIndex.title1}
          count={3}
          color={"black"}
          background={"green"}
          total={data.unusualIndex.blocks.length + 1}
        />
        <div
          ref={(el) => (sectionEnd.current[7] = el)}
          className="section-end"
        ></div>
      </div>
      <Footer data={data.footer} />
    </div>
  );
}

export default Content;
