import "./SectionDivider.scss";
import { useEffect, useRef } from "react";
import gsap from "gsap";

function SectionDivider(props) {
  const tl = useRef();

  useEffect(() => {
    // tl.current = gsap.timeline({});
    // tl.current.fromTo(
    //   logo.current,
    //   { autoAlpha: 0, y: 20 },
    //   { autoAlpha: 1, duration: 2, y: 0 }
    // );
  }, []);

  return (
    <div
      id={props.data.id}
      className={`section-divider ${props.background}Background ${props.color}`}
    >
      <h1 className="title">{props.data.sectionTitle}</h1>
      <div
        className={`section-number ${
          props.altColor ? props.altColor : "black"
        } `}
      >{`● 0${props.data.index} / 01`}</div>
    </div>
  );
}

export default SectionDivider;
