import "./HelloMediaBlock.scss";
import {
  Suspense,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import parse from "html-react-parser";
import gsap from "gsap";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import {
  OrbitControls,
  TorusKnot,
  useAnimations,
  useCursor,
  useGLTF,
} from "@react-three/drei";
import { AsciiEffect } from "three-stdlib";
// import * as THREE from "three";

function AsciiRenderer({
  renderIndex = 1,
  bgColor = "black",
  fgColor = "white",
  characters = " .:-+*=%@#,",
  invert = true,
  color = false,
  resolution = 0.14,
}) {
  // Reactive state
  const { size, gl, scene, camera } = useThree();

  // Create effect
  const effect = useMemo(() => {
    const effect = new AsciiEffect(gl, characters, {
      invert,
      color,
      resolution,
    });
    effect.domElement.style.position = "absolute";
    effect.domElement.style.top = "0px";
    effect.domElement.style.left = "0px";
    effect.domElement.style.pointerEvents = "none";
    return effect;
  }, [characters, invert, color, resolution]);

  // Styling
  useLayoutEffect(() => {
    effect.domElement.style.color = fgColor;
    effect.domElement.style.backgroundColor = bgColor;
  }, [fgColor, bgColor]);

  // Append on mount, remove on unmount
  useEffect(() => {
    gl.domElement.style.opacity = "0";
    gl.domElement.parentNode.appendChild(effect.domElement);
    return () => {
      gl.domElement.style.opacity = "1";
      gl.domElement.parentNode.removeChild(effect.domElement);
    };
  }, [effect]);

  // Set size
  useEffect(() => {
    effect.setSize(size.width, size.height);
  }, [effect, size]);

  // Take over render-loop (that is what the index is for)
  useFrame((state) => {
    effect.render(scene, camera);
  }, renderIndex);

  // This component returns nothing, it is a purely logical
}

function Model({ ...props }) {
  const ref = useRef();
  const { invalidate, camera, gl } = useThree();

  //const { scene, animations } = useGLTF("./assets/models/logo-cube.glb");
  // const { actions, mixer } = useAnimations(animations, scene);

  useEffect(() => {
    // scene.traverse((mesh, i) => {
    //   mesh.material = new THREE.MeshPhongMaterial({ color: "white" });
    //   mesh.frustumCulled = false;
    // });
    // actions["animation_0"].timeScale = 0.5;
    // actions["animation_0"].play();
  }, []);

  useFrame((state, delta) => {
    // console.log("render");
    ref.current.rotation.x += 0.004;
    ref.current.rotation.z -= 0.002;
    if (!props.isPaused) invalidate();
  });

  useEffect(() => {
    if (!props.isPaused) invalidate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isPaused]);
  // useCursor(hovered);

  return (
    <mesh {...props} ref={ref}>
      {/* <primitive object={scene} /> */}
      <torusKnotGeometry args={[1, 0.2, 128, 32]} />
      <meshStandardMaterial color="orange" />
    </mesh>
  );
}

function HelloMediaBlock(props) {
  const tl = useRef();
  const ref = useRef();
  useEffect(() => {}, []);

  const observer = useRef();
  const canvasRef = useRef();

  const [isPaused, setIsPaused] = useState(true);

  useEffect(() => {
    observer.current = new IntersectionObserver(
      function (entries) {
        if (entries[0].isIntersecting === true) {
          //console.log('Element is visible in screen');
          setIsPaused(false);
        } else {
          //console.log('Element is not visible in screen');
          setIsPaused(true);
        }
      },
      { threshold: [0] }
    );

    const ref = canvasRef.current;
    observer.current.observe(ref);

    return () => observer.current.unobserve(ref);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      id={props.id}
      className={`hello-media-block ${
        props.background ? props.background : ""
      }Background`}
    >
      <h1 className={`wins ${props.color}`}>{props.data.title}</h1>
      <Suspense fallback={null}>
        <Canvas
          camera={{ fov: 50 }}
          ref={canvasRef}
          frameloop="demand"
          resize={{ scroll: false }}
        >
          <color attach="background" args={["black"]} flat />
          <ambientLight intensity={0.5} />
          <directionalLight position={[10, 10, 10]} intensity={0.5} />
          <Model
            isPaused={isPaused}
            position={[0, 0, 0]}
            // scale={[0.005, 0.005, 0.005]}
            scale={[2, 2, 2]}
          />
          <OrbitControls enableZoom={false} />
          <AsciiRenderer
            isPaused={isPaused}
            fgColor={"#000000"}
            bgColor={"#FF3D00"}
          />
        </Canvas>
      </Suspense>
      <div className="section-labels">
        <div
          className={`section-title ${props.highlight}`}
        >{`(${props.section})`}</div>
        <div className={`section-number`}>{`●  ${
          props.count < 10 ? "0" + props.count : props.count
        } / ${props.total < 10 ? "0" + props.total : props.total}`}</div>
      </div>
    </div>
  );
}

export default HelloMediaBlock;
