import "./UnusualSubscribeBlock.scss";
import { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import parse from "html-react-parser";

function UnusualSubscribeBlock(props) {
  const tl = useRef();
  const positions = useRef([]);
  const [activePosition, setActivePosition] = useState(0);
  const subscribeForm = useRef();
  const email = useRef();
  const subscribeListItem = useRef();
  const thanks = useRef();

  useEffect(() => {
    // tl.current = gsap.timeline({});
    // tl.current.fromTo(
    //   logo.current,
    //   { autoAlpha: 0, y: 20 },
    //   { autoAlpha: 1, duration: 2, y: 0 }
    // );
  }, []);

  const subscribeButtonClicked = (e) => {
    e.preventDefault();
    // validate if req
    if (!email.current.value) {
      alert("Please enter an email address.");
      email.current.focus();
      return false;
    } else {
      const validRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (email.current.value.match(validRegex)) {
        var data = new FormData(subscribeForm.current);
        data.append("mce-EMAIL", email.current.value);
        data.append("EMAIL", email.current.value);
        // console.log(data);
        fetch(props.data.url, { method: "post", mode: "no-cors", body: data })
          .then((res) => res.text())
          .then((txt) => {
            // do something when server responds
            // console.log(txt);
            thanks.current.classList.add("show");
            subscribeListItem.current.classList.add("hide");
          })
          .catch((err) => console.log(err));
        return false;
      } else {
        alert("Please enter an valid email address.");
        email.current.focus();
        return false;
      }
    }
  };

  return (
    <div className={`unusual-subscribe-block`}>
      <div className={`subscribe-list-item`} ref={subscribeListItem}>
        <form
          ref={subscribeForm}
          className="subscribe-form"
          onSubmit={subscribeButtonClicked}
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
        >
          <div className="item">
            <input
              type="email"
              name="EMAIL"
              id="mce-EMAIL"
              ref={email}
              className={`title subscribe-title`}
              placeholder={parse(props.data.title)}
            />
          </div>
          <input type="hidden" name="tags" value="4170357,4170361" readOnly />
          <div
            style={{ position: "absolute", left: "-5000px", ariaHidden: true }}
          >
            <input
              type="text"
              name="b_4d8f5a11007230e3136b358e6_51c2278739"
              value=""
              readOnly
            />
          </div>

          {/* <input
            type="submit"
            value="Subscribe"
            name="subscribe"
            id="mc-embedded-subscribe"
            class="button"
          /> */}
          <button
            id="subscribe-button"
            onClick={subscribeButtonClicked}
            className="subscribe-button"
          >
            {props.data.subscribe}
          </button>
        </form>
      </div>
      <div ref={thanks} className="thanks">
        {props.data.thanks}
      </div>

      {/* <div id="mc_embed_signup">
        <form
          action="https://handsome.us5.list-manage.com/subscribe/post?u=4d8f5a11007230e3136b358e6&amp;id=51c2278739&amp;f_id=009674ebf0"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          class="validate"
          target="_self"
        >
          <div id="mc_embed_signup_scroll">
            <div class="indicates-required">
              <span class="asterisk">*</span> indicates required
            </div>
            <div class="mc-field-group">
              <label for="mce-EMAIL">
                Email Address <span class="asterisk">*</span>
              </label>
              <input
                type="email"
                name="EMAIL"
                class="required email"
                id="mce-EMAIL"
                required
              />
              <span id="mce-EMAIL-HELPERTEXT" class="helper_text"></span>
            </div>
            <div hidden="true">
              <input type="hidden" name="tags" value="4170357,4170361" />
            </div>
            <div id="mce-responses" class="clear">
              <div
                class="response"
                id="mce-error-response"
                style={{ display: "none" }}
              ></div>
              <div
                class="response"
                id="mce-success-response"
                style={{ display: "none" }}
              ></div>
            </div>
            <div
              style={{
                position: "absolute",
                left: "-5000px",
                ariaHidden: true,
              }}
              aria-hidden="true"
            >
              <input
                type="text"
                name="b_4d8f5a11007230e3136b358e6_51c2278739"
                tabindex="-1"
                value=""
              />
            </div>
            <div class="clear">
              <input
                type="submit"
                value="Subscribe"
                name="subscribe"
                id="mc-embedded-subscribe"
                class="button"
              />
            </div>
          </div>
        </form>
      </div> */}
    </div>
  );
}

export default UnusualSubscribeBlock;
