import "./VideoPlayerBlock.scss";
import { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { Player } from "@lottiefiles/react-lottie-player";
import parse from "html-react-parser";
import gsap from "gsap";

function VideoPlayerBlock(props) {
  const tl = useRef();
  const lottiePlayer = useRef();
  const el = useRef();
  const cover = useRef();
  const playButton = useRef();

  const [playing, setPlaying] = useState(false);
  const [paused, setPaused] = useState(true);

  useEffect(() => {
    new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          // callback(element);
          // observer.disconnect();
          //setPlaying(true);
        } else {
          setPlaying(false);
        }
      });
    }).observe(el.current);
  }, []);

  const playVideo = () => {
    if (tl.current) tl.current.pause();
    tl.current = gsap.timeline();
    tl.current.to([cover.current, playButton.current], {
      autoAlpha: 0,
      duration: 1,
    });
    setPlaying(true);
  };

  return (
    <div
      ref={el}
      id={props.id}
      className={`video-player-block ${
        props.background ? props.background : ""
      }Background ${props.data.style}`}
    >
      <img
        className={`image ${props.data.style}`}
        src={props.data.image}
        alt=""
        onClick={playVideo}
        ref={cover}
      />
      <img
        src={"../../assets/project-aria/video-play-button.svg"}
        alt="play"
        className="play-button"
        ref={playButton}
        onClick={playVideo}
      />
      <div className={`media ${props.data.style}`}>
        <ReactPlayer
          className="react-player"
          url={props.data.media}
          width="100%"
          height="100%"
          controls
          // config={{
          //   vimeo: {
          //     playerOptions: {},
          //   },
          // }}
          // onPause={() => {
          //   setPaused(true);
          // }}
          onPlay={() => {
            setPlaying(true);
          }}
          // controls={true}
          playing={playing}
        />
      </div>
    </div>
  );
}

export default VideoPlayerBlock;
