import "./CapabilitiesBlock.scss";
import { useEffect, useRef, useState } from "react";
import parse from "html-react-parser";
import gsap from "gsap";

function CapabilitiesBlock(props) {
  const tl = useRef();

  const tabs = useRef([]);
  const tabPanels = useRef([]);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    // tl.current = gsap.timeline({});
    // tl.current.fromTo(
    //   logo.current,
    //   { autoAlpha: 0, y: 20 },
    //   { autoAlpha: 1, duration: 2, y: 0 }
    // );
  }, []);

  useEffect(() => {
    tl.current = gsap.timeline({});
    tl.current.to(tabPanels.current, { autoAlpha: 0, duration: 0.5 });
    tl.current.to(tabPanels.current[activeTab], {
      autoAlpha: 1,
      duration: 0.5,
    });
  }, [activeTab]);

  const tabClicked = (e) => {
    const index = parseInt(e.currentTarget.getAttribute("data-index")) || 0;
    setActiveTab(index);
  };

  return (
    <div id={props.id} className={`capabilities-block`}>
      {props.data.sets.map((t, i) => {
        return (
          <div key={"tag-block-" + i} className="tag-block">
            <div className="title-row">
              <h1>{t.title}</h1>
              <span className="title-number">{t.number}</span>
            </div>
            <div className="tag-list">
              {t.tags.map((tag, i) => {
                return (
                  <div
                    key={"tag-block-tag-" + i}
                    className={`tag ${i === 0 ? "first" : ""}`}
                  >
                    {tag}
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default CapabilitiesBlock;
