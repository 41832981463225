import "./Landing.scss";
import { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { data } from "../../data/data.js";
import { Player } from "@lottiefiles/react-lottie-player";
import parse from "html-react-parser";
import useStore from "../../store.js";

function Landing() {
  const tl = useRef();
  const logo = useRef();
  const [logoFile, setLogoFile] = useState();

  const setContentLoaded = useStore((state) => state.setContentLoaded);

  if (!logoFile) {
    setLogoFile(
      data.landing.logos[Math.floor(Math.random() * data.landing.logos.length)]
        .file
    );
  }

  useEffect(() => {
    tl.current = gsap.timeline({});
    tl.current.fromTo(
      logo.current,
      { autoAlpha: 0, y: 20 },
      { autoAlpha: 1, duration: 2, y: 0 }
    );

    if (!logoFile) {
      setLogoFile(
        data.landing.logos[
          Math.floor(Math.random() * data.landing.logos.length)
        ].file
      );
    }
  }, []);
  return (
    <div className="landing">
      <div className="box">
        <div className="landing-header-row">
          <h1 className="landing-header-row-left">
            {parse(data.landing.title1)}
          </h1>
          <h1 className="landing-header-row-right">
            {parse(data.landing.title2)}
          </h1>
        </div>
        {/* <img className="landing-logo" src={LandingLogo} alt="" /> */}
        <div className={`landing-logo lottie padding-fix`}>
          <Player
            src={logoFile}
            loop
            autoplay
            onEvent={(event) => {
              if (event === "load") {
                console.log("lottie ready");
                setContentLoaded(true);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Landing;
