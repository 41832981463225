import "./CareersTitleBlock.scss";
import { useEffect, useRef } from "react";
import gsap from "gsap";
import parse from "html-react-parser";

function CareersTitleBlock(props) {
  const tl = useRef();

  useEffect(() => {
    // tl.current = gsap.timeline({});
    // tl.current.fromTo(
    //   logo.current,
    //   { autoAlpha: 0, y: 20 },
    //   { autoAlpha: 1, duration: 2, y: 0 }
    // );
  }, []);

  return (
    <div id={props.id} className={`careers-title-block`}>
      {props.data.title.map((t, i) => {
        return (
          <h1
            key={props.id + "careerstitletblock" + i}
            className={`title ${t.color ? t.color : ""} ${
              t.background ? t.background + "Background" : ""
            }`}
          >
            {parse(t.text)}
          </h1>
        );
      })}
      {/* <div className="section-labels">
        <div
          className={`section-title ${props.color}`}
        >{`(${props.section})`}</div>
        <div
          className={`section-number`}
        >{`●  0${props.count} / 0${props.total}`}</div>
      </div> */}
    </div>
  );
}

export default CareersTitleBlock;
