import "./BigDividerVoltaAdvanced.scss";
import { useEffect, useRef } from "react";
import gsap from "gsap";
import { data } from "../../data/data";

function BigDividerVoltaAdvanced(props) {
  const tl = useRef();
  const arrowsLeft = useRef();
  const arrowsRight = useRef();

  useEffect(() => {
    // tl.current = gsap.timeline({});
    // tl.current.fromTo(
    //   [arrowsLeft.current, arrowsRight.current],
    //   { y: -6, autoAlpha: 0 },
    //   {
    //     y: 6,
    //     autoAlpha: 1,
    //     repeat: -1,
    //     duration: 1,
    //     ease: "sine.out",
    //   }
    // );
  }, []);

  return (
    <div
      id={props.id}
      className={`big-divider-volta-advanced ${props.background}Background ${
        props.color
      } ${props.data.style ? props.data.style : ""}`}
    >
      {props.data.style && props.data.style.includes("right-align") && (
        <img src={props.data.icon} alt="" className="icon" />
      )}

      <div className="titles">
        <h2 className={`sub-title ${props.color || ""}`}>
          {props.data.subtitle}
        </h2>
        <h1 className={`title ${props.color || ""}`}>{props.data.title}</h1>
      </div>

      {(!props.data.style || !props.data.style.includes("right-align")) && (
        <img src={props.data.icon} alt="" className="icon" />
      )}
      <img src={props.data.smallIcon} alt="" className="small-icon" />
    </div>
  );
}

export default BigDividerVoltaAdvanced;
