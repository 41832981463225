import "./ImageSequenceBlock.scss";
import { useEffect, useRef, useState } from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import parse from "html-react-parser";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

function ImageSequenceBlock(props) {
  const tl = useRef();
  // const vidRef = useRef();
  const el = useRef();

  const canvas = useRef();
  const renderIndex = useRef(0);
  const imageFiles = useRef([]);

  const [paused, setPaused] = useState(false);

  const render = () => {
    if (paused) return;
    if (imageFiles.current[renderIndex.current]) {
      const ctx = canvas.current.getContext("2d");
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
      const img = imageFiles.current[renderIndex.current];
      const scale = Math.min(
        canvas.current.width / img.width,
        canvas.current.height / img.height
      );
      const x = canvas.current.width / 2 - (img.width / 2) * scale;
      const y = canvas.current.height / 2 - (img.height / 2) * scale;
      ctx.drawImage(img, x, y, img.width * scale, img.height * scale);
    }
  };

  useEffect(() => {
    for (let i = props.data.startFrame; i <= props.data.endFrame; i++) {
      const p = new Image();
      p.src = props.data.path + i + "." + props.data.ext;
      imageFiles.current[i] = p;
    }
    //console.log("ere");
    ScrollTrigger.create({
      scroller: props.el.current,
      // markers: true,
      trigger: el.current,
      start: "top 70%",
      endTrigger: el.current,
      end: "bottom 70%",
      invalidateOnRefresh: true,
      onToggle: (self) => {
        // console.log("toggled, isActive:", self.isActive);
        setPaused(!self.isActive);
      },
      onUpdate: (self) => {
        //console.log("progres:", self.progress.toFixed(3));
        renderIndex.current = Math.floor(
          self.progress * imageFiles.current.length
        );
        render();
        //setProgress(parseFloat(self.progress.toFixed(3)));
      },
    });

    return () => {
      imageFiles.current = null;
    };
  }, []);

  return (
    <div
      id={props.id}
      className={`image-sequence-block ${
        props.background ? props.background : ""
      }Background ${props.data.style}`}
      ref={el}
    >
      {/* <video
        src={props.data.media}
        muted={true}
        playsInline={true}
        loop
        autoPlay={true}
        className="media video"
        ref={vidRef}
      /> */}
      <canvas
        ref={canvas}
        width={1920}
        height={1080}
        className={`media video`}
      />
      {!props.hideSectionLabels && (
        <div className="section-labels">
          <div
            className={`section-title ${props.highlight}`}
          >{`(${props.section})`}</div>
          <div className={`section-number`}>{`●  ${
            props.count < 10 ? "0" + props.count : props.count
          } / ${props.total < 10 ? "0" + props.total : props.total}`}</div>
        </div>
      )}
    </div>
  );
}

export default ImageSequenceBlock;
