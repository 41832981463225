import "./BigDividerVoltaSplit.scss";
import { useEffect, useRef } from "react";
import gsap from "gsap";

function BigDividerVoltaSplit(props) {
  const tl = useRef();
  const arrowsLeft = useRef();
  const arrowsRight = useRef();

  useEffect(() => {
    // tl.current = gsap.timeline({});
    // tl.current.fromTo(
    //   [arrowsLeft.current, arrowsRight.current],
    //   { y: -6, autoAlpha: 0 },
    //   {
    //     y: 6,
    //     autoAlpha: 1,
    //     repeat: -1,
    //     duration: 1,
    //     ease: "sine.out",
    //   }
    // );
  }, []);

  return (
    <div
      id={props.id}
      className={`big-divider-volta-split ${props.font ? props.font : ""} ${
        props.data.style ? props.data.style : ""
      }`}
    >
      {(props.data.title1 || props.data.icon1) && (
        <div
          className={`cell-1 cell ${props.background}Background ${props.color}`}
        >
          {props.data.title1 && (
            <h1 className={`title ${props.font ? props.font : ""}`}>
              {props.data.title1}
            </h1>
          )}
          {props.data.icon1 && <img src={props.data.icon1} alt="" />}
        </div>
      )}

      {(props.data.title2 || props.data.icon2) && (
        <div
          className={`cell-2 cell ${props.background2}Background ${props.color}`}
        >
          {props.data.title2 && (
            <h1 className={`title ${props.font ? props.font : ""}`}>
              {props.data.title2}
            </h1>
          )}
          {props.data.icon2 && <img src={props.data.icon2} alt="" />}
        </div>
      )}

      {(props.data.title3 || props.data.icon3) && (
        <div
          className={`cell-3 cell ${props.background3}Background ${props.color}`}
        >
          {props.data.title3 && (
            <h1 className={`title ${props.font ? props.font : ""}`}>
              {props.data.title3}
            </h1>
          )}
          {props.data.icon3 && <img src={props.data.icon3} alt="" />}
        </div>
      )}
    </div>
  );
}

export default BigDividerVoltaSplit;
