import "./RoundedDivider.scss";
import { useEffect, useRef } from "react";
import gsap from "gsap";
import parse from "html-react-parser";

function RoundedDivider(props) {
  const tl = useRef();
  const arrowsLeft = useRef();
  const arrowsRight = useRef();

  useEffect(() => {
    // tl.current = gsap.timeline({});
    // tl.current.fromTo(
    //   [arrowsLeft.current, arrowsRight.current],
    //   { y: -6, autoAlpha: 0 },
    //   {
    //     y: 6,
    //     autoAlpha: 1,
    //     repeat: -1,
    //     duration: 1,
    //     ease: "sine.out",
    //   }
    // );
  }, []);

  return (
    <div
      id={props.id}
      className={`rounded-divider ${props.background}Background ${
        props.color
      } ${props.data.style ? props.data.style : ""}`}
    >
      <span className={`text left ${props.color}`}>{props.data.left}</span>
      <h1 className={`title ${props.data.style ? props.data.style : ""}`}>
        {parse(props.data.title)}
      </h1>
      <span className={`text right ${props.color}`}>{props.data.right}</span>
    </div>
  );
}

export default RoundedDivider;
