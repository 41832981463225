import "./DividerAlt.scss";
import { useEffect, useRef } from "react";
import gsap from "gsap";

function DividerAlt(props) {
  const tl = useRef();
  const title = useRef();
  const title2 = useRef();

  useEffect(() => {
    tl.current = gsap.timeline({});
    tl.current.fromTo(
      [title.current],
      { x: "0%" },
      {
        x: "-33.333%",
        repeat: -1,
        duration: 12,
        ease: "none",
      }
    );
  }, []);

  return (
    <div id={props.id} className={`divider-alt ${props.color}`}>
      <div className={`title-left ${props.color}`}>{props.data.titleLeft}</div>
      <h1 className={`title ${props.highlight}`}>
        <div className="title-inner">
          <div ref={title} className="title-inner-inner">
            {props.data.title}
            {props.data.title}
            {props.data.title}
          </div>
        </div>
      </h1>
      <div className={`title-right ${props.color}`}>
        {props.data.titleRight}
      </div>
    </div>
  );
}

export default DividerAlt;
