const data = {
  navigation: [
    {
      title: "Raw Materials",
      slug: "",
      color: "black",
      background: "white",
      maxHeight: 104,
      mobileHeight: 308,
    },
    {
      title: "Hello",
      slug: "hello",
      color: "offwhite",
      background: "orange",
      maxHeight: 491,
      mobileHeight: 5217,
    },
    {
      title: "Approach",
      slug: "approach",
      color: "offwhite",
      background: "purple",
      maxHeight: 368,
      mobileHeight: 3806,
    },
    {
      title: "Work",
      slug: "work",
      color: "offwhite",
      background: "black",
      maxHeight: 248,
      mobileHeight: 1539,
      caseHeights: [
        16190, // 7-eleven
        19389, // volta
        24021, // meta ai
        27125, // HTQ
      ],
    },
    {
      title: "Talent",
      slug: "talent",
      color: "offwhite",
      background: "blue",
      maxHeight: 318,
      mobileHeight: 2812,
    },
    {
      title: "Careers",
      slug: "careers",
      color: "offwhite",
      background: "red",
      maxHeight: 192,
      mobileHeight: 1102,
    },
    {
      title: "Contact",
      slug: "contact",
      color: "black",
      background: "yellow",
      maxHeight: 220,
      mobileHeight: 1486,
    },
    {
      title: "Unusual Index",
      slug: "unusual-index",
      color: "black",
      background: "green",
      maxHeight: 159,
      mobileHeight: 1675,
    },
  ],

  landing: {
    index: 0,
    pageTitle: "Raw Materials | An Unusual Design Company",
    pageDescription:
      "We design and launch unusually creative digital products that change brands' fortunes.",
    title1: "Raw<br / >Materials",
    title2: "An Unusual<br />Design Company",

    logos: [
      { file: "../../assets/animations/RM_LivingHold_Explore_A1_Lottie.json" },
      { file: "../../assets/animations/RM_LivingHold_Explore_B1_Lottie.json" },
      { file: "../../assets/animations/RM_LivingHold_Explore_C1_Lottie.json" },
      { file: "../../assets/animations/RM_LivingHold_Explore_D1_Lottie.json" },
      { file: "../../assets/animations/RM_Logotype_Explore_A1_Lottie.json" },
      { file: "../../assets/animations/RM_Logotype_Explore_B1_Lottie.json" },
      { file: "../../assets/animations/RM_Logotype_Explore_C1_Lottie.json" },
      { file: "../../assets/animations/RM_Logotype_Explore_D1_Lottie.json" },
      { file: "../../assets/animations/RM_Logotype_Explore_E1_Lottie.json" },
      { file: "../../assets/animations/RM_Logotype_Explore_F1_Lottie.json" },
    ],
  },

  hello: {
    index: 1,
    id: "hello",
    name: "hello",
    sectionTitle: "You are now entering ( Hello ) section",
    pageTitle: "We Are Raw Materials | Unusual Wins",
    pageDescription:
      "We're building an unusual kind of creative company, to help ambitious leaders escape the culture of conformity and design a better way forward.",
    title1: "Hello",
    blocks: [
      {
        type: "TitleBlock",
        title: [
          { text: "We Are", color: "black", align: "justify" },
          { text: "Raw", color: "orange", align: "right" },
          { text: "Materials", color: "orange" },
        ],
        smallText:
          "We design and launch unusually creative digital products that change brands’ fortunes.",
      },
      {
        type: "TextBlock",
        text: [
          {
            text: "Product design needs to be a creative endeavor.",
            color: "offwhite",
          },
          {
            text: "Optimization and incremental thinking are leading to the death of innovation and a lack of originality.",
            color: "offwhite",
          },
          {
            text: "When everything is the same, being different is the greatest opportunity.",
            color: "offwhite",
          },
        ],
        smallText:
          "We design and launch unusually creative digital products that change brands’ fortunes.",
      },
      {
        type: "MissionBlock",
        text: [
          {
            title: "Our Mission",
            text: "We are building Raw Materials to:",
            color: "black",
          },
          {
            number: "01",
            title: "Challenge",
            text: "Help ambitious leaders escape the <span>culture of conformity.</span>",
            color: "black",
          },
          {
            number: "02",
            title: "Goal",
            text: "Design a better <span>way forward.</span>",
            color: "black",
          },
        ],
        smallText:
          "We design and launch unusually creative digital products that change brands’ fortunes.",
      },
      {
        type: "HelloMediaBlock",
        title: "Unusual Wins",
      },
      {
        type: "Divider",
        title:
          "WE'VE SPENT DECADES HELPING TO MAKE THE RULES, NOW WE'RE BREAKING THEM.",
        arrow: "../../assets/animations/Arrow.json",
      },
      {
        type: "PeopleRow",
        people: [
          {
            role: "Chief Operating Officer",
            name: "Jennifer Allen",
            image: "../../assets/people/jennifer-allen.png",
            media: "../../assets/people/jennifer-1.mp4",
            mediaType: "video",
          },
          {
            role: "Chief Creative Officer",
            name: "Pablo Marques",
            image: "../../assets/people/pablo-marques.png",
            media: "../../assets/people/pabs-1.mp4",
            mediaType: "video",
          },
          {
            role: "Chief Executive Officer",
            name: "John Roescher",
            image: "../../assets/people/john-roescher.png",
            media: "../../assets/people/john-1.mp4",
            mediaType: "video",
          },
        ],
      },
      {
        type: "DividerAlt",
        titleLeft: "(Raw)",
        title:
          "We’re united by a singular vision to create an unusual kind of creative company... ",
        titleRight: "(Materials)",
      },
      {
        type: "Quotes",
        quotes: [
          {
            image: "../../assets/animations/M peacock v02.json",
            imageType: "lottie",
            client: "Peacock TV",
            title: "Design Partnership",
            quote:
              "“Peacock has an incredible design team. Our partnership brings the best out of one other, and this relationship has shaped much about how RM thinks.”",
            tags: ["PRODUCT & UX", "ENTERTAINMENT"],
            by: "from",
            attribute: "Pablo Marques",
            attributeRole: "Chief Creative Officer,",
            attributeCompany: "Raw Materials",
          },
          {
            image: "../../assets/animations/M Meta export.json",
            imageType: "lottie",
            client: "Meta",
            title: "Meta AI Rebrand",
            quote:
              "“A pleasure to work with, the META team are the kind of partners who bring us the challenges we long for; complex, interesting problems at the very edge of technology.”",
            tags: ["BRAND DESIGN", "ARTIFICIAL INTELLIGENCE"],
            by: "from",
            attribute: "Jennifer Allen",
            attributeRole: "Chief Operating Officer,",
            attributeCompany: "Raw Materials",
          },
        ],
      },
    ],
  },

  approach: {
    index: 2,
    id: "approach",
    pageTitle: "Approach | Raw Materials",
    sectionTitle: "You are now entering ( Approach ) section",
    pageDescription:
      "Rigor & Romance. Method and imagination in equal measure, to unlock creativity in product design. Because better is different.",
    title1: "Approach",
    blocks: [
      {
        type: "MediaBlock",
        // text: "Better is",
        media: "../../assets/images/appraoch-block-1.png",
        mediaMobile: "../../assets/images/appraoch-block-1-mobile.png",
        mediaType: "image",
        style: "larger topPadLarger bottomPadLarger",
      },
      {
        type: "MissionBlock",
        text: [
          {
            number: "01",
            title: "Challenge",
            text: "<span>Rigor & Romance.</span> Method and imagination in equal measure.",
            color: "black",
          },
          {
            number: "02",
            title: "Goal",
            text: "<span>Finding the best talent</span> in the world and helping them make the best work of their lives.",
            color: "black",
          },
        ],
      },
      // {
      //   type: "MediaBlock",
      //   media: "../../assets/images/appraoch-block-2.png",
      //   mediaMobile: "../../assets/images/appraoch-block-2-mobile.png",
      //   mediaType: "image",
      //   style: "larger topPadLarger bottomPad",
      // },
      {
        type: "BigDivider",
        title: "Independent in",
        left: "(Raw)",
        right: "(Materials)",
      },
      {
        type: "BigDivider",
        title: "Ownership",
        left: "(Materials)",
        right: "(Raw)",
      },
      {
        type: "BigDivider",
        title: "& Spirit",
        left: "(Raw)",
        right: "(Materials)",
      },
      {
        type: "Divider",
        title: "A CULTURE BUILT FOR CONSTANT ADAPTATION.",
        arrow: "../../assets/animations/ArrowGreen.json",
      },
      {
        type: "TabbedBlock",
        tabs: [
          {
            number: "01",
            title: "One Team",
            text: "Our clients become part of <span>our team</span>, and our team a <span>part of their company</span>.",
          },
          {
            number: "02",
            title: "Creator Led",
            text: "Every team member’s higher priority is to <span>make the different</span>.",
          },
          {
            number: "03",
            title: "Phase Zero",
            text: "The project starts when we <span>first meet</span>. Every interaction shapes the work.",
          },
          {
            number: "04",
            title: "Anti Transactional",
            text: "We build long relationships, we want to become a <span>part of your journey</span>.",
          },
        ],
      },
      {
        type: "BigDivider",
        title: "Our Capabilities",
        left: "(Raw)",
        right: "(Materials)",
      },
      {
        sets: [
          {
            title: "DESIGN",
            number: "01",
            tags: [
              "Creative Direction",
              "Digital Product Design",
              "Brand Design",
              "Motion Design",
              "Experience Design",
              "Interaction Design",
              "Visual Design",
              "Prototyping",
              "Copywriting",
              "Video Production",
            ],
          },
          {
            title: "STRATEGY",
            number: "02",
            tags: [
              "Product Management",
              "Business Analysis",
              "Product Roadmapping",
              "Ecosystem Strategy",
              "Brand Strategy",
              "Consumer Research & Testing",
              "Technology Strategy",
              "Content Strategy",
              "Optimization Strategy",
              "Data Planning",
            ],
          },
          {
            title: "TECHNOLOGY",
            number: "03",
            tags: [
              "Technical Architecture",
              "Platform Development",
              "Front End Development",
              "Back End Development",
              "Mobile App Development",
              "API & CMS Development",
              "Manual & Automated QA",
              "Voice Interfaces",
              "Machine Learning",
              "Augmented & Mixed Reality",
            ],
          },
        ],
      },
    ],
  },

  work: {
    index: 3,
    id: "work",
    pageTitle: "Creativity is Good Business | Work | Raw Materials",
    sectionTitle: "You are now entering ( Work ) section",
    pageDescription:
      "We partner with leading brands like Meta, NBC Universal, Audi, and Unity to create unusually good digital products and brands.",
    title1: "Work",
    title2: "Good Design Is Good Business",
    blocks: [
      {
        type: "WorkTitleBlock",
        titles: [
          { type: "text", value: "Creativity" },
          {
            type: "media",
            value: "../../assets/images/work-titles-image-1.png",
          },
          { type: "text", value: "is" },
          {
            type: "media",
            value: "../../assets/images/work-titles-image-2.png",
          },
          { type: "text", value: "good" },
          {
            type: "media",
            value: "../../assets/images/work-titles-image-3.png",
          },
          { type: "text", value: "business" },
        ],
      },
    ],

    projects: [
      {
        title: "7-Eleven Mobile",
        id: "7-eleven-mobile",
        slug: "7-eleven",
        pageTitle: "7-Eleven Rewards | Brand Strategy | Raw Materials",
        pageDescription:
          "Combining elements from multiple apps and programs and designing a unified platform to redefine and enhance what the 7-Eleven brand means to people.",
        description:
          "End-to-end product design for 7-11’s digital consumer experience.",
        category: "Product Innovation",
        logo: "../../assets/7-eleven-rewards/7-eleven.svg",
        logoStyle: "h48",
        color: "pink",
        active: true,
        thumbs: [
          { image: "../../assets/7-eleven-rewards/7E-CS-01.jpg" },
          { image: "../../assets/7-eleven-rewards/7E-CS-03.jpg" },
          { image: "../../assets/7-eleven-rewards/7E-CS-04.jpg" },
          { image: "../../assets/7-eleven-rewards/7E-CS-02.jpg" },
          { image: "../../assets/7-eleven-rewards/7E-CS-05.jpg" },
        ],
        blocks: [
          {
            type: "MediaBlock",
            media: "../../assets/7-eleven-rewards/7-eleven-block-1.png",
            mediaMobile:
              "../../assets/7-eleven-rewards/7-eleven-block-1-mobile.png",
            mediaType: "image",
            style: "larger topPad bottomPad",
          },
          {
            type: "BigDivider",
            title: "A Convenient Future",
            left: "",
            right: "",
          },
          {
            type: "MediaBlock",
            media: "../../assets/7-eleven-rewards/7-Eleven Logo.svg",
            mediaType: "image",
            style: "full-bleed tight",
          },
          {
            type: "RobotBlock",
            media: "../../assets/animations/robot.json",
            mediaType: "animation",
            style: "full",
          },
          {
            type: "BigDivider",
            title: "For Convenience",
            left: "",
            right: "",
          },
          {
            type: "ImageRow",
            images: [
              {
                background: "lightPurple",
                image:
                  "../../assets/7-eleven-rewards/7-eleven-block-5-col-1.png",
                style: "maxWidth80",
              },
              {
                background: "gold",
                image:
                  "../../assets/7-eleven-rewards/7-eleven-block-5-col-2.png",
                style: "maxWidth80",
              },
              {
                background: "redOrange",
                image:
                  "../../assets/7-eleven-rewards/7-eleven-block-5-col-3.png",
                media: "../../assets/animations/OrderInProgress.json",
                mediaType: "lottie",
                mediaStyle: "insetTopBox",
                style: "maxWidth80",
              },
            ],
          },
          {
            type: "MediaTitleTextBlock",
            style: "paddingBottom",

            title: [
              { text: "A &nbsp; More", color: "black", align: "right2" },
              { text: "Demanding", color: "black", align: "left" },
              { text: "Audience", color: "black", align: "right2 left-mobile" },
            ],

            smallText:
              "7-11 is all about convenience.  One of the world’s most popular quick-stop chains, they have always tried to make gassing up your car and grabbing snacks and essentials as quick and easy as possible.",
            smallText2:
              "But in a world where digital services are everywhere, people expect more than just a store that’s in the right place at the right time. They want digital services that enhance the experience, make buying even easier, and reward their loyalty—all on demand.",
            media: {
              type: "image",
              file: "../../assets/7-eleven-rewards/7-eleven-block-6.svg",
              style: "marginRight10",
              mobileSpecial: "mobile-media-special",
            },
          },
          {
            type: "MediaBlock",
            media: "../../assets/7-eleven-rewards/Trucks.svg",
            mediaType: "image",
            style: "tight offset-top paddingBottom paddingBottomMobile",
          },
          {
            type: "BigDivider",
            title: "Rethinking the Experience",
            left: "",
            right: "",
          },
          {
            type: "MediaBlock",
            media: "../../assets/animations/pipes.json",
            mediaType: "animation",
            style: "full-bleed tight offset-top",
          },
          {
            type: "RoundedDivider",
            title:
              "We set out to design one ( <span style='color:#008163'>Unified Platform</span> ) that would define what the (<span style='color:#008163'>7-Eleven</span> ) brand means to people, combining elements of multiple ( <span style='color:#008163'>Fractured</span> ) and ( <span style='color:#008163'>Disconnected</span> ) apps and programs.",
            left: "",
            right: "",
          },
          {
            type: "ImageRow",
            images: [
              {
                background: "lightGold",
                image:
                  "../../assets/7-eleven-rewards/7-eleven-block-8-col-1.svg",
              },
              {
                background: "beige",
                image:
                  "../../assets/7-eleven-rewards/7-eleven-block-8-col-2.png",
                media: "../../assets/videos/Checkout 003.mp4",
                mediaType: "video",
                mediaStyle: "videoOverlay",
                style: "maxWidth80",
              },
              {
                background: "redOrange",
                image:
                  "../../assets/7-eleven-rewards/7-eleven-block-8-col-3.svg",
                media: "../../assets/animations/Checkout.json",
                mediaType: "lottie",
                mediaStyle: "insetMiddleBox",
                //style: "maxWidth80",
              },
            ],
          },
          {
            type: "MediaBlock7Eleven",
            media: "../../assets/animations/OrderInProgress.json",
            image:
              "../../assets/7-eleven-rewards/7-eleven-right-image-special-media-block.svg",
            mediaType: "animation",
            style: "right50",
            imageStyle: "left",
          },
          {
            type: "Roundedivider",
            title:
              "And we wanted to introduce a ( <span style='color:#008163'>Suite of Innovative</span> ) new ways to interact with the store, like being able to ( <span style='color:#008163'>Shop Ahead</span> ), ( <span style='color:#008163'>Self-checkout</span> ) over mobile, ( <span style='color:#008163'>Turning On</span> ) fuel pumps and a valuable ( <span style='color:#008163'>Rewards Program</span> )",

            left: "",
            right: "",
          },
          {
            type: "ImageRow",
            style: "wide-center",
            images: [
              {
                background: "green7Eleven",
                image:
                  "../../assets/7-eleven-rewards/7-eleven-block-8-col-2.png",
                media: "../../assets/videos/price-lock.mp4",
                // media: "../../assets/videos/select-pump.mp4",
                mediaType: "video",
                mediaStyle: "videoOverlay narrow",
                type: "video",
                style: "narrow",
              },
              {
                background: "",
                image: "../../assets/7-eleven-rewards/fuel-up-rewards.png",
                style: "wide-center",
              },
              {
                background: "beige",
                image:
                  "../../assets/7-eleven-rewards/7-eleven-block-8-col-2.png",
                media: "../../assets/videos/select-pump.mp4",
                mediaType: "video",
                mediaStyle: "videoOverlay narrow",
                type: "video",
                style: "narrow",
              },
            ],
          },
          {
            type: "MediaTitleTextBlock",
            style: "paddingBottomBig",
            title: [
              { text: "The", color: "black", align: "center" },
              { text: "Simpler", color: "black", align: "left" },
              {
                text: "The Better",
                color: "black",
                align: "right2 left-mobile",
              },
            ],
            smallText:
              "Instead of just creating a suite of functionalities and leaving the users to figure out how to use the platform, we put intense effort into creating a playful and captivating onboarding experience that helped people understand and appreciate the new product.",
            media: {
              type: "image",
              file: "../../assets/7-eleven-rewards/Coffee.svg",
              style: "marginLeft20 marginTopNeg20 cup",
            },
            effects: {
              type: "random",
              file: "../../assets/animations/star.json",
            },
          },

          {
            type: "ImageRow",
            style: "default",
            images: [
              {
                background: "lightPurple",
                image:
                  "../../assets/7-eleven-rewards/7-eleven-block-11-col-1.svg",
                media: "../../assets/animations/Carwash.json",
                mediaType: "lottie",
                mediaStyle: "insetMiddleBox",
              },
              {
                background: "beige",
                image:
                  "../../assets/7-eleven-rewards/7-eleven-block-8-col-2.png",
                media: "../../assets/videos/pay-with-rewards.mp4",
                mediaType: "video",
                mediaStyle: "videoOverlay",
                style: "maxWidth80",
                type: "video",
              },
              {
                background: "lightGreen",
                image:
                  "../../assets/7-eleven-rewards/7-eleven-block-11-col-3.svg",
              },
            ],
          },
          {
            type: "BigDivider",
            title: "Making it all feel",
            left: "",
            right: "",
          },

          {
            type: "ImageRow",
            style: "wide-center",
            images: [
              {
                background: "gold",
                image:
                  "../../assets/7-eleven-rewards/7-eleven-block-8-col-2.png",
                media: "../../assets/videos/pump.mp4",
                mediaType: "video",
                mediaStyle: "videoOverlay narrow",
                type: "video",
                style: "narrow",
              },
              {
                background: "",
                image:
                  "../../assets/7-eleven-rewards/good-times-great-deals.png",
                style: "wide-center",
              },
              {
                background: "green7Eleven",
                image:
                  "../../assets/7-eleven-rewards/7-eleven-block-8-col-2.png",
                media: "../../assets/videos/mobile-checkout.mp4",
                mediaType: "video",
                mediaStyle: "videoOverlay narrow",
                type: "video",
                style: "narrow",
              },
            ],
          },

          {
            type: "BigDivider",
            title: "Simple and Familiar",
            left: "",
            right: "",
          },
          {
            type: "MediaTitleTextBlock",
            style: "paddingBottomBig",
            title: [
              {
                text: "A Brand",
                color: "black",
                align: "justify justify-mobile",
              },
              { text: "That's", color: "black", align: "right2 left-mobile" },
              { text: "Ready For", color: "black", align: "left right-mobile" },
              { text: "The Future", color: "black", align: "right2" },
            ],
            smallText:
              "With so much emphasis being placed on new ways to interact with the store, the digital brand needed a refresh that felt contemporary, inviting.",
            smallTextStyle: "brand",
            smallText2:
              " We responded by creating a design system that strikes the perfect balance of emotion and function. Thanks to their newly redesigned platform, 7-11 is ready to grab the future and go.",
            smallTextStyle: "brand",
            media: {
              type: "lottie",
              file: "../../assets/animations/InviteFriends.json",
              style: "invite-friends",
            },
          },
          {
            type: "Quotes",
            quotes: [
              {
                image: "../../assets/7-eleven-rewards/7-eleven-full-color.svg",
                imageType: "image",
                client: "7-Eleven",
                title: "Mobile Innovation",
                quote:
                  "“An iconic brand like 7-Eleven deserved more than just straightforward product thinking. We aimed to create something that would become an integral part of the brand’s mythology.”",
                tags: ["Product Design", "Experience Strategy", "Innovation"],
                by: "from",
                attribute: "John Roescher",
                attributeRole: "Chief Executive Officer,",
                attributeCompany: "Raw Materials",
              },
            ],
          },
        ],
      },
      {
        title: "Volta Charging",
        id: "volta-charging",
        slug: "volta",
        pageTitle: "Volta Charging | Product Design | Raw Materials",
        pageDescription:
          "Partnering closely to explore how creative thinking could reinvigorate the supercharging brand and create a more captivating customer experience.",
        description:
          "Creating the next generation of EV charging network experiences — from car to kiosk to phone.",
        category: "Product Design",
        logo: "../../assets/volta/volta.svg",
        logoStyle: "h48",
        color: "voltaLightBlue",
        active: true,

        thumbs: [
          { image: "../../assets/volta/VC-CS-01.jpg" },
          { image: "../../assets/volta/VC-CS-05.jpg" },
          { image: "../../assets/volta/VC-CS-02.jpg" },
          { image: "../../assets/volta/VC-CS-03.jpg" },
          { image: "../../assets/volta/VC-CS-04.jpg" },
        ],
        blocks: [
          {
            type: "MediaBlock",
            media: "../../assets/volta/volta-block-1.svg",
            mediaMobile: "../../assets/volta/volta-block-1-mobile.svg",
            mediaType: "image",
            style: "full-bleed-plus",
          },
          {
            type: "MediaBlock",
            media: "../../assets/volta/volta-title-block-2.svg",
            mediaMobile: "../../assets/volta/volta-title-block-2.svg",
            mediaType: "image",
            style: "full-bleed tight",
          },

          {
            type: "BigDivider",
            title: "Super Charging",
            // left: "(Raw)",
            // right: "(Materials)",
          },
          {
            type: "ImageRow",
            style: "default-full",
            images: [
              {
                background: "black",
                image:
                  "../../assets/7-eleven-rewards/7-eleven-block-8-col-2.png",
                media: "../../assets/videos/volta-1.mp4",
                mediaType: "video",
                // mediaStyle: "videoOverlayFull",
                // style: "full",
                // type: "video",

                mediaType: "video",
                mediaStyle: "videoOverlay",
                style: "maxWidth80",
                type: "video",
              },
              {
                background: "black",
                image:
                  "../../assets/7-eleven-rewards/7-eleven-block-8-col-2.png",
                media: "../../assets/videos/volta-2.mp4",
                mediaType: "video",
                // mediaStyle: "videoOverlayFull",
                // style: "full",
                // type: "video",
                mediaType: "video",
                mediaStyle: "videoOverlay",
                style: "maxWidth80",
                type: "video",
              },
              {
                background: "black",
                image:
                  "../../assets/7-eleven-rewards/7-eleven-block-8-col-2.png",
                media: "../../assets/videos/volta-3.mp4",
                mediaType: "video",
                // mediaStyle: "videoOverlayFull",
                // style: "full",
                // type: "video",
                mediaType: "video",
                mediaStyle: "videoOverlay",
                style: "maxWidth80",
                type: "video",
              },
            ],
          },
          {
            type: "BigDivider",
            title: "EV Adoption",
            // left: "(Raw)",
            // right: "(Materials)",
          },
          {
            type: "ColorDivider",
            colors: [
              "#000000",
              "#CCDADE",
              "#FF5C00",
              "#20DB70",
              "#FFD93E",
              "#00C2FF",
            ],
          },
          {
            type: "BigDivider",
            title: "North",
            icon: "../../assets/volta/volta-north-arrow.svg",
          },
          {
            type: "MediaBlockAnimatedVolta",
            media: "../../assets/volta/volta-gas-pump-2.png",
            mediaMobile: "../../assets/volta/volta-gas-pump-2.png",
            mediaType: "image",
            style: "full-bleed-plus",
          },
          {
            type: "BigDivider",
            title: "West",
            icon: "../../assets/volta/volta-west-arrow.svg",
          },
          {
            type: "ColorDivider",
            colors: [
              "#000000",
              "#CCDADE",
              "#FF5C00",
              "#20DB70",
              "#FFD93E",
              "#00C2FF",
            ],
          },
          {
            type: "VoltaTitleBlock",
            title: [
              {
                text: "A Different",
                color: "voltaBlack",
                align: "justify show-line-mobile ignore-align-mobile",
              },
              {
                text: "Way to",
                color: "voltaBlack",
                align: "right show-line-mobile ignore-align-mobile",
              },
              {
                text: "Charge",
                color: "voltaBlack show-line-mobile ignore-align-mobile",
              },
            ],
            smallText:
              "Electric vehicles can’t reach mass adoption until there’s a more robust charging infrastructure. There needs to be more stations in more places.",
          },
          {
            type: "RoundedDivider",
            title:
              "Volta is (  <span style='color: #00C2FF'>Partnering</span> ) with retailers to place ( <span style='color: #00C2FF'>Charging Stations</span> ) outside their establishments,<br /> as a way to (<span style='color: #00C2FF'> Integrate</span> ) the experience ( <span style='color: #00C2FF'>Seamlessly</span> ) into daily life.",
            left: "",
            right: "",
          },
          {
            type: "VoltaMediaGrid",
            columns: 2,
            style: "split-30",
            media: [
              { type: "image", file: "../../assets/volta/volta-map-left.svg" },
              { type: "image", file: "../../assets/volta/volta-map.png" },
            ],
          },
          {
            type: "MediaBlock",
            media: "../../assets/volta/volta-usage-calendar.svg",
            mediaMobile: "../../assets/volta/volta-usage-calendar.svg",
            mediaType: "image",
            style: "full-bleed-contain tight round-overflow-hidden",
          },
          {
            type: "VoltaBarGraph",
            bars: [
              {
                title: "Gym",
                width: 22.5,
                color: "voltaLightGrey",
                background: "voltaGrey",
              },
              {
                title: "Station",
                width: 15,
                color: "voltaBlack",
                background: "voltaLightGreen",
              },
              {
                title: "Grocery Store",
                width: 40,
                color: "voltaBlack",
                background: "voltaOrange",
              },
              {
                title: "Park",
                width: 22.5,
                color: "voltaBlack",
                background: "voltaYellow",
              },
            ],
          },

          {
            type: "RoundedDivider",
            title:
              "Most charging companies ( <span style='color: #00C2FF'>Focus</span>  ) on heavily traveled routes,<br />but (<img class='icon' src='../../assets/volta/volta-bird-inline-orange.svg' />) is taking a ( <span style='color: #00C2FF'>Different Approach</span>  ).",
            left: "",
            right: "",
          },
          {
            type: "VoltaMediaGrid",
            columns: 3,
            style: "grid-3",
            media: [
              {
                type: "image",
                file: "../../assets/volta/volta-grid-item-1.png",
              },
              {
                type: "image",
                file: "../../assets/volta/volta-grid-item-2.png",
              },
              {
                type: "image",
                file: "../../assets/volta/volta-grid-item-3.png",
              },
              {
                type: "image",
                file: "../../assets/volta/volta-grid-item-4.png",
              },
              {
                type: "image",
                file: "../../assets/volta/volta-grid-item-5.png",
              },
              {
                type: "image",
                file: "../../assets/volta/volta-grid-item-6.png",
              },
            ],
          },
          {
            type: "ColorDivider",
            colors: [
              "#000000",
              "#CCDADE",
              "#FF5C00",
              "#20DB70",
              "#FFD93E",
              "#00C2FF",
            ],
          },
          {
            type: "MediaBlock",
            media: "../../assets/volta/volta-orange-block.svg",
            mediaMobile: "../../assets/volta/volta-orange-block.svg",
            mediaType: "image",
            style: "full-bleed tight",
          },

          {
            type: "VoltaTitleBlock",
            style: "bottom-blocks-2",
            title: [
              {
                text: "A &nbsp; Jolt",
                color: "voltaBlack",
                align: "right4 show-line-mobile ignore-align-mobile",
              },
              {
                text: "of",
                color: "voltaBlack",
                align: "right3 show-line-mobile ignore-align-mobile",
              },
              {
                text: "Creativity",
                color: "voltaBlack",
                align: "right2 show-line-mobile ignore-align-mobile",
              },
            ],
            smallText:
              "Volta came to us with an existing experience, mainly centered around the core Voltla mobile app, that had been driven by technology, not human insights. We partnered closely with them to show how creative thinking could reinvigorate their brand, and create a more captivating mobile experience and overall service.",
            smallText2:
              "Most players in the space take a cold, techy, utilitarian approach to branding. We gave Volta a warm, human, inclusive look fitting a brand that aims to make vehicle charging a regular sight in every neighborhood.",
          },
          {
            type: "BigDividerAdvanced",
            subtitle: "200m this way",
            background: "voltaGrey2",
            title: "Ice Cream + Charging",
            icon: "../../assets/volta/volta-north-icon-black.svg",
            smallIcon: "../../assets/volta/volta-oil-icon.svg",
          },
          {
            type: "BigDividerVoltaSplit",
            title1: "Powered",
            title2: "by",
            style: "split-60",
            // left: "(Raw)",
            // right: "(Materials)",
          },
          {
            type: "ImageRow",
            style: "",
            images: [
              {
                background: "",
                image: "../../assets/volta/volta-3-row-block-1.svg",
                style: "full",
              },
              {
                background: "black",
                image:
                  "../../assets/volta/volta-3-row-block-phone-content-2.png",

                style: "maxWidth90",
                type: "image",
              },
              {
                background: "",
                image: "../../assets/volta/volta-3-row-block-3.svg",
                style: "full",
              },
            ],
          },
          {
            type: "BigDividerVoltaSplit",

            title2: "People",

            icon1: "../../assets/volta/volta-people-arrows.svg",
            icon3: "../../assets/volta/volta-people-arrows.svg",
            style: "split-25-50-25",
          },
          {
            type: "MediaBlock",
            media: "../../assets/volta/VC-25.svg",
            mediaMobile: "../../assets/volta/VC-25.svg",
            mediaType: "image",
            style: "full-bleed tight",
          },
          {
            type: "BigDividerAdvanced",
            style: "right-align",
            subtitle: "100m this way",
            background: "voltaGrey2",
            title: "AC Charging + Latte",
            icon: "../../assets/volta/volta-back-arrow.svg",
            smallIcon: "../../assets/volta/volta-oil-yellow-icon.svg",
          },
          {
            type: "ColorDivider",
            colors: [
              "#000000",
              "#CCDADE",
              "#FF5C00",
              "#20DB70",
              "#FFD93E",
              "#00C2FF",
            ],
          },
          {
            type: "BigDivider",
            title: "A Journey",
          },
          {
            type: "VoltaMediaGrid",
            columns: 2,
            style: "split-50",
            media: [
              {
                type: "image",
                file: "../../assets/volta/volta-shopping-cart.svg",
              },
              {
                type: "image",
                file: "../../assets/volta/volta-fast-changer.svg",
              },
            ],
          },
          {
            type: "BigDivider",
            title: "Of Discovery",
          },
          {
            type: "ImageRow",
            style: "",
            images: [
              {
                background: "black",
                image:
                  "../../assets/7-eleven-rewards/7-eleven-block-8-col-2.png",
                media: "../../assets/videos/volta-11.mp4",
                mediaType: "video",
                // mediaStyle: "videoOverlayFull",
                // style: "full",
                // type: "video",

                mediaType: "video",
                mediaStyle: "videoOverlay",
                style: "maxWidth80",
                type: "video",
              },
              {
                background: "black",
                image:
                  "../../assets/7-eleven-rewards/7-eleven-block-8-col-2.png",
                media: "../../assets/videos/volta-12.mp4",
                mediaType: "video",
                // mediaStyle: "videoOverlayFull",
                // style: "full",
                // type: "video",
                mediaType: "video",
                mediaStyle: "videoOverlay",
                style: "maxWidth80",
                type: "video",
              },
              {
                background: "black",
                image: "../../assets/volta/volta-13-static.png",
                mediaType: "image",
                // mediaStyle: "videoOverlayFull",
                // style: "full",
                // type: "video",
                // mediaType: "video",
                // mediaStyle: "videoOverlay",
                style: "maxWidth80",
                type: "image",
              },
              // {
              //   background: "",
              //   image: "../../assets/7-eleven-rewards/good-times-great-deals.png",
              //   style: "wide-center",
              // },
            ],
          },
          {
            type: "VoltaTitleBlock",
            style: "bottom-blocks-2",
            title: [
              {
                text: "Simply",
                color: "voltaBlack",
                align: "justify show-line-mobile ignore-align-mobile",
              },
              {
                text: "Electric",
                color: "voltaBlack",
                align: "right3 show-line-mobile ignore-align-mobile",
              },
            ],
            smallText:
              "It is a complete vision for an intuitive and fulfilling way to charge your car—and a cleaner world for all of us. Throughout the process, we ensured that the resulting app would be intuitive and easy to use. ",
            smallText2:
              "The mobile experience also helped Volta incentivize community involvement as a way to build even more momentum behind the adoption of electric vehicles.",
          },
          {
            type: "MediaBlock",
            media: "../../assets/volta/volta-green-bird.svg",
            mediaMobile: "../../assets/volta/volta-green-bird.svg",
            mediaType: "image",
            style: "full-bleed-plus",
          },
          {
            type: "Quotes",
            quotes: [
              {
                image: "../../assets/volta/volta-bottom-quote-logo.svg",
                imageType: "image",
                client: "Volta",
                title: "EV<br />Innovation",
                quote:
                  "“Volta is a pioneer in building the type of infrastructure the future will run on. Helping them design that future was an unmissable opportunity.”",
                tags: [
                  "BRAND STRATEGY",
                  "Experience Strategy",
                  "Product Design",
                ],
                by: "from",
                attribute: "John Roescher",
                attributeRole: "Chief Executive Officer",
                attributeCompany: "Raw Materials",
              },
            ],
          },
        ],
      },
      {
        title: "Meta AI",
        id: "meta-ai",
        slug: "meta-ai",
        pageTitle: "Meta AI | Brand Design | Raw Materials",
        pageDescription:
          "Branding AI by visualizing its beautiful complexities",
        description: "Branding AI by visualizing its beautiful complexities.",
        category: "Brand Design",
        logo: "../../assets/meta-ai/meta-ai-logo.svg",
        logoStyle: "h32",
        color: "metaBlue",
        active: true,
        thumbs: [
          { image: "../../assets/meta-ai/MA-CS-01.jpg" },
          { image: "../../assets/meta-ai/MA-CS-02.jpg" },
          { image: "../../assets/meta-ai/MA-CS-03.jpg" },
          { image: "../../assets/meta-ai/MA-CS-04.jpg" },
          { image: "../../assets/meta-ai/MA-CS-05.jpg" },
        ],
        blocks: [
          {
            type: "MediaBlock",
            media: "../../assets/videos/meta-ai-landing.mp4",
            mediaType: "video",
            extraImage: "../../assets/meta-ai/meta-ai-logo-2.svg",
            extraImageStyle: "center",
            style: "over-sized-video round-overflow-hidden",
          },
          {
            type: "MetaTitleBlock",
            style: "bottom-blocks-3a optimistic",
            title: [
              {
                text: "The Beauty",
                color: "black",
                align:
                  "hideOnMobile left2 show-line-mobile ignore-align-mobile mobile-right",
              },
              {
                text: "The",
                color: "black",
                showSpecialIndex: true,
                align: "hideOnDesktop left4 show-line-mobile",
              },
              {
                text: "Beauty",
                color: "black",
                align: "hideOnDesktop left2 show-line-mobile mobile-right",
              },

              {
                text: "of",
                color: "black",
                align:
                  "right4 show-line-mobile ignore-align-mobile mobile-right3",
              },
              {
                text: "Complexity",
                color: "black",
                align: "left2 show-line-mobile ignore-align-mobile unsetWidth",
              },
            ],
            smallText:
              "We were tasked with helping Meta create a visual language to represent the striking and complex ideas behind one of their most important efforts, Meta AI. A brand with a diverse audience, from the press to academia and researchers. ",
            smallText2:
              "<p>Together, we chose to pursue the inherent beauty of the foundational concepts behind AI to create a visual world that felt equally complex, beautiful, and new. Something that could express the ideas that lie at the boundaries of artificial life and creativity — something that looks perhaps like software biomimicry.</p>",
            backgroundImageMobile: {
              image: "../../assets/meta-ai/meta-ai-titles-block-1-mobile.png",
            },
            backgroundImage: {
              image: "../../assets/meta-ai/meta-ai-titles-block-1.png",
              style: "style-1",
            },
          },
          {
            type: "MediaBlock",
            media: "../../assets/meta-ai/meta-ai-titles-block-2.png",
            mediaType: "image",
            style: "full-bleed",
          },
          {
            type: "BigDivider",
            title: "Speech Recognition",
          },
          {
            type: "MediaBlock",
            media: "../../assets/meta-ai/MA-06.jpg",
            mediaMobile: "../../assets/meta-ai/MA-06.jpg",
            mediaType: "image",
            style: "full-bleed",
          },
          {
            type: "ImageRow",
            style: "two-column",
            images: [
              {
                background: "",
                image: "../../assets/meta-ai/image-row-1-1.jpg",
                style: "full two-column rounded",
              },
              {
                background: "",
                image: "../../assets/meta-ai/image-row-1-2.jpg",
                style: "full two-column rounded",
              },
            ],
          },
          {
            type: "RoundedDivider",
            title:
              "When visualizing (&nbsp;<span style='color: #1778F2; font-weight:400;'>speech&nbsp;recognition</span>&nbsp;) ideas, we focused on finding new dimensions to visualize (&nbsp;<span style='color: #1778F2; font-weight:400;'>sound</span>&nbsp;), alluding to how computers process this information (&nbsp;<span style='color: #1778F2; font-weight:400;'>differently&nbsp;than&nbsp;us</span>&nbsp;) while consuming the exact same data.",
            left: "",
            right: "",
            style: "optimistic meta-ai-narrow",
          },

          {
            type: "MetaAIMediaBlock",
            subTitleColor: "white",
            subTitle: "SPEECH & AUDIO | NLP",
            titleColor: "metaAILightBlueTitle",
            title: "A Two-stage Approach to Speech Bandwidth Extension",
            media: "../../assets/meta-ai/meta-ai-title-block-image-1.png",
            mediaType: "image",
            style: "full",
          },

          {
            type: "MediaBlock",
            media: "../../assets/videos/meta-ai-video-2.mp4",
            mediaType: "video",
            // extraImage: "../../assets/meta-ai/meta-ai-logo-2.svg",
            // extraImageStyle: "center",
            style: "over-sized-video round-overflow-hidden",
            sectionLabelStyle: "grey",
          },
          {
            type: "MetaTitleBlock",
            style: "bottom-blocks-3 optimistic mobile-blocks-margin-1",
            title: [
              {
                text: "A Need",
                color: "metaAIPlum",
                align:
                  "left2 show-line-mobile ignore-align-mobile mobile-right",
              },
              {
                text: "for Range",
                color: "metaAIPlum",
                align:
                  "right2 show-line-mobile ignore-align-mobile mobile-right1",
              },
              {
                text: "and Depth",
                color: "metaAIPlum",
                align: "left3 show-line-mobile ignore-align-mobile",
              },
            ],
            smallText:
              "Meta AI’s brand has a diverse range of audiences, from conference attendees, and the tech press to researchers in academia. At times, it needs to speak boldly, and at others, strike a more authoritative and serious tone.",
            smallText2:
              "<p>Our visuals needed to be striking and beautiful but also rooted in the authentic ideas of the concepts they represented. We built an understanding of each audience to ensure that even though they sported different perspectives, they could all relate to the work.</p>",
            backgroundImageMobile: {
              image: "../../assets/meta-ai/meta-ai-titles-block-2-mobile.png",
              style: "style-2",
            },
            backgroundImage: {
              image: "../../assets/meta-ai/meta-ai-titles-block-2-1.png",
              style: "style-2",
            },
          },
          {
            type: "MediaBlock",
            media: "../../assets/meta-ai/meta-ai-titles-block-2-2.png",
            mediaType: "image",
            style: "full-bleed",
          },
          {
            type: "BigDivider",
            title: "Machine Learning",
          },
          {
            type: "MediaBlock",
            media: "../../assets/meta-ai/MA-14.jpg",
            mediaMobile: "../../assets/meta-ai/MA-14.jpg",
            mediaType: "image",
            style: "full-bleed",
          },
          {
            type: "RoundedDivider",
            title:
              "Machines (&nbsp;<span style='color: #1778F2; font-weight:400;'>learn&nbsp;and&nbsp;think</span>&nbsp;) in processes where complexity (&nbsp;<span style='color: #1778F2; font-weight:400;'>emerges</span>&nbsp;) from simple rules. To visualize this, we created systems to build increasingly (&nbsp;<span style='color: #1778F2; font-weight:400;'>complex</span>&nbsp;) images based on a simple set of ideas and parameters.",
            left: "",
            right: "",
            style: "optimistic meta-ai-narrow",
          },
          {
            type: "ImageRow",
            style: "three-column",
            images: [
              {
                background: "",
                image: "../../assets/meta-ai/image-row-2-1.png",
                style: "full three-column rounded",
              },
              {
                background: "",
                image: "../../assets/meta-ai/image-row-2-2.png",
                style: "full three-column rounded",
              },
              {
                background: "",
                image: "../../assets/meta-ai/image-row-2-3.png",
                style: "full three-column rounded",
              },
            ],
          },
          {
            type: "MediaBlock",
            media: "../../assets/meta-ai/MA-16.jpg",
            mediaMobile: "../../assets/meta-ai/MA-16.jpg",
            mediaType: "image",
            style: "full-bleed",
          },
          {
            type: "MetaTitleBlock",
            style: "bottom-blocks-3 optimistic mobile-blocks-margin-2",
            title: [
              {
                text: "A Delicate",
                color: "black",
                align:
                  "hideOnMobile left2 show-line-mobile ignore-align-mobile mobile-right",
              },
              {
                text: "A",
                color: "black",
                showSpecialIndex: true,
                align: "hideOnDesktop left4 show-line-mobile",
              },
              {
                text: "Delicate",
                color: "black",
                align: "hideOnDesktop left2 show-line-mobile mobile-right",
              },
              {
                text: "Balancing",
                color: "black",
                align:
                  "right2 show-line-mobile ignore-align-mobile mobile-right1",
              },
              {
                text: "Act",
                color: "black",
                align: "left3 show-line-mobile ignore-align-mobile",
              },
            ],
            smallText:
              "The master Meta brand is extremely strong, so our task was to explore the limits of what is possible without sacrificing that equity.",
            smallText2:
              "<p>We responded by designing a language that spanned illustrations, photography, and tone of voice that could be seamlessly integrated into and expand the existing design system.</p>",
            backgroundImageMobile: {
              image: "../../assets/meta-ai/meta-ai-titles-block-3-mobile.png",
              style: "style-3",
            },
            backgroundImage: {
              image: "../../assets/meta-ai/meta-ai-titles-block-3-1.png",
              style: "style-3",
            },
          },
          {
            type: "MediaBlock",
            media: "../../assets/meta-ai/meta-ai-titles-block-3-2.png",
            mediaType: "image",
            style: "full-bleed",
          },
          {
            type: "BigDivider",
            title: "Computer Vision",
          },

          {
            type: "MetaAIMediaBlock2",
            subTitle:
              "Creating new ways for computers to gain a higher level of",
            subTitleColor: "black",
            subTitle2: "of the visual world around us.",
            subTitle2Color: "black",
            title: "Understanding",
            titleColor: "white",
            media: "../../assets/meta-ai/14.png",
            mediaType: "image",
            style: "full",
          },
          {
            type: "ImageRow",
            style: "two-column",
            images: [
              {
                background: "white",
                image: "../../assets/meta-ai/image-row-3-1.png",
                style: "two-column rounded",
              },
              {
                background: "white",
                image: "../../assets/meta-ai/image-row-3-2.png",
                style: "two-column rounded",
              },
            ],
          },

          {
            type: "RoundedDivider",
            title:
              "Computers see by identifying the (&nbsp;<span style='color: #1778F2; font-weight:400;'>boundaries</span>&nbsp;) between distinct objects and visual layers of an image and trying to find (&nbsp;<span style='color: #1778F2; font-weight:400;'>patterns</span>&nbsp;) in the relationships between those layers. We visualized that by creating concepts based on the schematic representations of these ideas.",
            left: "",
            right: "",
            style: "optimistic meta-ai-narrow",
          },
          {
            type: "MetaTitleBlock",
            style: "bottom-blocks-4 optimistic mobile-blocks-margin-4",
            title: [
              {
                text: "The Result",
                color: "black",
                align:
                  "hideOnMobile left3 show-line-mobile ignore-align-mobile mobile-right",
              },
              {
                text: "The",
                color: "black",
                showSpecialIndex: true,
                align: "hideOnDesktop left4 show-line-mobile",
              },
              {
                text: "Result",
                color: "black",
                align: "hideOnDesktop left2 show-line-mobile mobile-right",
              },

              {
                text: "Of A True",
                color: "black",
                align:
                  "right3 show-line-mobile ignore-align-mobile mobile-right",
              },
              {
                text: "Partnership",
                color: "black",
                align: "unsetWidth left1 show-line-mobile ignore-align-mobile",
              },
            ],
            smallText:
              "Raw Materials and Meta worked in close partnership. Working as one seamless team is the only way to develop work that is relevant, novel, and appropriate while observing the inevitable constraints that exist in any project.",
            smallText2: "",
            backgroundImageMobile: {
              image: "../../assets/meta-ai/meta-ai-titles-block-4-mobile.png",
              style: "style-4",
            },
            backgroundImage: {
              image: "../../assets/meta-ai/meta-ai-titles-block-4-1.png",
              style: "style-4",
            },
          },
          {
            type: "BigDivider",
            title: " Generative AI",
          },

          {
            type: "MediaGrid",
            columns: 3,
            style: "grid-3",
            media: [
              {
                type: "image",
                file: "../../assets/meta-ai/Loving The Details.jpg",
              },
              {
                type: "image",
                file: "../../assets/meta-ai/Loving The Details-1.jpg",
              },
              {
                type: "image",
                file: "../../assets/meta-ai/Loving The Details-2.jpg",
              },
              {
                type: "image",
                file: "../../assets/meta-ai/Loving The Details-3.jpg",
              },
              {
                type: "image",
                file: "../../assets/meta-ai/Loving The Details-4.jpg",
              },
              {
                type: "image",
                file: "../../assets/meta-ai/Loving The Details-5.jpg",
              },
            ],
          },

          {
            type: "RoundedDivider",
            title:
              "Generative AI creates something new out of understanding and (&nbsp;<span style='color: #1778F2; font-weight:400;'>replicating&nbsp;patterns</span>&nbsp;). To display these ideas, we created a system of images that could build an (&nbsp;<span style='color: #1778F2; font-weight:400;'>infinite&nbsp;number</span>&nbsp;) of different shapes by aggregating (&nbsp;<span style='color: #1778F2; font-weight:400;'>smaller&nbsp;pieces</span>&nbsp;) just as these systems do.",
            left: "",
            right: "",
            style: "optimistic meta-ai-narrow",
          },
          {
            type: "MediaBlock",
            media: "../../assets/meta-ai/MA-28.jpg",
            mediaMobile: "../../assets/meta-ai/MA-28.jpg",
            mediaType: "image",
            style: "full-bleed",
          },
          {
            type: "MetaTitleBlock",
            style:
              "bottom-blocks-3 optimistic mobile-blocks-margin-5 special-small-text-1",
            title: [
              {
                text: "Propelling",
                color: "metaAIGreen",
                align:
                  "hideOnMobile right green-line show-line-mobile ignore-align-mobile mobile-right",
              },
              {
                text: "the",
                color: "metaAIGreen",
                showSpecialIndex: true,
                align: "hideOnMobile right2 green-line show-line-mobile",
              },
              {
                text: "Prop-",
                color: "metaAIGreen",
                align: "hideOnDesktop green-line show-line-mobile left4",
              },
              {
                text: "elling &nbsp; &nbsp; the",
                color: "metaAIGreen",
                showSpecialIndex: true,
                align: "hideOnDesktop green-line show-line-mobile",
              },
              {
                text: "Business",
                color: "metaAIGreen",
                align: "left2 green-line show-line-mobile mobile-right",
              },
              {
                text: "Forward",
                color: "white",
                align:
                  "right2 green-line show-line-mobile ignore-align-mobile mobile-right1",
              },
            ],
            smallText:
              "Meta AI is intertwined with the future of Meta, and we helped their team find a voice to extend the brand's presence across all of their channels.",
            smallText2: "",
            backgroundImageMobile: {
              image: "../../assets/meta-ai/meta-ai-titles-block-5-mobile.png",
              style: "style-5",
            },
            backgroundImage: {
              image: "../../assets/meta-ai/meta-ai-titles-block-5-1.png",
              style: "style-5",
            },
          },
          {
            type: "MediaBlock",
            media: "../../assets/meta-ai/meta-ai-titles-block-5-2.png",
            mediaType: "image",
            style: "full-bleed",
          },
          {
            type: "BigDivider",
            title: " Visual Development",
          },
          {
            // type: "MediaBlock",
            // media: "../../assets/meta-ai/MA-32.jpg",
            // mediaMobile: "../../assets/meta-ai/MA-32.jpg",
            // mediaType: "image",
            // style: "full-bleed",

            type: "MediaBlock",
            media: "../../assets/videos/meta-ai-video-3.mp4",
            mediaType: "video",
            // extraImage: "../../assets/meta-ai/meta-ai-logo-2.svg",
            // extraImageStyle: "center",
            style: "round-overflow-hidden",
            sectionLabelStyle: "grey",
          },
          {
            type: "ImageRow",
            style: "two-column",
            images: [
              {
                background: "white",
                image: "../../assets/meta-ai/image-row-4-1.jpg",
                style: "two-column rounded full tight",
              },
              {
                background: "white",
                image: "../../assets/meta-ai/image-row-4-2.jpg",
                style: "two-column rounded full tight",
              },
            ],
          },
          {
            type: "MediaBlock",
            media: "../../assets/meta-ai/MA-34.jpg",
            mediaMobile: "../../assets/meta-ai/MA-34.jpg",
            mediaType: "image",
            style: "full-bleed",
          },
          {
            type: "ImageRow",
            style: "three-column",
            images: [
              {
                background: "",
                image: "../../assets/meta-ai/image-row-5-1.jpg",
                style: "full three-column rounded",
              },
              {
                background: "",
                image: "../../assets/meta-ai/image-row-5-2.jpg",
                style: "full three-column rounded",
              },
              {
                background: "",
                image: "../../assets/meta-ai/image-row-5-3.jpg",
                style: "full three-column rounded",
              },
            ],
          },
          {
            type: "RoundedDivider",
            title:
              "The work we created with Meta resulted from (&nbsp;<span style='color: #1778F2; font-weight:400;'>numerous&nbsp;iterations</span>&nbsp;) and a relentless creative pursuit of the (&nbsp;<span style='color: #1778F2; font-weight:400;'>right&nbsp;answers</span>&nbsp;). It was also the product of immense care for craft and of collaboration with (&nbsp;<span style='color: #1778F2; font-weight:400;'>skilled&nbsp;digital&nbsp;artisans</span>&nbsp;) in Design, 3D, motion, and code. Craft and thought in equal measures.",
            left: "",
            right: "",
            style: "optimistic meta-ai-narrow",
          },
          {
            type: "MediaBlock",
            media: "../../assets/meta-ai/16-1.png",
            mediaMobile: "../../assets/meta-ai/16-1.png",
            mediaType: "image",
            style: "full-bleed",
          },
          {
            type: "ImageRow",
            style: "three-column",
            images: [
              {
                background: "",
                image: "../../assets/meta-ai/image-row-6-1.jpg",
                style: "full three-column rounded",
              },
              {
                background: "",
                image: "../../assets/meta-ai/image-row-6-2.jpg",
                style: "full three-column rounded",
              },
              {
                background: "",
                image: "../../assets/meta-ai/image-row-6-3.jpg",
                style: "full three-column rounded",
              },
            ],
          },
          {
            type: "MediaBlock",
            media: "../../assets/meta-ai/MA-39.jpg",
            mediaMobile: "../../assets/meta-ai/MA-39.jpg",
            mediaType: "image",
            style: "full-bleed",
          },
          {
            type: "Quotes",
            quotes: [
              {
                image: "../../assets/animations/M Meta export.json",
                imageType: "lottie",
                client: "Meta AI",
                title: "Beautiful Complexity",
                quote:
                  "“Getting a perspective of the coming age of AI from inside an organization leading the charge gave us the inspiration to invent a brand in a completely new way.”",
                tags: ["Brand Design", "ARTIFICIAL INTELLIGENCE"],
                by: "by",
                attribute: "John Roescher",
                attributeRole: "Chief Executive Officer,",
                attributeCompany: "Raw Materials",
              },
            ],
          },
        ],
      },
      {
        title: "855-HOW-TO-QUIT",
        id: "how-to-quit",
        slug: "how-to-quit",
        pageTitle: "How To Quit | 360 Campaign | Raw Materials",
        pageDescription:
          "Initiative to fight the opioid epidemic, by turning the the object of addiction into a way out.",
        description:
          "Initiative to fight the opioid epidemic, by turning the the object of addiction into a way out.",
        category: "360 Campaign",
        logo: "../../assets/how-to-quit/how-to-quit-logo.svg",
        logoStyle: "h48",
        color: "htqOrange",
        active: true,
        thumbs: [
          { image: "../../assets/how-to-quit/855-CS-01.jpg" },
          { image: "../../assets/how-to-quit/855-CS-02.jpg" },
          { image: "../../assets/how-to-quit/855-CS-03.jpg" },
          { image: "../../assets/how-to-quit/855-CS-04.jpg" },
          { image: "../../assets/how-to-quit/855-CS-05.jpg" },
        ],
        blocks: [
          // {
          //   type: "MediaBlock",
          //   media: "../../assets/videos/meta-ai-landing.mp4",
          //   mediaType: "video",
          //   extraImage: "../../assets/meta-ai/meta-ai-logo-2.svg",
          //   extraImageStyle: "center",
          //   style: "full-bleed fit",
          // },
          {
            type: "VideoPlayerBlock",

            media: "https://player.vimeo.com/video/924325725?h=78361722d3",
            // image: "../../assets/project-aria/aria-video-placeholder.jpg",
            style: "",
          },
          {
            type: "HTQTitleBlock",
            style: "bottom-blocks-3a htqLightGrey ",
            title: [
              {
                text: "Creativity",
                color: "htqLightGrey",
                align:
                  "left2 show-line-mobile ignore-align-mobile mobile-right",
              },
              {
                text: "vs  The Opioid",
                color: "htqLightGrey",
                align:
                  "hideOnMobile justify show-line-mobile ignore-align-mobile mobile-right",
              },
              // {
              //   text: "vs",
              //   color: "htqLightGrey",
              //   showSpecialIndex: true,
              //   align: "hideOnDesktop left4 show-line-mobile",
              // },
              {
                text: "vs The Opioid",
                color: "htqLightGrey",
                align: "hideOnDesktop  show-line-mobile ",
              },
              {
                text: "Epidemic",
                color: "htqLightGrey",
                align: "right show-line-mobile ignore-align-mobile mobile-left",
              },
            ],
            smallText:
              "In the face of the opioid crisis—a decades-long battle claiming lives and devastating communities across the United States—traditional approaches to combatting addiction have fallen short. ",
            smallText2:
              "<p>Recognizing the urgent need for a different, more creative solution, Raw Materials partnered with Service Plan to embark on an innovative project aimed at connecting those struggling with opioid addiction to resources and support in a new and innovative way.</p>",
            // backgroundImageMobile: {
            //   image: "../../assets/meta-ai/meta-ai-titles-block-1-mobile.png",
            // },
            // backgroundImage: {
            //   image: "../../assets/meta-ai/meta-ai-titles-block-1.png",
            //   style: "style-1",
            // },
          },
          {
            type: "MediaBlock",
            media: "../../assets/how-to-quit/htq-1.jpg",
            mediaType: "image",
            style: "full-bleed",
          },
          {
            type: "MediaBlock",
            media: "../../assets/how-to-quit/htq-2.jpg",
            mediaType: "image",
            style: "full-bleed",
          },

          {
            type: "ImageRow",
            style: "two-column",
            images: [
              {
                background: "",
                image: "../../assets/how-to-quit/htq-3.jpg",
                style: "full two-column rounded",
              },
              {
                background: "",
                image: "../../assets/how-to-quit/htq-4.jpg",
                style: "full two-column rounded",
              },
            ],
          },

          {
            type: "MediaBlock",
            media: "../../assets/how-to-quit/htq-5.jpg",
            mediaType: "image",
            style: "full-bleed",
          },

          {
            type: "HTQitleBlock",
            style: "bottom-blocks-3a htqLightCream ",
            title: [
              {
                text: "855",
                color: "htqLightCream",
                align:
                  "left3 show-line-mobile ignore-align-mobile mobile-right3",
              },
              {
                text: "HOW-TO-QUIT",
                color: "htqLightCream",
                align: "show-line-mobile unsetWidth",
              },

              {
                text: "(OPIOIDS)",
                color: "htqLightCream",
                align:
                  "left2 show-line-mobile ignore-align-mobile mobile-right",
              },
            ],
            smallText:
              "At the heart of this initiative was the creation of a toll-free helpline, 855-HOW-TO-QUIT, which uses the imprint codes on popular opioid pills as phone extensions. On the other side of the line are people and their inspiring stories of success when overcoming the very problem callers are dealing with.",
            smallText2:
              "Each imprint connects you with a different story about dealing not only with addiction but addiction to the very substance you are calling about. Using the pill imprint codes turns the same object of addiction into a potential lifeline, offering a direct connection to those who have successfully navigated the journey to recovery.",
            // backgroundImageMobile: {
            //   image: "../../assets/meta-ai/meta-ai-titles-block-1-mobile.png",
            // },
            // backgroundImage: {
            //   image: "../../assets/meta-ai/meta-ai-titles-block-1.png",
            //   style: "style-1",
            // },
          },

          {
            type: "MediaBlock",
            media: "../../assets/videos/how-to-quit-video-2.mp4",
            mediaType: "video",
            // extraImage: "../../assets/meta-ai/meta-ai-logo-2.svg",
            // extraImageStyle: "center",
            style: "full-bleed fit",
          },
          {
            type: "BigDivider",
            title: "Empathy",
          },
          {
            type: "RoundedDivider",
            title:
              "By enabling callers to connect with individuals and stories of those who have overcome addiction to the same substance, the project fosters a sense of understanding and shared experience.",
            left: "",
            right: "",
            style: "",
          },

          {
            type: "MediaBlock",
            media: "../../assets/how-to-quit/htq-6.jpg",
            mediaType: "image",
            style: "full-bleed",
          },

          {
            type: "ImageRow",
            style: "three-column",
            images: [
              {
                background: "",
                image: "../../assets/how-to-quit/htq-7.jpg",
                style: "full three-column rounded",
              },
              {
                background: "",
                image: "../../assets/how-to-quit/htq-8.jpg",
                style: "full three-column rounded",
              },
              {
                background: "",
                image: "../../assets/how-to-quit/htq-9.jpg",
                style: "full three-column rounded",
              },
            ],
          },

          {
            type: "BigDivider",
            title: "Accessibility",
          },
          {
            type: "RoundedDivider",
            title:
              "The service is designed to be as accessible as possible, available 24/7 across all 50 states, <br />ensuring help is always just a phone call away.",
            left: "",
            right: "",
            style: "narrow",
          },
          {
            type: "ImageRow",
            style: "two-column",
            images: [
              {
                background: "",
                media: "../../assets/videos/how-to-quit-video-7.mp4",
                style: "full two-column rounded htqVideo2",
                mediaType: "video",
                mediaStyle: "htqVideo2",
              },
              {
                background: "",
                media: "../../assets/videos/how-to-quit-video-8.mp4",
                style: "full two-column rounded htqVideo2",
                mediaType: "video",
                mediaStyle: "htqVideo2",
              },
            ],
          },
          {
            type: "MediaBlock",
            media: "../../assets/how-to-quit/htq-12.jpg",
            mediaMobile: "../../assets/how-to-quit/htq-12-mobile.jpg",
            mediaType: "image",
            style: "full-bleed",
          },

          {
            type: "BigDivider",
            title: "Engagement",
          },
          {
            type: "RoundedDivider",
            title:
              "A comprehensive campaign, including out-of-home media and digital platforms, raises awareness and drives engagement, targeting environments most relevant to those most in need.",
            left: "",
            right: "",
            style: "narrow",
          },

          {
            type: "MediaBlock",
            media: "../../assets/how-to-quit/htq-13.jpg",
            mediaMobile: "../../assets/how-to-quit/htq-13-mobile.jpg",
            mediaType: "image",
            style: "full-bleed",
          },

          {
            type: "ImageRow",
            style: "two-column",
            images: [
              {
                background: "",
                image: "../../assets/how-to-quit/htq-14.jpg",
                style: "full two-column rounded",
              },
              {
                background: "",
                image: "../../assets/how-to-quit/htq-15.jpg",
                style: "full two-column rounded",
              },
            ],
          },
          {
            type: "HTQTitleBlock",
            style: "bottom-blocks-3a htqLightPink ",
            title: [
              {
                text: "Design as",
                color: "htqLightPink",
                align:
                  "left2 show-line-mobile ignore-align-mobile mobile-right",
              },
              {
                text: "A Tool &nbsp;&nbsp;&nbsp; For",
                color: "htqLightPink",
                align: "justify show-line-mobile ",
              },

              {
                text: "Connection",
                color: "htqLightPink",
                align: "right show-line-mobile ignore-align-mobile unsetWidth",
              },
            ],
            smallText:
              "A design strategy that focused on clarity, empathy, and impact.<br /> ",
            smallText2: "",
            // backgroundImageMobile: {
            //   image: "../../assets/meta-ai/meta-ai-titles-block-1-mobile.png",
            // },
            // backgroundImage: {
            //   image: "../../assets/meta-ai/meta-ai-titles-block-1.png",
            //   style: "style-1",
            // },
          },

          {
            type: "MediaBlock",
            media: "../../assets/how-to-quit/htq-16.jpg",
            mediaType: "image",
            style: "full-bleed",
          },
          {
            type: "BigDivider",
            title: "Typography and Colors",
          },
          {
            type: "MediaBlock",
            media: "../../assets/how-to-quit/htq-17.jpg",
            mediaMobile: "../../assets/how-to-quit/htq-17-mobile.jpg",
            mediaType: "image",
            style: "full-bleed",
          },

          {
            type: "ImageRow",
            style: "two-column",
            images: [
              {
                background: "",
                image: "../../assets/how-to-quit/htq-18.jpg",
                style: "full two-column rounded",
              },
              {
                background: "",
                image: "../../assets/how-to-quit/htq-19.jpg",
                style: "full two-column rounded",
              },
            ],
          },
          {
            type: "RoundedDivider",
            title:
              "A custom narrow typeface was developed in collaboration with Kimera, emphasizing readability and a medical aesthetic. The color palette was intentionally subdued, with monochromatic tones and orange accents, reflecting the seriousness of opioid addiction.",
            left: "",
            right: "",
            style: "narrow",
          },

          {
            type: "MediaBlock",
            media: "../../assets/how-to-quit/htq-20.svg",
            mediaType: "image",
            style: "full topPad bottomPad",
          },
          {
            type: "BigDivider",
            title: "Digital Pills",
          },
          {
            type: "MediaBlock",
            media: "../../assets/how-to-quit/htq-21.png",
            mediaMobile: "../../assets/how-to-quit/htq-21-mobile.png",
            mediaType: "image",
            style: "full topPad bottomPad",
          },

          {
            type: "RoundedDivider",
            title:
              "High-quality CGI representations of pills were created to serve as focal points across various mediums, ensuring accuracy and engagement without necessitating actual pharmaceuticals.",
            left: "",
            right: "",
            style: "narrow",
          },

          {
            type: "MediaGrid",
            columns: 3,
            style: "grid-3",
            media: [
              {
                type: "video",
                file: "../../assets/videos/pill-1.mp4",
              },
              {
                type: "video",
                file: "../../assets/videos/pill-2.mp4",
              },
              {
                type: "video",
                file: "../../assets/videos/pill-3.mp4",
              },
              {
                type: "video",
                file: "../../assets/videos/pill-4.mp4",
              },
              {
                type: "video",
                file: "../../assets/videos/pill-5.mp4",
              },
              {
                type: "video",
                file: "../../assets/videos/pill-6.mp4",
              },
            ],
          },
          {
            type: "MediaBlock",
            media: "../../assets/how-to-quit/htq-22.jpg",
            mediaMobile: "../../assets/how-to-quit/htq-22-mobile.jpg",
            mediaType: "image",
            style: "full-bleed",
          },

          {
            type: "ImageRow",
            style: "two-column",
            images: [
              {
                background: "",
                media: "../../assets/videos/how-to-quit-video-4.mp4",
                style: "full two-column rounded ",
                mediaType: "video",
                mediaStyle: "htqVideo",
                //image: "../../assets/how-to-quit/htq-23.png",

                // mediaStyle: "videoOverlayFull",
                // style: "full",
                // type: "video",

                // style: "maxWidth80",
              },
              {
                background: "",
                media: "../../assets/videos/how-to-quit-video-5.mp4",
                style: "full two-column rounded htqVideo",
                mediaType: "video",
                mediaStyle: "htqVideo",
              },
            ],
          },

          {
            type: "BigDivider",
            title: "Website Design",
          },

          {
            type: "MediaBlock",
            media: "../../assets/videos/how-to-quit-video-3.mp4",
            mediaType: "video",
            // extraImage: "../../assets/meta-ai/meta-ai-logo-2.svg",
            // extraImageStyle: "center",
            style: "full-bleed fit",
          },
          {
            type: "ImageRow",
            style: "two-column",
            images: [
              {
                background: "",
                image: "../../assets/how-to-quit/htq-25.jpg",
                style: "full two-column rounded",
              },
              {
                background: "",
                image: "../../assets/how-to-quit/htq-26.jpg",
                style: "full two-column rounded",
              },
            ],
          },
          {
            type: "MediaBlock",
            media: "../../assets/how-to-quit/htq-27.jpg",
            mediaMobile: "../../assets/how-to-quit/htq-27-mobile.jpg",
            mediaType: "image",
            style: "full-bleed",
          },

          {
            type: "ImageRow",
            style: "three-column",
            images: [
              {
                background: "htqCell1",
                //image: "../../assets/how-to-quit/col-1-info.svg",
                media: "../../assets/videos/pill-7.mp4",
                style: "three-column rounded htqVideo3 htqCell1",
                mediaType: "video",
                mediaStyle: "htqVideo3",
              },
              {
                background: "htqCell2",
                //image: "../../assets/how-to-quit/col-2-info.svg",
                media: "../../assets/videos/pill-8.mp4",
                style: "three-column rounded htqVideo3 htqCell1",
                mediaType: "video",
                mediaStyle: "htqVideo3",
              },
              {
                background: "htqCell3",
                //image: "../../assets/how-to-quit/col-3-info.svg",
                media: "../../assets/videos/pill-9.mp4",
                style: "three-column rounded htqVideo3 htqCell1",
                mediaType: "video",
                mediaStyle: "htqVideo3",
              },
            ],
          },

          {
            type: "RoundedDivider",
            title:
              "The campaign hub, 855-How-To-Quit.org, features easy access to stories, resources, and support with a clean, sharp, and easily navigable interface.",
            left: "",
            right: "",
            style: "narrow",
          },
          {
            type: "HTQTitleBlock",
            style: "bottom-blocks-3a htqLightGreen",
            title: [
              {
                text: "The Power",
                color: "htqLightGreen",
                align:
                  "right show-line-mobile ignore-align-mobile mobile-right",
              },
              {
                text: "of Working",
                color: "htqLightGreen",
                align: "left show-line-mobile ignore-align-mobile ",
              },

              {
                text: "Together",
                color: "htqLightGreen",
                align: "right2 show-line-mobile ignore-align-mobile unsetWidth",
              },
            ],
            smallText:
              "This project was made possible through a deep belief in partnership. We are a coalition of NGOs, healthcare professionals, activists, media partners, and creative talents, including renowned photographer Jeffrey Stockbridge and typographic experts at Kimera. ",
            smallText2:
              "These partnerships amplified the campaign's reach and impact, bringing together diverse creative disciplines and resources towards a common goal.",
            // backgroundImageMobile: {
            //   image: "../../assets/meta-ai/meta-ai-titles-block-1-mobile.png",
            // },
            // backgroundImage: {
            //   image: "../../assets/meta-ai/meta-ai-titles-block-1.png",
            //   style: "style-1",
            // },
          },
          {
            type: "MediaBlock",
            media: "../../assets/how-to-quit/htq-28.jpg",
            mediaMobile: "../../assets/how-to-quit/htq-28-mobile.jpg",
            mediaType: "image",
            style: "full-bleed",
          },
          {
            type: "MediaBlock",
            media: "../../assets/how-to-quit/htq-29.png",
            mediaMobile: "../../assets/how-to-quit/htq-29-mobile.png",
            mediaType: "image",
            style: "full-bleed",
          },
          {
            type: "MediaBlock",
            media: "../../assets/how-to-quit/htq-30.jpg",
            mediaType: "image",
            style: "full-bleed",
          },
          {
            type: "MediaBlock",
            media: "../../assets/how-to-quit/htq-31.png",
            mediaMobile: "../../assets/how-to-quit/htq-31-mobile.jpg",
            mediaType: "image",
            style: "full-bleed",
          },

          {
            type: "HTQTitleBlock",
            style: "bottom-blocks-3a black",
            title: [
              {
                text: "&nbsp;Creativity",
                color: "black",
                align:
                  "left3 show-line-mobile ignore-align-mobile mobile-right",
              },
              {
                text: "for &nbsp;&nbsp; Change",
                color: "black",
                align: "hideOnMobile left show-line-mobile ignore-align-mobile",
              },
              {
                text: "for Change",
                color: "black",
                align:
                  "hideOnDesktop justify show-line-mobile ignore-align-mobile",
              },
            ],
            smallText:
              "855-HOW-TO-QUIT is evidence of the power of creativity to address complex challenges. By reimagining the approach to opioid addiction support, The team has provided a novel resource for those in need and underscored the potential for design and innovation to create real positive change.",
            smallText2:
              "We sincerely hope this helps folks deal with these severe problems and demonstrates that creative thinking can be a powerful ally, even and especially when faced with daunting crises.",
            // backgroundImageMobile: {
            //   image: "../../assets/meta-ai/meta-ai-titles-block-1-mobile.png",
            // },
            // backgroundImage: {
            //   image: "../../assets/meta-ai/meta-ai-titles-block-1.png",
            //   style: "style-1",
            // },
          },

          {
            type: "MediaBlock",
            media: "../../assets/videos/how-to-quit-video-6.mp4",
            mediaType: "video",
            // extraImage: "../../assets/meta-ai/meta-ai-logo-2.svg",
            // extraImageStyle: "center",
            style: "full-bleed fit",
          },

          {
            type: "ImageRow",
            style: "three-column",
            images: [
              {
                background: "",
                image: "../../assets/how-to-quit/htq-32.jpg",
                style: "full three-column rounded",
              },
              {
                background: "",
                image: "../../assets/how-to-quit/htq-33.jpg",
                style: "full three-column rounded",
              },
              {
                background: "",
                image: "../../assets/how-to-quit/htq-34.jpg",
                style: "full three-column rounded",
              },
            ],
          },

          {
            type: "MediaBlock",
            media: "../../assets/how-to-quit/htq-35.jpg",
            mediaType: "image",
            style: "full-bleed",
          },

          {
            type: "Quotes",
            quotes: [
              {
                image: "../../assets/how-to-quit/M Raw Materials.svg",
                imageType: "image",
                client: "855-HOW-TO-QUIT",
                title: "Creativity For Change.",
                quote:
                  "“Creativity can make a dent on almost any problem in the world. If the dent we make with our work is saving a single life, all of it is worth it.”",
                tags: ["CAMPAIGN", "Branding"],
                by: "by",
                attribute: "Pablo Marques",
                attributeRole: "Chief Creative Officer,",
                attributeCompany: "Raw Materials",
              },
            ],
          },
        ],
      },
      {
        title: "AR Design",
        id: "ar-design",
        slug: "ar-design",
        pageTitle: "AR Design | Contact to See  | Raw Materials",
        pageDescription:
          "Helping Meta shape how computers perceive the world to build the foundation for the future of AR.",
        description:
          "Helping Meta shape how computers perceive the world to build the foundation for the future of AR.",
        category: "Contact to See",
        logo: "../../assets/meta-ai/meta-ai-logo.svg",
        logoStyle: "h32",
        color: "metaBlue",
        active: false,
        disabled: false,
        thumbs: [
          { image: "../../assets/project-aria/PA-CS-01.jpg" },
          { image: "../../assets/project-aria/PA-CS-02.jpg" },
          { image: "../../assets/project-aria/PA-CS-03.jpg" },
          { image: "../../assets/project-aria/PA-CS-04.jpg" },
          { image: "../../assets/project-aria/PA-CS-05.jpg" },
        ],
      },
      // {
      //   title: "Project Aria",
      //   id: "project-aria",
      //   slug: "project-aria",
      //   pageTitle: "Project Aria | Research | Raw Materials",
      //   pageDescription:
      //     "Helping Meta shape how computers perceive the world to build the foundation for the future of AR.",
      //   description:
      //     "Helping Meta shape how computers perceive the world to build the foundation for the future of AR.",
      //   category: "Research",
      //   logo: "../../assets/meta-ai/meta-ai-logo.svg",
      //   logoStyle: "h32",
      //   color: "metaBlue",
      //   active: true,

      //   thumbs: [
      //     { image: "../../assets/project-aria/PA-CS-01.jpg" },
      //     { image: "../../assets/project-aria/PA-CS-02.jpg" },
      //     { image: "../../assets/project-aria/PA-CS-03.jpg" },
      //     { image: "../../assets/project-aria/PA-CS-04.jpg" },
      //     { image: "../../assets/project-aria/PA-CS-05.jpg" },
      //   ],
      //   blocks: [
      //     {
      //       type: "MediaBlock",
      //       media: "../../assets/project-aria/aria-lines.json",
      //       mediaType: "animation",
      //       extraImage: "../../assets/project-aria/meta-logo-black.svg",
      //       extraImageStyle: "",
      //       style: "inset-50 tight",
      //     },
      //     {
      //       type: "ImageRow",
      //       style: "",
      //       images: [
      //         {
      //           background: "black",
      //           image: "../../assets/project-aria/block-2-col-1.svg",
      //           style: "height90",
      //         },
      //         {
      //           background: "metaBlue",
      //           image:
      //             "../../assets/7-eleven-rewards/7-eleven-block-8-col-2.png",
      //           media: "../../assets/project-aria/block-2-cl-2.jpg",
      //           media2: "../../assets/project-aria/project_aria_animated.gif",
      //           mediaType: "complex",
      //           mediaStyle: "videoOverlay",
      //           style: "maxWidth80",
      //           type: "complex",
      //         },
      //         {
      //           background: "black",
      //           image: "../../assets/project-aria/block-2-col-3.svg",
      //           style: "height90",
      //         },
      //       ],
      //     },
      //     {
      //       type: "RoundedDivider",
      //       title:
      //         "We ( <span style='color: #1778F2; font-weight:400;'>Designed</span>  ) the companion app, processes and experiences<br />to support the (<img class='icon' src='../../assets/project-aria/meta-blue-icon.svg' />) Aria Program.",
      //       left: "",
      //       right: "",
      //       style: "sf-pro",
      //     },
      //     {
      //       type: "MediaBlock",
      //       media: "../../assets/project-aria/aria-text.svg",
      //       mediaType: "image",
      //       style: "bottomPadLarger topPad full",
      //     },
      //     {
      //       type: "MetaTitleBlock",
      //       style: "bottom-blocks-2",
      //       title: [
      //         {
      //           text: "Researching",
      //           color: "black",
      //           align: "right show-line-mobile ignore-align-mobile",
      //         },
      //         {
      //           text: "The",
      //           color: "black",
      //           align: "right4 show-line-mobile ignore-align-mobile",
      //         },
      //         {
      //           text: "Frontiers Of",
      //           color: "black",
      //           align: "left show-line-mobile ignore-align-mobile",
      //         },
      //         {
      //           text: "Perception",
      //           color: "black",
      //           align: "right show-line-mobile ignore-align-mobile",
      //         },
      //       ],
      //       smallText:
      //         "In order to realize the vision of Augmented Reality experiences that immerse the viewer in a fully responsive world, Meta created Project Aria. The project uses an experimental headset that records everything the wearer looks at, tracking eye movements and recording video.",
      //       smallText2:
      //         "<p>Raw Materials designed the tools to help Aria’s users record the world as they experience it.</p><p>This work helps researchers better understand how humans sense and experience their environment — continuing Meta’s leadership role in exploring the power of augmented reality.</p>",
      //     },

      //     {
      //       type: "VideoPlayerBlock",
      //       media: "https://player.vimeo.com/video/853414332?h=52a32b81b2",
      //       image: "../../assets/project-aria/aria-video-placeholder.jpg",
      //       style: "",
      //     },
      //     {
      //       type: "BigDivider",
      //       title: "A Natural Extension",
      //     },
      //     {
      //       type: "BigDivider",
      //       title: "of the Reality Labs Team",
      //     },
      //     {
      //       type: "ImageSequenceBlock",
      //       startFrame: 1,
      //       endFrame: 185,
      //       path: "../../assets/project-aria/glasses/",
      //       ext: "jpg",
      //       media: "../../assets/project-aria/rm-glasses-r2.mp4",
      //       image: "../../assets/project-aria/glasses-placeholder.jpg",
      //       style: "",
      //     },
      //     {
      //       type: "RoundedDivider",
      //       title:
      //         "<p style='margin:0;padding:0;max-width:90%;margin-left:auto;margin-right:auto;'>We’ve integrated into Meta’s team as a ( <span style='color: #1778F2; font-weight:400;'>Special Ops</span>  ) arm — designing the ( <span style='color: #1778F2; font-weight:400;'>User Experience</span>  ) of Aria’s companion app, optimizing the tools researchers use to manipulate ( <span style='color: #1778F2; font-weight:400;'>Data</span>  ), and improving the features and ( <span style='color: #1778F2; font-weight:400;'>Functionality</span>  ) of the device.</p>",
      //       left: "",
      //       right: "",
      //       style: "sf-pro",
      //     },
      //     {
      //       type: "ImageRow",
      //       style: "",
      //       images: [
      //         {
      //           background: "metaGreyWhite",
      //           media: "../../assets/project-aria/Diagram-c.mp4",
      //           mediaType: "video",
      //           mediaStyle: "videoOverlayFull",
      //           style: "full",
      //           type: "video",
      //         },
      //         {
      //           background: "metaBlue",
      //           image:
      //             "../../assets/7-eleven-rewards/7-eleven-block-8-col-2.png",
      //           media: "../../assets/project-aria/block-10-col-2.jpg",
      //           mediaType: "complex",
      //           mediaStyle: "videoOverlay",
      //           style: "maxWidth80",
      //           type: "complex",
      //         },
      //         {
      //           background: "white",
      //           media: "../../assets/project-aria/DogWalking-c.mp4",
      //           mediaType: "video",
      //           mediaStyle: "videoOverlayFull",
      //           style: "full",
      //           type: "video",
      //         },
      //       ],
      //     },
      //     {
      //       type: "MetaTitleBlock",
      //       style: "bottom-blocks-2",
      //       title: [
      //         {
      //           text: "Grounding",
      //           color: "black",
      //           align: "right2 show-line-mobile ignore-align-mobile",
      //         },
      //         {
      //           text: "AR in",
      //           color: "black",
      //           align: "left show-line-mobile ignore-align-mobile",
      //         },
      //         {
      //           text: "Real Life",
      //           color: "black",
      //           align: "right2 show-line-mobile ignore-align-mobile",
      //         },
      //       ],
      //       smallText:
      //         "<p>In order for the research to be effective, we needed people to record as much of their lives as possible.</p><p>Users were averaging 10 hours a day of raw footage, recording every aspect of their lives. That is a lot to digest, review, and edit to ensure what one wants to be private stays private.</p>",
      //       smallText2:
      //         "<p>We designed an onboarding experience that ensured participants fully understood how to support the research while protecting their privacy and that of others.</p><p>It was essential that, through the mobile companion app, users had an always-available way to control the device and data and to turn off, edit, and manage the content quickly and easily.</p>",
      //     },

      //     {
      //       type: "MediaBlock",
      //       media: "../../assets/project-aria/aria-full-image-1.jpg",
      //       mediaMobile:
      //         "../../assets/project-aria/aria-full-image-1-mobile.jpg",
      //       mediaType: "image",
      //       style: "full-bleed",
      //     },
      //     {
      //       type: "MetaTitleBlock",
      //       style: "bottom-blocks-2",
      //       title: [
      //         {
      //           text: "Increasing",
      //           color: "black",
      //           align: "right2 show-line-mobile ignore-align-mobile",
      //         },
      //         {
      //           text: "The",
      //           color: "black",
      //           align: "left2 show-line-mobile ignore-align-mobile",
      //         },
      //         {
      //           text: "Speed Of",
      //           color: "black",
      //           align: "right2 show-line-mobile ignore-align-mobile",
      //         },
      //         {
      //           text: "Invention",
      //           color: "black",
      //           align: "left show-line-mobile ignore-align-mobile",
      //         },
      //       ],
      //       smallText:
      //         "<p>Our work helped Meta increase the scale, speed, and ambition of Project Aria.</p><p>We helped them transition it from an early-stage internal project to a world-renowned Augmented Reality research program, now a core part of Meta's Reality Labs effort.</p>",
      //       smallText2:
      //         "<p>Our work spanned designing the content and tools used to onboard and train research participants, improving the effectiveness of Aria's internal tools, and creating a mechanism to gather and analyze user feedback.</p><p>We pushed the project forward by defining and prioritizing &quot;moonshot&quot; ideas to help the team imagine the subsequent phases of AR innovation.</p>",
      //     },

      //     {
      //       type: "MediaBlock",
      //       media: "../../assets/project-aria/aria-full-image-2.jpg",
      //       mediaMobile:
      //         "../../assets/project-aria/aria-full-image-2-mobile.jpg",
      //       mediaType: "image",
      //       style: "full-bleed",
      //     },
      //     {
      //       type: "MetaTitleBlock",
      //       style: "bottom-blocks-2",
      //       title: [
      //         {
      //           text: "The Future",
      //           color: "black",
      //           align: "right2 show-line-mobile ignore-align-mobile",
      //         },
      //         {
      //           text: "Of",
      //           color: "black",
      //           align: "right5 show-line-mobile ignore-align-mobile",
      //         },
      //         {
      //           text: "Computing",
      //           color: "black",
      //           align: "left show-line-mobile ignore-align-mobile",
      //         },
      //       ],
      //       smallText:
      //         "<p>Project Aria is a critical piece of the puzzle in helping Meta execute their strategic vision of leading the development of the next generation of computing — Extended Reality and Artificial Intelligence.</p>",
      //       smallText2:
      //         "<p>Raw Materials’ partnership with Meta on Project Aria has contributed to the advancement of user-centric research in machine perception and has helped the broader technology community understand how AR can work in the real world.</p>",
      //     },
      //     {
      //       type: "Quotes",
      //       quotes: [
      //         {
      //           image: "../../assets/animations/M Meta export.json",
      //           imageType: "lottie",
      //           client: "Meta",
      //           title: "Project Aria",
      //           quote:
      //             "“Aria is now an essential part of building the machine perception foundation for the AR future”",
      //           tags: ["BRAND STRATEGY", "Experience Design", "Research"],
      //           by: "from",
      //           attribute: "Michael Abrashr",
      //           attributeRole: "Chief Scientist",
      //           attributeCompany: "Meta Reality Labs",
      //         },
      //       ],
      //     },
      //   ],
      // },

      {
        title: "NBC Universal",
        category: "Product Design",
        logo: "../../assets/nbc-universal/nbc.svg",
        logoStyle: "h48",
        disabled: true,
        thumbs: [
          { image: "../../assets/audi/audi-thumb-4.png" },
          { image: "../../assets/audi/audi-thumb-5.png" },
          { image: "../../assets/audi/audi-thumb-1.png" },
          { image: "../../assets/audi/audi-thumb-2.png" },
          { image: "../../assets/audi/audi-thumb-3.png" },
        ],
      },
      {
        title: "Unity",
        category: "Brand Design",
        logo: "../../assets/unity/unity-logo.svg",
        logoStyle: "h48",
        disabled: true,
        thumbs: [
          { image: "../../assets/unity/UN-CS-01.jpg" },
          { image: "../../assets/unity/UN-CS-02.jpg" },
          { image: "../../assets/unity/UN-CS-03.jpg" },
          { image: "../../assets/unity/UN-CS-04.jpg" },
          { image: "../../assets/unity/UN-CS-05.jpg" },
        ],
      },
      {
        title: "Grammarly",
        category: "Product Design",
        logo: "../../assets/grammarly/grammarly.svg",
        logoStyle: "h32",
        disabled: true,
        thumbs: [
          { image: "../../assets/audi/audi-thumb-3.png" },
          { image: "../../assets/audi/audi-thumb-5.png" },
          { image: "../../assets/audi/audi-thumb-1.png" },
          { image: "../../assets/audi/audi-thumb-2.png" },
          { image: "../../assets/audi/audi-thumb-3.png" },
          { image: "../../assets/audi/audi-thumb-4.png" },
        ],
      },

      // {
      //   title: "Four Hands",
      //   category: "Product Design",
      //   logo: "../../assets/four-hands/four-hands-logo.svg",
      //   logoStyle: "h16",
      //   thumbs: [
      //     { image: "../../assets/four-hands/FH-CS-05.jpg" },
      //     { image: "../../assets/four-hands/FH-CS-01.jpg" },
      //     { image: "../../assets/four-hands/FH-CS-02.jpg" },
      //     { image: "../../assets/four-hands/FH-CS-03.jpg" },
      //     { image: "../../assets/four-hands/FH-CS-04.jpg" },
      //   ],
      // },
      // {
      //   title: "Audi Mobility",
      //   category: "Brand Design",
      //   logo: "../../assets/audi/audi-logo.svg",
      //   logoStyle: "h32",
      //   thumbs: [
      //     { image: "../../assets/audi/audi-thumb-4.png" },
      //     { image: "../../assets/audi/audi-thumb-1.png" },
      //     { image: "../../assets/audi/audi-thumb-2.png" },
      //     { image: "../../assets/audi/audi-thumb-3.png" },
      //     { image: "../../assets/audi/audi-thumb-5.png" },
      //   ],
      // },

      // {
      //   title: "Peddle",
      //   category: "Brand Design",
      //   logo: "../../assets/peddle/peddle.svg",
      //   logoStyle: "h32",
      //   disabled: true,
      //   thumbs: [
      //     { image: "../../assets/audi/audi-thumb-1.png" },
      //     { image: "../../assets/audi/audi-thumb-5.png" },
      //     { image: "../../assets/audi/audi-thumb-2.png" },
      //     { image: "../../assets/audi/audi-thumb-3.png" },
      //     { image: "../../assets/audi/audi-thumb-4.png" },
      //   ],
      // },

      // {
      //   title: "Facebook Live Maps",
      //   category: "Product Design",
      //   logo: "../../assets/meta-ai/meta-ai-logo.svg",
      //   logoStyle: "h32",
      //   disabled: true,
      //   thumbs: [
      //     { image: "../../assets/audi/audi-thumb-2.png" },
      //     { image: "../../assets/audi/audi-thumb-5.png" },
      //     { image: "../../assets/audi/audi-thumb-1.png" },
      //     { image: "../../assets/audi/audi-thumb-3.png" },
      //     { image: "../../assets/audi/audi-thumb-4.png" },
      //   ],
      // },
    ],
  },

  talent: {
    index: 4,
    id: "talent",
    pageTitle: "The Raw Talent Network | Raw Materials",
    sectionTitle: "You are now entering ( Talent ) section",
    pageDescription:
      "Our take on the future of creative work. A global community of collaborators empowered to do the best work of their lives.",
    title1: "Talent",
    blocks: [
      {
        type: "MediaBlock",
        media: "../../assets/animations/RN WORLD 04.json",
        mediaType: "animation",
        style: "full mobileRotate90",
      },
      {
        type: "TextBlock",
        text: [
          {
            text: "Finding the best talent in the world and helping them make the best work of their lives.",
            color: "offwhite",
          },
        ],
        smallText:
          "We design and launch unusually creative digital products that change brands’ fortunes.",
      },
      {
        type: "TalentTitleBlock",
        title: [
          {
            preText: "The",
            image: "../../assets/images/talent-title-block-row-1.svg",
            text: "aw",
            color: "blue",
          },
          {
            image: "../../assets/images/talent-title-block-row-2.svg",
            text: "alent",
            color: "blue",
            align: "right",
          },
          {
            image: "../../assets/images/talent-title-block-row-3.svg",
            text: "etwork",
            color: "blue",
            align: "right",
          },
        ],
        smallText:
          "A community created by RM to be a super connector for the unusually talented.",
      },
      {
        type: "TalentPeopleRow",
        people: [
          {
            role: "Head of Delivery",
            name: "Rebecca Pustizzi",
            image: "../../assets/people/talent-rebecca-pustizzi.png",
          },
          {
            role: "Executive Producer",
            name: "Matt Walsh",
            image: "../../assets/people/talent-matt-walsh.png",
          },
          {
            role: "Experience Lead",
            name: "Charles Law",
            image: "../../assets/people/talent-charles-law.png",
          },
          {
            role: "Executive Producer",
            name: "Matt Walsh",
            image: "../../assets/people/talent-matt-walsh.png",
          },
          {
            role: "Head of Delivery",
            name: "Rebecca Pustizzi",
            image: "../../assets/people/talent-rebecca-pustizzi.png",
          },
        ],
      },
      {
        type: "Divider",
        title: "HOW WE WORK",
        arrow: "../../assets/animations/ArrowRed.json",
      },
      {
        type: "TabbedBlock",
        tabs: [
          {
            number: "01",
            title: "( Everywhere Company )",
            text: "The freedom and flexibility to work and collaborate everywhere.",
            image: "../../assets/images/talent-tabbed-block-1.svg",
          },
          {
            number: "02",
            title: "( The Academy )",
            text: "Bringing up the next generation of creative product designers.",
            image: "../../assets/images/talent-tabbed-block-2.svg",
          },
          {
            number: "03",
            title: "( The Creative Community )",
            text: "Connecting with creatives around the world and inspiring them to join our endeavor.",
            image: "../../assets/images/talent-tabbed-block-3.svg",
          },
        ],
      },
    ],
  },

  careers: {
    index: 5,
    id: "careers",
    pageTitle: "Join the Raw Talent Network | Careers at Raw Materials",
    sectionTitle: "You are now entering ( Careers ) section",
    pageDescription:
      "We are looking for the unusually talented. Explore open roles and join the Raw Talent Network.",
    title1: "Careers",
    blocks: [
      {
        type: "CareersTitleBlock",
        title: [
          {
            text: "We",
            color: "black",
          },
          {
            text: "&nbsp;",
            color: "black",
          },
          {
            text: "Are",
            color: "black",
          },
          {
            text: "&nbsp;",
            color: "black",
          },
          {
            text: "Looking",
            color: "black",
          },
          {
            text: "&nbsp;",
            color: "black",
          },
          {
            text: "For",
            color: "black",
          },
          {
            text: "The",
            color: "black",
          },
          {
            text: "Unusually",
            background: "red",
            color: "black",
          },
          {
            text: "Talented",
            background: "black",
            color: "red",
          },
          {
            text: "&nbsp;",
            color: "black",
          },
        ],
      },
      {
        type: "Divider",
        title: "OPEN POSITIONS",
        arrow: "../../assets/animations/ArrowBlack.json",
      },
      {
        type: "CareersListBlock",
        applyGeneralTitle: "TOO UNUSUAL AND DON’T SEE YOURSELF HERE?",
        applyGeneralCategory: "( REACH OUT )",
        applyGeneralUrl: "https://google.com",
        noPositions: "There are no current openings.",
        //positions: [],
        positions: [
          {
            title: "Creative, Product Design ",
            color: "black",
            category: "( CREATIVE )",
            urlText: "Apply on Greenhouse",
            url: "https://boards.greenhouse.io/rawmaterials/jobs/4235383006",
            text: [
              "Product designers are one of the most important raw materials of raw materials. They are the ones holding the pen and bearing the responsibility for bringing to the world the work of a whole team.",
              "They are magnificent and curious problem solvers — With the imagination of a poet and the rigor of a mathematician, just as comfortable in the world of art or technology.",
              "A product designer’s first responsibility is to their users and the world their designs will shape — knowing this, they are keen students of the human condition and the systems that drive life.",
            ],
            locationTitle: "Location",
            location: "( Remote )",
            timezoneTitle: "Timezone",
            timezone: "( +/- 3 hours Pacific Time )",
          },
          {
            title: "Creative, Motion Design ",
            color: "black",
            category: "( DELIVERY )",
            urlText: "Apply on Greenhouse",
            url: "https://boards.greenhouse.io/rawmaterials/jobs/4235399006",
            text: [
              "In a world mediated by screens, static designs are unfit and boring. Everything we design should move, behave, respond, and feel alive. The world of the screen should not feel different from the material world.",
              "This is why we love Motion Designers. From the extraordinary to the succinct, they can give life, personality, and life-like behaviors to design work.",
              "Motion designers can explain functionalities and convey emotions and tell stories through movement and the manipulation of time.",
            ],
            locationTitle: "Location",
            location: "( Remote )",
            timezoneTitle: "Timezone",
            timezone: "( ET )",
          },

          {
            title: "For the Too Unusual",
            color: "black",
            category: "( DATA )",
            urlText: "Apply on Greenhouse",
            url: "https://boards.greenhouse.io/rawmaterials/jobs/4236602006",
            text: [
              "You are great at something we haven’t listed here. God, you are great at more than one thing; you are so great and so unusual you don’t even know how you would fit with us.",
              "But you want to chat anyway. You like the mission we are on. You have used plenty of things that bored you and made you mad. Things you know you could make better if only you had the chance.",
              "You clicked this because the Unusual attracts you. You are unusual. You understand different, you celebrate different, you want to make the different.",
            ],
            locationTitle: "Location",
            location: "( Remote )",
            timezoneTitle: "Timezone",
            timezone: "( ET )",
          },
        ],
      },
    ],
  },

  contact: {
    index: 6,
    id: "contact",
    pageTitle: "Contact | Raw Materials",
    sectionTitle: "You are now entering ( Contact ) section",
    pageDescription: "Let's talk creativity.",
    title1: "Contact",
    blocks: [
      {
        type: "TitleBlock",
        style: "ignore-bottom-height-mobile",
        title: [
          { text: "Let’s", align: "show-line-mobile ignore-align-mobile" },
          {
            text: "Talk",
            align: "indent show-line-mobile ignore-align-mobile",
          },
          { text: "Creativity.", align: "right2 ignore-align-mobile" },
        ],
      },
      {
        type: "ContactPeopleRow",
        people: [
          {
            title: "New Business",
            text: "Talk with John",
            url: "mailto:john@therawmaterials.com",
            image: "../../assets/people/contact-people-1.png",
            media: "../../assets/people/john_contact-1.mp4",
            mediaType: "video",
          },
          {
            title: "News & Press",
            text: "Talk with Jennifer",
            url: "mailto:jennifer.allen@therawmaterials.com",
            image: "../../assets/people/contact-people-2.png",
            media: "../../assets/people/jennifer_contact-1.mp4",
            mediaType: "video",
          },
          {
            title: "Join The Team",
            text: "Talk with Pablo",
            url: "mailto:pabs@therawmaterials.com",
            image: "../../assets/people/pablo-marques.png",
            media: "../../assets/people/pabs_contact-1-b.mp4",
            mediaType: "video",
          },
          // {
          //   title: "Join The Team",
          //   text: "Talk with Courtney",
          //   url: "mailto:courtney.burns@therawmaterials.com",
          //   image: "../../assets/people/contact-people-3.png",
          //   media: "../../assets/people/courtney_contact-1.mp4",
          //   mediaType: "video",
          // },
          // {
          //   title: "Everything Else",
          //   text: "Talk with Us",
          //   url: "mailto:hi@therawmaterials.com",
          //   image: "../../assets/people/contact-people-4.png",
          //   media: "../../assets/people/rebecca_contact-1.mp4",
          //   mediaType: "video",
          // },
        ],
      },
      {
        type: "Divider",
        title: "ONE COMPANY, 100 STUDIOS",
        arrow: "../../assets/animations/ArrowBlack.json",
      },
    ],
  },

  unusualIndex: {
    index: 7,
    id: "unusual-index",
    pageTitle: "The (Unusual) Index",
    sectionTitle: "You are now entering ( Unusual Index ) section",
    pageDescription:
      "The (Unusual) Index is Raw Materials' compendium of unusually great products, people, and ideas that inspire us to think differently.",
    title1: "Unusual Index",
    blocks: [
      {
        type: "UnusualMediaBlock",
        media: "../../assets/images/unusual-index-media-block-1.svg",
        mediaType: "image",
        style: "full-bleed",
      },
      {
        type: "TextBlock",
        text: [
          {
            text: "The (Unusual) Index is our compendium of unusually great products, people, and ideas that inspire us to think differently.",
            color: "black",
          },
        ],
      },
      {
        type: "Divider",
        title: "ISSUE #1 IS CURRENTLY BEING COOKED. CURIOUS? SUBSCRIBE BELOW.",
        arrow: "../../assets/animations/ArrowBlack.json",
      },

      {
        type: "UnusualSubscribeBlock",
        title: "NOTACULT@THERAWMATERIALS.COM",
        // url: "https://handsome.us5.list-manage.com/subscribe/post-json?u=4d8f5a11007230e3136b358e6&id=51c2278739",
        // url: "https://handsome.us5.list-manage.com/subscribe/post?u=4d8f5a11007230e3136b358e6&id=51c2278739&f_id=009674ebf0",
        url: "https://handsome.us5.list-manage.com/subscribe/post?u=4d8f5a11007230e3136b358e6&id=51c2278739&f_id=009674ebf0",
        subscribe: "SUBSCRIBE",
        thanks: "Thank you for subscribing!",
      },
    ],
  },

  footer: {
    title: "Raw Materials.",
    subTitle: "An Unusual Design Company.",
    social: [
      { title: "Instagram", url: "https://instagram.com/raw._.materials" },
      { title: "Twitter", url: "https://twitter.com/rawmaterialsco" },
      {
        title: "Linkedin",
        url: "https://www.linkedin.com/company/raw-materials-co/",
      },
      // { title: "Medium", url: "https://medium.com" },
    ],

    contactUrl: "mailto:hi@therawmaterials.com",
    contactText: "Contact Us",
    year: "© 2023",
  },
};

export { data };
