import "./MediaGrid.scss";
import { Fragment, useEffect, useRef } from "react";
import gsap from "gsap";
import parse from "html-react-parser";

function MediaGrid(props) {
  const tl = useRef();

  return (
    <div
      id={props.id}
      className={`media-grid ${
        props.background ? props.background + "Background" : ""
      } ${props.color ? props.color : ""} ${props.data.style}`}
    >
      {props.data.media.map((c, i) => {
        return (
          <div
            key={"media-grid-cell" + i}
            className={`block ${props.background}Background ${props.color} ${
              "cell-" + i
            }`}
          >
            {c.type === "image" && (
              <img className={`media`} src={c.file} alt="" />
            )}
            {c.type === "video" && (
              <video
                autoPlay
                loop
                muted
                playsInline
                src={c.file}
                type="video/mp4"
              />
            )}
          </div>
        );
      })}
    </div>
  );
}

export default MediaGrid;
