import "./VoltaMediaGrid.scss";
import { Fragment, useEffect, useRef } from "react";
import gsap from "gsap";
import parse from "html-react-parser";

function VoltaMediaGrid(props) {
  const tl = useRef();

  return (
    <div
      id={props.id}
      className={`volta-media-grid ${
        props.background ? props.background + "Background" : ""
      } ${props.color ? props.color : ""} ${props.data.style}`}
    >
      {props.data.media.map((c, i) => {
        return (
          <div
            key={"volta-media-grid-cell" + i}
            className={`block ${props.background}Background ${props.color} ${
              "cell-" + i
            }`}
          >
            <img className={`media`} src={c.file} alt="" />
          </div>
        );
      })}
    </div>
  );
}

export default VoltaMediaGrid;
