import "./MetaAIMediaBlock2.scss";
import { useEffect, useRef, useState } from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import parse from "html-react-parser";
import gsap from "gsap";
import ReactPlayer from "react-player";

function MetaAIMediaBlock2(props) {
  const tl = useRef();
  const el = useRef();
  const lottiePlayer = useRef();
  const videoPlayer = useRef();

  const [playing, setPlaying] = useState(false);
  const [paused, setPaused] = useState(true);

  useEffect(() => {
    new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          // callback(element);
          // observer.disconnect();
          //setPlaying(true);
          setPlaying(true);
        } else {
          setPlaying(false);
        }
      });
    }).observe(el.current);
  }, []);

  return (
    <div
      id={props.id}
      ref={el}
      className={`meta-ai-media-block2 ${
        props.background ? props.background : ""
      }Background ${props.data.style} ${
        props.hideOnMobile ? "hide-on-mobile" : ""
      }`}
    >
      {props.data.subTitle && (
        <h1 className={`sub-title ${props.data.subTitleColor}`}>
          {props.data.subTitle}
        </h1>
      )}
      {props.data.title && (
        <h1 className={`title ${props.data.titleColor}`}>{props.data.title}</h1>
      )}
      {props.data.subTitle2 && (
        <h1 className={`sub-title2 ${props.data.subTitle2Color}`}>
          {props.data.subTitle2}
        </h1>
      )}
      {props.data.mediaType === "image" && (
        <img
          className={`media image ${props.data.style} ${
            props.data.mediaMobile ? "mobile-hide" : ""
          }`}
          src={props.data.media}
          alt=""
        />
      )}
      {props.data.mediaType === "image" && props.data.mediaMobile && (
        <img
          className={`media-mobile image ${props.data.style}`}
          src={props.data.mediaMobile}
          alt=""
        />
      )}
      {props.data.mediaType === "animation" && (
        <div className={`media lottie ${props.data.style}`}>
          <Player
            // animationData={animationData}
            src={props.data.media}
            loop
            autoplay
            ref={lottiePlayer}

            // height={400}
            // width={400}
          />
        </div>
      )}
      {props.data.mediaType === "video" && (
        <div className={`media video ${props.data.style}`}>
          <ReactPlayer
            // animationData={animationData}
            url={props.data.media}
            loop
            playing={true}
            ref={videoPlayer}
            muted
            volume={0}
            playsinline
            className="react-player"
            width="100%"
            height="100%"
          />
        </div>
      )}
      {props.data.extraImage && (
        <img
          className={`extra-image ${props.data.extraImageStyle}`}
          src={props.data.extraImage}
          alt=""
        />
      )}
      {!props.hideSectionLabels && (
        <div className="section-labels">
          <div
            className={`section-title ${props.highlight}`}
          >{`(${props.section})`}</div>
          <div className={`section-number`}>{`●  ${
            props.count < 10 ? "0" + props.count : props.count
          } / ${props.total < 10 ? "0" + props.total : props.total}`}</div>
        </div>
      )}
    </div>
  );
}

export default MetaAIMediaBlock2;
