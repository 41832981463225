import "./TabbedBlock.scss";
import { Fragment, useEffect, useRef, useState } from "react";
import parse from "html-react-parser";
import gsap from "gsap";

function TabbedBlock(props) {
  const tl = useRef();

  const tabs = useRef([]);
  const tabPanels = useRef([]);
  const [activeTab, setActiveTab] = useState(0);
  const tabsMobile = useRef([]);

  useEffect(() => {
    // tl.current = gsap.timeline({});
    // tl.current.fromTo(
    //   logo.current,
    //   { autoAlpha: 0, y: 20 },
    //   { autoAlpha: 1, duration: 2, y: 0 }
    // );
  }, []);

  useEffect(() => {
    tl.current = gsap.timeline({});
    tl.current.to(tabPanels.current, { y: "120%", duration: 0.1 }, 0);
    tl.current.to(
      tabPanels.current[activeTab],
      {
        y: 0,
        ease: "back.out",
        duration: 0.1,
      },
      0.1
    );

    tl.current.to(
      tabsMobile.current,
      { height: "17vw", duration: 0.3, ease: "back.out" },
      0
    );
    tl.current.to(
      tabsMobile.current[activeTab],
      {
        height: "auto",
        duration: 0.3,
        ease: "back.out",
      },
      0
    );
  }, [activeTab]);

  const tabClicked = (e) => {
    const index = parseInt(e.currentTarget.getAttribute("data-index")) || 0;
    setActiveTab(index);
  };

  return (
    <Fragment>
      <div id={props.id} className={`tabbed-block`}>
        <div className="tabs">
          {props.data.tabs.map((t, i) => {
            return (
              <div
                key={props.id + "tab" + i}
                className={`${props.section.toLowerCase() + "-tab-" + i}  tab ${
                  i === activeTab ? "active" : ""
                }`}
                ref={(el) => (tabs.current[i] = el)}
                onClick={tabClicked}
                data-index={i}
              >
                <h3
                  className={`tab-title ${
                    i === activeTab ? props.highlight : ""
                  }`}
                >
                  {t.title}
                </h3>
                <div
                  className={`tab-number ${
                    i === activeTab
                      ? props.highlightAlt +
                        " " +
                        props.highlight +
                        "Background"
                      : ""
                  }`}
                >
                  {t.number}
                </div>
              </div>
            );
          })}
        </div>

        <div className="tabPanels">
          {props.data.tabs.map((t, i) => {
            return (
              <div
                key={props.id + "tabPanel" + i}
                className={`tabPanel ${i === activeTab ? "active" : ""}`}
                ref={(el) => (tabPanels.current[i] = el)}
                onClick={tabClicked}
                data-index={i}
              >
                <h1 className={`text ${t.color}}`}>
                  {parse(
                    t.text.replace(
                      "<span>",
                      "<span class='" + props.highlight + "'>"
                    )
                  )}
                </h1>
                {t.image && <img src={t.image} alt="" />}
              </div>
            );
          })}
        </div>

        <div className="section-labels">
          <div
            className={`section-title ${props.highlight}`}
          >{`(${props.section})`}</div>
          <div className={`section-number`}>{`●  ${
            props.count < 10 ? "0" + props.count : props.count
          } / ${props.total < 10 ? "0" + props.total : props.total}`}</div>
        </div>
      </div>
      <div id={props.id} className={`tabbed-block-mobile`}>
        <div className="tabs">
          {props.data.tabs.map((t, i) => {
            return (
              <div
                key={props.id + "tab-mobile" + i}
                className={`${props.section.toLowerCase() + "-tab-" + i} tab ${
                  i === activeTab ? "active" : ""
                }`}
                ref={(el) => (tabsMobile.current[i] = el)}
                onClick={tabClicked}
                data-index={i}
              >
                <div className="tab-top-row">
                  <h3
                    className={`tab-title ${
                      i === activeTab ? props.highlight : ""
                    }`}
                  >
                    {t.title}
                  </h3>
                  <div
                    className={`tab-number ${
                      i === activeTab
                        ? props.highlightAlt +
                          " " +
                          props.highlight +
                          "Background"
                        : ""
                    }`}
                  >
                    {t.number}
                  </div>
                </div>
                <div
                  className={`tabPanel ${i === activeTab ? "active" : ""}`}
                  data-index={i}
                >
                  <h1 className={`text ${t.color}}`}>
                    {parse(
                      t.text.replace(
                        "<span>",
                        "<span class='" + props.highlight + "'>"
                      )
                    )}
                  </h1>
                  {t.image && <img src={t.image} alt="" />}
                </div>
              </div>
            );
          })}
        </div>
        {/* <div className="section-labels">
          <div
            className={`section-title ${props.highlight}`}
          >{`(${props.section})`}</div>
          <div
            className={`section-number`}
          >{`●  0${props.count} / 0${props.total}`}</div>
        </div> */}
      </div>
    </Fragment>
  );
}

export default TabbedBlock;
