import "./MediaTitleTextBlock.scss";
import { useEffect, useRef } from "react";
import gsap from "gsap";
import parse from "html-react-parser";
import { Player } from "@lottiefiles/react-lottie-player";

function MediaTitleTextBlock(props) {
  const tl = useRef();

  useEffect(() => {
    // tl.current = gsap.timeline({});
    // tl.current.fromTo(
    //   logo.current,
    //   { autoAlpha: 0, y: 20 },
    //   { autoAlpha: 1, duration: 2, y: 0 }
    // );
  }, []);

  return (
    <div
      id={props.id}
      className={`media-title-text-block ${props.background + "Background"} ${
        props.data.style
      }`}
    >
      <div className="titles">
        {props.data.title.map((t, i) => {
          return (
            <h1
              key={props.id + "titletblock" + props.count + "_" + i}
              className={`title ${t.color} ${t.align ? t.align : ""}`}
            >
              {parse(t.text)}
            </h1>
          );
        })}
      </div>
      <div
        className={`media-mobile media ${
          props.data.media.mobileSpecial ? props.data.media.mobileSpecial : ""
        }`}
      >
        {props.data.media.type === "image" && (
          <img
            className={`${props.data.media.style}`}
            src={props.data.media.file}
            alt=""
          />
        )}
        {props.data.media.type === "lottie" && (
          <div className={`lottie ${props.data.media.style}`}>
            <Player
              src={props.data.media.file}
              loop
              autoplay
              speed={Math.random() * 0.5 + 0.5}
            />
          </div>
        )}
      </div>
      <div
        className={`small-text ${props.data.smallTextStyle} ${
          props.data.media &&
          props.data.media.style &&
          props.data.media.style.includes("cup")
            ? "margin-bottom"
            : ""
        }`}
      >
        {props.data.smallText}
      </div>
      <div
        className={`small-text2 ${props.data.smallTextStyle} ${
          props.data.media.style.includes("invite-friends")
            ? "margin-bottom"
            : ""
        }`}
      >
        {props.data.smallText2}
      </div>
      <div
        className={`media ${
          props.data.media.mobileSpecial ? props.data.media.mobileSpecial : ""
        }`}
      >
        {props.data.media.type === "image" && (
          <img
            className={`${props.data.media.style}`}
            src={props.data.media.file}
            alt=""
          />
        )}
        {props.data.media.type === "lottie" && (
          <div className={`lottie ${props.data.media.style}`}>
            <Player
              src={props.data.media.file}
              loop
              autoplay
              speed={Math.random() * 0.5 + 0.5}
            />
          </div>
        )}
      </div>
      {props.data.effects &&
        [...Array(10)].map((s, i) => {
          return (
            <div
              key={"stareffect" + i}
              style={{
                left: Math.random() * 80 - 40 + "%",
                top: Math.random() * 80 - 40 + "%",
                width: Math.random() * 50 + 50 + "%",
              }}
              className={`effect lottie ${props.data.effects.mediaStyle}`}
            >
              <Player
                src={props.data.effects.file}
                loop
                autoplay
                speed={Math.random() * 0.5 + 0.5}
              />
            </div>
          );
        })}
      <div className="section-labels">
        <div
          className={`section-title ${props.color}`}
        >{`(${props.section})`}</div>
        <div className={`section-number`}>{`●  ${
          props.count < 10 ? "0" + props.count : props.count
        } / ${props.total < 10 ? "0" + props.total : props.total}`}</div>
      </div>
    </div>
  );
}

export default MediaTitleTextBlock;
