import { create } from "zustand";

const useStore = create((set) => ({
  progress: 0,
  setProgress: (number) => set(() => ({ progress: number })),
  activeSection: null,
  setActiveSection: (number) => set(() => ({ activeSection: number })),
  navTo: null,
  setNavTo: (number) => set(() => ({ navTo: number })),
  activeCaseStudy: null,
  setActiveCaseStudy: (number) => set(() => ({ activeCaseStudy: number })),
  mobileProgress: 0,
  setMobileProgress: (number) => set(() => ({ mobileProgress: number })),
  mobileContentProgress: 0,
  setMobileContentProgress: (number) =>
    set(() => ({ mobileContentProgress: number })),
  mobileNavActive: null,
  setMobileNavActive: (value) => set(() => ({ mobileNavActive: value })),
  loadProgress: 0,
  setLoadProgress: (number) => set(() => ({ loadProgress: number })),
  loaded: false,
  setLoaded: (bool) => set(() => ({ loaded: bool })),
  fontsLoaded: false,
  setFontsLoaded: (bool) => set(() => ({ fontsLoaded: bool })),
  contentLoaded: false,
  setContentLoaded: (bool) => set(() => ({ contentLoaded: bool })),
}));

export default useStore;
