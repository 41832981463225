import "./MediaBlockAnimatedVolta.scss";
import { useEffect, useLayoutEffect, useRef } from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import parse from "html-react-parser";
import gsap from "gsap";
import useStore from "../../store";

function MediaBlockAnimatedVolta(props) {
  const tl = useRef();
  const el = useRef();
  const animatedBackground = useRef();
  const fillBlock = useRef();
  const counter = useRef();
  const counterSmall = useRef();
  const activeCaseStudy = useStore((state) => state.activeCaseStudy);

  useEffect(() => {
    //console.log(props);
    if (tl.current) tl.current.pause();
    tl.current = gsap.timeline({});
    if (activeCaseStudy === props.caseIndex) {
      tl.current.fromTo(
        [animatedBackground.current],
        { height: "10%" },
        {
          height: "81%",
          yoyo: true,
          repeatDelay: 1,
          ease: "sine.inOut",
          duration: 5,
          repeat: -1,
          onUpdate: function () {
            //console.log(this.progress());
            counter.current.innerHTML =
              parseInt(this.progress() * 100) + "<span class='small'>%</span>";
          },
          //
          //console.log("progress:", self.progress.toFixed(3));
          //setProgress(parseFloat(self.progress.toFixed(3)));
        }
      );
      tl.current.fromTo(
        [fillBlock.current],
        { height: "4vw" },
        {
          height: "37.6vw",
          yoyo: true,
          repeatDelay: 1,
          ease: "sine.inOut",
          duration: 5,
          repeat: -1,
          onUpdate: function () {
            counterSmall.current.innerHTML =
              parseInt(this.progress() * 100) + "<span class='small'>%</span>";
          },
          //
          //console.log("progress:", self.progress.toFixed(3));
          //setProgress(parseFloat(self.progress.toFixed(3)));
        },
        0
      );
    }
    return () => {
      if (tl.current) tl.current.pause();
      tl.current = null;
    };
  }, [activeCaseStudy]);

  return (
    <div
      id={props.id}
      ref={el}
      className={`media-block-animated-volta ${props.data.style}`}
    >
      <div
        ref={animatedBackground}
        className={`animated-background ${
          props.background ? props.background : ""
        }Background`}
      >
        <h1 ref={counter} className={`counter ${props.color}`}></h1>
      </div>

      {props.data.text && (
        <h1 className={`title ${props.color}`}>{props.data.text}</h1>
      )}

      {props.data.mediaType === "image" && (
        <img
          className={`media image ${props.data.style} ${
            props.data.mediaMobile ? "mobile-hide" : ""
          }`}
          src={props.data.media}
          alt=""
        />
      )}
      {props.data.mediaType === "image" && props.data.mediaMobile && (
        <img
          className={`media-mobile image ${props.data.style}`}
          src={props.data.mediaMobile}
          alt=""
        />
      )}
      <div
        ref={fillBlock}
        className={`animated-fill-block ${
          props.background ? props.background : ""
        }Background`}
      >
        <h1 ref={counterSmall} className={`counter-small ${props.color}`}></h1>
      </div>
      {props.data.mediaType === "animation" && (
        <div className={`media lottie ${props.data.style}`}>
          <Player
            // animationData={animationData}
            src={props.data.media}
            loop
            autoplay

            // height={400}
            // width={400}
          />
        </div>
      )}
      {!props.hideSectionLabels && (
        <div className="section-labels">
          <div
            className={`section-title ${props.highlight}`}
          >{`(${props.section})`}</div>
          <div className={`section-number`}>{`●  ${
            props.count < 10 ? "0" + props.count : props.count
          } / ${props.total < 10 ? "0" + props.total : props.total}`}</div>
        </div>
      )}
    </div>
  );
}

export default MediaBlockAnimatedVolta;
