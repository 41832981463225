import "./CaseStudyVoltaCharging.scss";
import { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import MediaBlock from "../MediaBlock/MediaBlock";
import BigDivider from "../BigDivider/BigDivider";
import useStore from "../../store";
import ImageRow from "../ImageRow/ImageRow";
import RoundedDivider from "../RoundedDivider/RoundedDivider";
import Quotes from "../Quotes/Quotes";
import ColorDivider from "../ColorDivider/ColorDivider";
import BigDividerVolta from "../BigDividerVolta/BigDividerVolta";
import MediaBlockAnimatedVolta from "../MediaBlockAnimatedVolta/MediaBlockAnimatedVolta";
import VoltaTitleBlock from "../VoltaTitleBlock/VoltaTitleBlock";
import VoltaMediaGrid from "../VoltaMediaGrid/VoltaMediaGrid";
import BigDividerVoltaAdvanced from "../BigDividerVoltaAdvanced/BigDividerVoltaAdvanced";
import BigDividerVoltaSplit from "../BigDividerVoltaSplit/BigDividerVoltaSplit";
import VoltaBarGraph from "../VoltaBarGraph/VoltaBarGraph";

function CaseStudyVoltaCharging(props) {
  const activeCaseStudy = useStore((state) => state.activeCaseStudy);

  const tl = useRef();
  const el = useRef();

  const sectionStart = useRef();
  const sectionEnd = useRef();

  const [isActive, setIsActive] = useState(false);

  useEffect(() => {}, []);

  useEffect(() => {
    //console.log("active Case Study", activeCaseStudy);
    if (tl.current) tl.current.pause();
    // console.log("activeCaseStudy", activeCaseStudy, props.index);
    tl.current = gsap.timeline({
      onComplete: () => {
        ScrollTrigger.refresh();
      },
    });
    if (activeCaseStudy === null) {
      tl.current.call(() => {
        document.body.classList.remove("greyBackground");
      });
    }

    if (activeCaseStudy === props.index) {
      tl.current.call(() => {
        document.body.classList.add("greyBackground");
      });
      tl.current.to([el.current], {
        height: "auto",
        overflow: "unset",
        ease: "circ.in",
        duration: 0,
      });
      tl.current.to(props.el.current, {
        duration: 0.0,
        scrollTo: el.current.offsetTop - (window.innerHeight / 100) * 36,
        ease: "Power1.easeInOut",
      });
    } else {
      //tl.current.set([el.current], { height: "0" });
      if (
        el.current.offsetHeight > 0 &&
        props.el.current.scrollTop > el.current.offsetTop
      ) {
        tl.current.set(
          props.el.current,
          {
            scrollTo: props.el.current.scrollTop - el.current.offsetHeight,
          },
          0
        );
        tl.current.set(
          el.current,
          {
            height: 0,
            overflow: "hidden",
          },
          0
        );
      } else {
        tl.current.to(
          [el.current],
          {
            height: 0,
            ease: "circ.out",
            duration: 0.5,
            overflow: "hidden",
            // delay: 0.5,
          },
          0
        );
      }
    }
  }, [activeCaseStudy]);

  // useEffect(() => {
  //   console.log(isActive, props.index, activeCaseStudy);
  //   if (isActive && props.index === activeCaseStudy) {
  //     document.body.classList.add("greyBackground");
  //   } else {
  //     document.body.classList.remove("greyBackground");
  //   }
  // }, [isActive]);

  // useEffect(() => {
  //   ScrollTrigger.create({
  //     scroller: props.el.current,
  //     // markers: true,
  //     trigger: sectionStart.current,
  //     start: "top 22%",
  //     endTrigger: sectionEnd.current,
  //     end: "bottom top",
  //     invalidateOnRefresh: true,
  //     onToggle: (self) => {
  //       // console.log("toggled, isActive:", self.isActive);
  //       setIsActive(self.isActive);
  //     },
  //     onUpdate: (self) => {
  //       // console.log("progress:", self.progress.toFixed(3));
  //       //setProgress(parseFloat(self.progress.toFixed(3)));
  //     },
  //   });
  // }, []);

  return (
    <div
      id={"case-study-" + props.data.id}
      ref={el}
      className={`case-study-volta-charging`}
    >
      <div ref={sectionStart} className="section-start"></div>
      <MediaBlock
        data={props.data.blocks[0]}
        section={props.data.title}
        count={1}
        color={"white"}
        background={""}
        style={"full-bleed"}
        hideSectionLabels={true}
        total={props.data.blocks.length + 1}
      />
      <MediaBlock
        data={props.data.blocks[1]}
        section={props.data.title}
        count={2}
        color={"white"}
        background={""}
        style={"full-bleed"}
        hideSectionLabels={true}
        total={props.data.blocks.length + 1}
      />
      <BigDivider
        data={props.data.blocks[2]}
        background={"voltaLightBlue"}
        color={"black"}
        font={"KlarheitKurrent"}
      />
      <ImageRow data={props.data.blocks[3]} />
      <BigDivider
        data={props.data.blocks[4]}
        background={"voltaLightBlue"}
        color={"black"}
        font={"KlarheitKurrent"}
      />
      <ColorDivider data={props.data.blocks[5]} />
      <BigDividerVolta
        data={props.data.blocks[6]}
        background={"black"}
        color={"voltaLightGrey"}
      />
      <MediaBlockAnimatedVolta
        data={props.data.blocks[7]}
        section={props.data.title}
        count={1}
        color={"voltaBlack"}
        background={"voltaYellow"}
        style={"full-bleed"}
        hideSectionLabels={false}
        total={props.data.blocks.length + 1}
        el={props.el}
        caseIndex={props.index}
      />
      <BigDividerVolta
        data={props.data.blocks[8]}
        background={"voltaYellow"}
        color={"voltaBlack"}
      />
      <ColorDivider data={props.data.blocks[9]} />
      <VoltaTitleBlock
        data={props.data.blocks[10]}
        section={props.data.title}
        count={1}
        background={"voltaYellow"}
        color={"voltaBlack"}
        total={2}
      />
      <RoundedDivider
        data={props.data.blocks[11]}
        background={"white"}
        color={"black"}
        // style={"radius-16"}
      />
      <VoltaMediaGrid data={props.data.blocks[12]} />
      <MediaBlock
        data={props.data.blocks[13]}
        section={props.data.title}
        count={1}
        color={"white"}
        background={"voltaGreyBlueB"}
        style={"full-bleed"}
        hideSectionLabels={true}
        total={props.data.blocks.length + 1}
      />
      <VoltaBarGraph data={props.data.blocks[14]} section={props.data.title} />
      <RoundedDivider
        data={props.data.blocks[15]}
        background={"white"}
        color={"black"}
      />
      <VoltaMediaGrid data={props.data.blocks[16]} />
      <ColorDivider data={props.data.blocks[17]} />
      <MediaBlock
        data={props.data.blocks[18]}
        section={props.data.title}
        count={1}
        color={"white"}
        background={""}
        style={"full-bleed"}
        hideSectionLabels={true}
        total={props.data.blocks.length + 1}
      />
      <VoltaTitleBlock
        data={props.data.blocks[19]}
        section={props.data.title}
        count={1}
        background={"voltaOrange"}
        color={"voltaBlack"}
        total={2}
      />
      <BigDividerVoltaAdvanced
        data={props.data.blocks[20]}
        background={"voltaGrey2"}
        color={"black"}
      />
      <BigDividerVoltaSplit
        data={props.data.blocks[21]}
        background={"voltaOrange"}
        background2={"voltaGrey3"}
        color={"voltaBlack"}
        font={"KlarheitKurrent"}
      />
      <ImageRow data={props.data.blocks[22]} />
      <BigDividerVoltaSplit
        data={props.data.blocks[23]}
        background={"voltaGrey3"}
        background2={"voltaOrange"}
        background3={"voltaGrey3"}
        color={"voltaBlack"}
        font={"KlarheitKurrent"}
      />
      <MediaBlock
        data={props.data.blocks[24]}
        section={props.data.title}
        count={1}
        color={"white"}
        background={""}
        style={"full-bleed"}
        hideSectionLabels={true}
        total={props.data.blocks.length + 1}
      />
      <BigDividerVoltaAdvanced
        data={props.data.blocks[25]}
        background={"voltaGrey2"}
        color={"black"}
      />
      <ColorDivider data={props.data.blocks[26]} />
      <BigDivider
        data={props.data.blocks[27]}
        background={"voltaLightGreen"}
        color={"voltaGrey"}
        font={"KlarheitKurrent"}
      />
      {/* <MediaBlock
        data={props.data.blocks[28]}
        section={props.data.title}
        count={1}
        color={"white"}
        background={""}
        style={"full-bleed"}
        hideSectionLabels={true}
        total={props.data.blocks.length + 1}
      /> */}
      <VoltaMediaGrid data={props.data.blocks[28]} />
      <BigDivider
        data={props.data.blocks[29]}
        background={"voltaLightGreen"}
        color={"voltaGrey"}
        font={"KlarheitKurrent"}
      />
      <ImageRow data={props.data.blocks[30]} />
      <VoltaTitleBlock
        data={props.data.blocks[31]}
        section={props.data.title}
        count={1}
        background={"voltaLightGreen"}
        color={"voltaBlack"}
        total={2}
      />
      <MediaBlock
        data={props.data.blocks[32]}
        section={props.data.title}
        count={1}
        color={"white"}
        background={""}
        style={"full-bleed"}
        hideSectionLabels={true}
        total={props.data.blocks.length + 1}
      />
      <Quotes
        data={props.data.blocks[33]}
        background={"beige"}
        color={"black"}
        highlight={"voltaLightBlue"}
        count={9}
        total={props.data.blocks.length + 1}
      />
      <div ref={sectionEnd} className="section-end"></div>
    </div>
  );
}

export default CaseStudyVoltaCharging;
