import "./VoltaBarGraph.scss";
import { Fragment, useEffect, useRef } from "react";
import gsap from "gsap";
import parse from "html-react-parser";

function VoltaBarGraph(props) {
  const tl = useRef();
  const bars = useRef([]);
  const percents = useRef([]);

  useEffect(() => {
    if (tl.current) tl.current.pause();
    tl.current = gsap.timeline({
      repeatRefresh: true,
      repeat: -1,
      repeatDelay: 1,
    });
    tl.current.to(
      bars.current[0],
      {
        height: "random(25,100)%",

        ease: "sine.inOut",
        duration: 3,
        onUpdate: function () {
          if (!bars.current[0]) return;
          percents.current[0].innerHTML =
            parseInt(bars.current[0].style.height) +
            "<span class='small'>%</span>";
        },
      },
      0
    );
    tl.current.to(
      bars.current[1],
      {
        height: "random(25,100)%",

        ease: "sine.inOut",
        duration: 3,
        onUpdate: function () {
          if (!bars.current[1]) return;
          percents.current[1].innerHTML =
            parseInt(bars.current[1].style.height) +
            "<span class='small'>%</span>";
        },
      },
      0
    );
    tl.current.to(
      bars.current[2],
      {
        height: "random(25,100)%",

        ease: "sine.inOut",
        duration: 3,
        onUpdate: function () {
          if (!bars.current[2]) return;
          percents.current[2].innerHTML =
            parseInt(bars.current[2].style.height) +
            "<span class='small'>%</span>";
        },
      },
      0
    );
    tl.current.to(
      bars.current[3],
      {
        height: "random(25,100)%",

        ease: "sine.inOut",
        duration: 3,
        onUpdate: function () {
          if (!bars.current[3]) return;
          percents.current[3].innerHTML =
            parseInt(bars.current[3].style.height) +
            "<span class='small'>%</span>";
        },
      },
      0
    );
  });

  return (
    <div id={props.id} className={`volta-bar-graph`}>
      <div className={`volta-bars`}>
        {props.data.bars.map((b, i) => {
          return (
            <div
              key={"volta-bar" + i}
              className={`volta-bar`}
              style={{ width: b.width + "%" }}
            >
              <div
                ref={(el) => (bars.current[i] = el)}
                className={`volta-bar-inner  ${b.background}Background ${props.color} `}
              >
                <img
                  className="arrow"
                  src={
                    b.color.includes("Black")
                      ? "./assets/volta/bar-graph-arrow-black.svg"
                      : "./assets/volta/bar-graph-arrow-white.svg"
                  }
                  alt=""
                />
                <h1 className={`title ${b.color}`}>{b.title}</h1>
                <h2
                  ref={(el) => (percents.current[i] = el)}
                  className={`percent ${b.color}`}
                >
                  50%
                </h2>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default VoltaBarGraph;
