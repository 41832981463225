import "./BigDivider.scss";
import { useEffect, useRef } from "react";
import gsap from "gsap";

function BigDivider(props) {
  const tl = useRef();
  const arrowsLeft = useRef();
  const arrowsRight = useRef();

  useEffect(() => {
    // tl.current = gsap.timeline({});
    // tl.current.fromTo(
    //   [arrowsLeft.current, arrowsRight.current],
    //   { y: -6, autoAlpha: 0 },
    //   {
    //     y: 6,
    //     autoAlpha: 1,
    //     repeat: -1,
    //     duration: 1,
    //     ease: "sine.out",
    //   }
    // );
  }, []);

  return (
    <div
      id={props.id}
      className={`big-divider ${props.background}Background ${props.color} ${
        props.font ? props.font : ""
      }`}
    >
      <span
        className={`text left ${
          props.highlightColor ? props.highlightColor : props.color
        } ${props.hideSmallText ? "hide-small-text" : ""}`}
      >
        {props.data.left}
      </span>

      <h1 className={`title ${props.font ? props.font : ""}`}>
        {props.data.title}
      </h1>

      <span
        className={`text right ${
          props.highlightColor ? props.highlightColor : props.colorr
        } ${props.hideSmallText ? "hide-small-text" : ""}`}
      >
        {props.data.right}
      </span>
    </div>
  );
}

export default BigDivider;
