import "./MediaBlock7Eleven.scss";
import { useEffect, useRef } from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import parse from "html-react-parser";
import gsap from "gsap";

function MediaBlock7Eleven(props) {
  const tl = useRef();

  useEffect(() => {
    // tl.current = gsap.timeline({});
    // tl.current.fromTo(
    //   logo.current,
    //   { autoAlpha: 0, y: 20 },
    //   { autoAlpha: 1, duration: 2, y: 0 }
    // );
  }, []);

  return (
    <div
      id={props.id}
      className={`media-block-7-eleven ${
        props.background ? props.background : ""
      }Background ${props.data.style}`}
    >
      <div className="media-block-content-wrapper">
        {props.data.text && (
          <h1 className={`title ${props.color}`}>{props.data.text}</h1>
        )}
        {props.data.image && (
          <img
            className={`media image ${props.data.imageStyle}`}
            src={props.data.image}
            alt=""
          />
        )}
        {props.data.mediaType === "animation" && (
          <div className={`media lottie ${props.data.style}`}>
            <Player
              // animationData={animationData}
              src={props.data.media}
              loop
              autoplay

              // height={400}
              // width={400}
            />
          </div>
        )}
      </div>
      {!props.hideSectionLabels && (
        <div className="section-labels">
          <div
            className={`section-title ${props.highlight}`}
          >{`(${props.section})`}</div>
          <div className={`section-number`}>{`●  ${
            props.count < 10 ? "0" + props.count : props.count
          } / ${props.total < 10 ? "0" + props.total : props.total}`}</div>
        </div>
      )}
    </div>
  );
}

export default MediaBlock7Eleven;
