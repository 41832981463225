import "./MobileNavigation.scss";
import { BrowserRouter as Router, Link } from "react-router-dom";
import { data } from "../../data/data.js";
import useStore from "../../store";
import { useEffect, useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

function MobileNavigation() {
  const progress = useStore((state) => state.progress);
  const setMobileProgress = useStore((state) => state.setMobileProgress);
  const mobileNavActive = useStore((state) => state.mobileNavActive);
  const setMobileNavActive = useStore((state) => state.setMobileNavActive);
  const mobileContentProgress = useStore(
    (state) => state.mobileContentProgress
  );
  const activeSection = useStore((state) => state.activeSection);
  const activeCaseStudy = useStore((state) => state.activeCaseStudy);

  const setNavTo = useStore((state) => state.setNavTo);
  const navItems = useRef([]);
  const navItemsDot = useRef([]);
  const nav = useRef();
  const navEl = useRef();
  const tl = useRef();
  const tl3 = useRef();

  useEffect(() => {
    ScrollTrigger.create({
      scroller: navEl.current,
      horizontal: true,
      invalidateOnRefresh: true,
      onUpdate: (self) => {
        //console.log("mobile progress:", self.progress.toFixed(3));
        setMobileProgress(parseFloat(self.progress.toFixed(3)));
      },
    });
    gsap.config({ nullTargetWarn: false });
  }, []);

  useEffect(() => {
    if (mobileNavActive) return;
    //console.log(mobileContentProgress);
    navEl.current.scroll(
      (navEl.current.scrollWidth - navEl.current.offsetWidth) *
        mobileContentProgress,
      0
    );
  }, [mobileContentProgress, mobileNavActive]);

  // useEffect(() => {
  //   if (activeSection === null || activeSection === undefined) return;

  //   console.log("active section mobile", activeSection);
  //   // tl.current = gsap.timeline({});
  //   // tl.current.to(navItems.current, { height: 0, duration: 0.5 }, 0);
  //   // tl.current.to(navItemsDot.current, { autoAlpha: 0 }, 0);
  //   // tl.current.to(navItemsDot.current[activeSection], { autoAlpha: 1 }, 0);
  //   // tl.current.to(
  //   //   navItems.current[activeSection],
  //   //   {
  //   //     height: data.navigation[activeSection].maxHeight,
  //   //     duration: 0.5,
  //   //     overwrite: true,
  //   //   },
  //   //   0
  //   // );

  //   // tl.current.call(
  //   //   () => {
  //   //     gsap.to(nav.current, {
  //   //       scrollTop: navItems.current[activeSection].offsetTop - 24,
  //   //       duration: 0.3,
  //   //       ease: "sine.out",
  //   //     });
  //   //   },
  //   //   null,
  //   //   0.5
  //   // );
  // }, [activeSection]);

  useEffect(() => {
    console.log("active case study mobile", activeCaseStudy);
    //const c = document.querySelector(".content");
    if (tl3.current) tl3.current.pause();
    tl3.current = gsap.timeline();
    tl3.current.call(() => {
      data.navigation.forEach((s, i) => {
        if (i !== 3) {
          navItems.current[i].style.width = s.mobileHeight * 0.15 + "px";
        } else {
          if (activeCaseStudy === null) {
            navItems.current[i].style.width = s.mobileHeight * 0.15 + "px";
          } else {
            navItems.current[i].style.width =
              s.caseHeights[activeCaseStudy] * 0.15 + "px";
          }
        }
      });

      ScrollTrigger.refresh();
    });
    // tl3.current.call(
    //   () => {
    //     c.scroll({ left: 0, top: c.scrollTop - 30, behavior: "smooth" });
    //   },
    //   null,
    //   1.5
    // );
  }, [activeCaseStudy]);

  const navClicked = (e) => {
    setNavTo(null);
    const id = parseInt(e.currentTarget.getAttribute("data-index"));
    setTimeout(() => {
      setNavTo(id);
    }, 100);
  };

  const scrolling = (e) => {
    setMobileNavActive(true);
  };

  return (
    <div ref={nav} className="mobile-navigation">
      <nav ref={navEl} onTouchStart={scrolling}>
        <div className="nav-items">
          {/* // Iterate over nav elements to build this  */}
          {data.navigation.map((n, i) => {
            return (
              <button
                ref={(el) => (navItems.current[i] = el)}
                className={`nav-item nav-${n.slug} ${n.color} ${n.background}Background`}
                style={{
                  width: data.navigation[i].maxHeight * 0.65 + "px",
                }}
                key={"nav-item-" + i}
                data-index={i}
                onClick={navClicked}
                // to={"/" + n.slug}
              >
                <span className="nav-item-number">{i < 10 ? "0" + i : i}</span>
                {i > 0 && <span className="nav-item-text">{n.title}</span>}
                {/* <span
                ref={(el) => (navItemsDot.current[i] = el)}
                className={`nav-dot ${n.color}Background nav-dot-${i}`}
              ></span> */}
              </button>
            );
          })}
        </div>
      </nav>
      {/* <div className="mobile-nav-footer">
        <h4 className="contact">
          <a href={data.global.contactUrl}>{data.global.contactText}</a>
        </h4>
        <h4 className="copyright">{data.global.copyright}</h4>
      </div> */}
    </div>
  );
}

export default MobileNavigation;
