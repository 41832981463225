import "./CaseStudyPreviewCard.scss";
import { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useStore from "../../store";
import parse from "html-react-parser";

function CaseStudyPreviewCard(props) {
  // TODO:  Handle mini thin state when scrolling out of view
  // Perhaps just another simple component instead of coplicaitng this one

  const tl = useRef();
  const tl2 = useRef();
  const tl3 = useRef();
  const logo = useRef();
  const front = useRef();
  const thumbs = useRef([]);
  const card = useRef();

  const [isOpen, setIsOpen] = useState(false);
  const [isMini, setIsMini] = useState(false);

  const activeCaseStudy = useStore((state) => state.activeCaseStudy);
  const setActiveCaseStudy = useStore((state) => state.setActiveCaseStudy);

  const over = () => {
    if (isOpen) return;
    tl.current = gsap.timeline({});
    tl.current.to(thumbs.current, {
      stagger: {
        each: 0.02,
        from: "start",
        ease: "back.out",
      },
      duration: 0.3,
      ease: "back.out",
      transform: function (index) {
        return "rotateX(-" + (45 - index * 10) + "deg)";
      },
    });
  };

  const out = () => {
    if (tl.current) tl.current.pause();
    tl.current = gsap.timeline({});
    tl.current.to(thumbs.current, {
      stagger: {
        each: 0.02,
        from: "end",
        ease: "circ.out",
      },
      duration: 0.3,
      ease: "circ.out",
      transform: "rotateX(0deg)",
    });
  };

  const open = () => {
    setIsMini(false);
    // if (tl3.current) tl2.current.pause();
    // tl3.current = gsap.timeline({});
    card.current.classList.add("open");
    thumbs.current.forEach((t, i) => {
      t.classList.add("open");
    });

    if (!props.data.active) return;
    if (isOpen) {
      //console.log("close case study:", props.data.title, props.index);
      setActiveCaseStudy(null);
      document.body.classList.remove("greyBackground");
    } else {
      //console.log("open case study:", props.data.title, props.index);
      setActiveCaseStudy(props.index);
    }
  };

  useEffect(() => {
    //console.log("activeCaseStudy", activeCaseStudy);
    if (activeCaseStudy !== props.index) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  }, [activeCaseStudy]);

  useEffect(() => {
    // if (tl2.current) tl2.current.pause();
    // tl2.current = gsap.timeline({});
    if (isOpen) {
      // tl2.current.set(card.current, { height: "20.83vw" });
      // tl2.current.set(thumbs.current[0], { height: "20.83vw" });
      card.current.classList.add("open");
      thumbs.current.forEach((t, i) => {
        t.classList.add("open");
      });
    } else {
      // tl2.current.set(card.current, { height: "11.1vw" });
      // tl2.current.set(thumbs.current, { height: "11.1vw" });
      card.current.classList.remove("open");
      thumbs.current.forEach((t, i) => {
        t.classList.remove("open");
      });
    }
    out();
  }, [isOpen]);

  useEffect(() => {
    if (!props.el || !props.el.current || !props.elBottom) return;
    // add trigger if this elelemtn scrolls out of view and its currently active
    ScrollTrigger.create({
      scroller: props.el.current,
      //markers: true,
      trigger: card.current,
      start: "top 5%",
      endTrigger: props.elBottom,
      // end: "bottom 30%",
      invalidateOnRefresh: true,
      onToggle: (self) => {
        setIsMini(self.isActive);

        // console.log("toggled, isActive:", self.isActive, props.index);
        //if (self.isActive) setActiveSection(i);
      },
      // onUpdate: (self) => {
      //   //console.log("progress:", self.progress.toFixed(3), i);
      //   setProgress(parseFloat(self.progress.toFixed(3)));
      // },
    });
  }, [props.el, props.elBottom]);

  return (
    <div
      className={`case-study-preview-card ${
        props.data.active ? "clickable" : ""
      } ${!props.data.active ? "mobile-disable-clickable" : ""}`}
      onMouseEnter={props.data.disabled ? null : over}
      onMouseLeave={props.data.disabled ? null : out}
      onClick={props.data.active ? open : null}
      ref={card}
    >
      <div
        ref={(el) => (thumbs.current[0] = el)}
        className="case-study-preview-card-front"
      >
        <div className="title-row">
          <div className={"title-wrapper"}>
            <h1>{parse(props.data.title)}</h1>
            <h2
              className={`mobile-category ${
                !props.data.active && props.data.disabled ? "disabled" : ""
              }`}
            >
              {!props.data.active && props.data.disabled
                ? "Coming Soon"
                : props.data.category}
            </h2>
          </div>
          <h2
            className={`category ${
              !props.data.active && props.data.disabled ? "disabled" : ""
            }`}
          >
            {!props.data.active && props.data.disabled
              ? "Coming Soon"
              : props.data.category}
          </h2>
          <img
            className={`case-study-preview-card-icon ${props.data.logoStyle}`}
            src={props.data.logo}
            alt={props.data.title}
          />
        </div>
        <h3 className={`description ${props.data.color}`}>
          {parse(props.data.description || "")}
        </h3>
      </div>
      {props.data.thumbs.map((t, i) => {
        return (
          <img
            key={i + "-thumb-" + i}
            ref={(el) => (thumbs.current[i + 1] = el)}
            className={`case-study-preview-card-thumb`}
            src={t.image}
            alt=""
            style={{
              zIndex: 90 - i * 5,
            }}
          />
        );
      })}
    </div>
  );
}

export default CaseStudyPreviewCard;
