import "./Quotes.scss";
import { Fragment, useEffect, useRef } from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import parse from "html-react-parser";
import gsap from "gsap";

function Quotes(props) {
  const tl = useRef();
  const arrowsLeft = useRef();
  const arrowsRight = useRef();

  const defaultOptions = {};

  useEffect(() => {
    // tl.current = gsap.timeline({});
    // tl.current.fromTo(
    //   [arrowsLeft.current, arrowsRight.current],
    //   { y: -6, autoAlpha: 0 },
    //   {
    //     y: 6,
    //     autoAlpha: 1,
    //     repeat: -1,
    //     duration: 1,
    //     ease: "sine.out",
    //   }
    // );
  }, []);

  return (
    <div id={props.id} className={`quotes ${props.color}`}>
      {props.data.quotes.map((q, i) => {
        return (
          <Fragment key={"quote-card-frag-" + i}>
            <div
              key={"quote-card-" + i}
              className={`quote-card ${props.background}Background`}
            >
              <div className="row">
                {!q.imageType ||
                  (q.imageType === "lottie" && (
                    <Player src={q.image} loop autoplay className="media" />
                  ))}
                {q.imageType === "image" && (
                  <img className="media image" src={q.image} alt="" />
                )}
                <h2 className={`client ${props.highlight}`}>{q.client}</h2>
                <h2 className={`title ${props.highlight}`}>{parse(q.title)}</h2>
                <h3 className={`quote`}>{q.quote}</h3>
              </div>
              <div className="row last-row">
                <div className="tags">
                  {q.tags.map((t, i) => {
                    return (
                      <span key={"tag" + i} className="tag">
                        {t}
                      </span>
                    );
                  })}
                </div>
                <div className="attribute">
                  <div className="attribute-by">{q.by}</div>
                  <div className="attribute-name">{q.attribute}</div>
                  <div className="attribute-info">
                    <span className="attribute-role">{q.attributeRole}</span>
                    <span className="attribute-company">
                      {q.attributeCompany}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              key={"quote-card-mobile" + i}
              className={`quote-card-mobile ${props.background}Background`}
            >
              <div className="row">
                {/* <img src={q.image} alt={q.client + "-" + q.title} /> */}
                {!q.imageType ||
                  (q.imageType === "lottie" && (
                    <Player
                      // animationData={animationData}
                      src={q.image}
                      loop
                      autoplay
                      className="media"
                      // height={400}
                      // width={400}
                    />
                  ))}
                {q.imageType === "image" && (
                  <img className="media image" src={q.image} alt="" />
                )}
              </div>
              <div className="row">
                <h2 className={`client ${props.highlight}`}>{q.client}</h2>
                <h2 className={`title ${props.highlight}`}>{parse(q.title)}</h2>
              </div>
              <div className="row">
                <h3 className={`quote`}>{q.quote}</h3>
              </div>
              <div className="attribute row">
                <div className="attribute-by">{q.by}</div>
                <div className="attribute-name-column">
                  <div className="attribute-name">{q.attribute}</div>
                  <div className="attribute-info">
                    <span className="attribute-role">{q.attributeRole}</span>
                    <span className="attribute-company">
                      {q.attributeCompany}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row last-row">
                <div className="tags">
                  {q.tags.map((t, i) => {
                    return (
                      <span key={"tag-mobile" + i} className="tag">
                        {t}
                      </span>
                    );
                  })}
                </div>
              </div>
            </div>
          </Fragment>
        );
      })}
    </div>
  );
}

export default Quotes;
