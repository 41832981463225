import "./Robot7Eleven.scss";
import { useEffect, useRef } from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import gsap from "gsap";

function Robot7Eleven(props) {
  const tl = useRef();
  const el = useRef();

  useEffect(() => {
    tl.current = gsap.timeline({ repeatRefresh: true, repeat: -1 });
    tl.current.to(el.current, {
      x: "random(0," + (window.innerWidth / 100) * 60 + ")",
      y:
        "random(-" +
        (window.innerWidth / 100) * 40 +
        "," +
        (window.innerWidth / 100) * 40 +
        ")",
      scale: "random(.9, 2.0)",
      duration: 6.0,
      ease: "sine.inOut",
      // invalidate: true,
    });
  }, []);

  return (
    <div className="robot-7-eleven">
      <div ref={el} className="robot">
        <Player
          src={props.data.media}
          loop
          autoplay
          className={`media lottie ${props.data.style}`}
        />
      </div>
    </div>
  );
}

export default Robot7Eleven;
