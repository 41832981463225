import "./Navigation.scss";
import { BrowserRouter as Router, Link } from "react-router-dom";
import { data } from "../../data/data.js";
import useStore from "../../store";
import { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { Player } from "@lottiefiles/react-lottie-player";

function Navigation() {
  const progress = useStore((state) => state.progress);
  const activeSection = useStore((state) => state.activeSection);
  const setNavTo = useStore((state) => state.setNavTo);
  const setActiveCaseStudy = useStore((state) => state.setActiveCaseStudy);
  const navItems = useRef([]);
  const navItemsNumber = useRef([]);
  const navItemsText = useRef([]);
  const navItemsDot = useRef([]);
  const nav = useRef();
  const tl = useRef();
  const navLottie = useRef();
  const [logoFile, setLogoFile] = useState();

  useEffect(() => {
    setLogoFile(
      data.landing.logos[Math.floor(Math.random() * data.landing.logos.length)]
        .file
    );
    gsap.config({ nullTargetWarn: false });
  }, []);

  useEffect(() => {
    gsap.set(navItemsDot.current[activeSection], {
      top: "calc((100% - 32px) * " + progress + " + 16px)",
    });
  }, [progress]);

  useEffect(() => {
    if (activeSection === null || activeSection === undefined) return;

    tl.current = gsap.timeline({});

    tl.current.set(navLottie.current, {
      autoAlpha: activeSection === 0 ? 0 : 1,
      duration: 0.1,
    });
    tl.current.set(navItemsText.current[0], {
      autoAlpha: activeSection === 0 ? 1 : 0,
      duration: 0.2,
    });
    tl.current.set(navItemsNumber.current[0], {
      autoAlpha: activeSection === 0 ? 1 : 0,
      duration: 0.2,
    });

    // if (activeSection === 0) {
    //   tl.current.to(navLottie.current, { autoAlpha: 0, duration: 0.1 });
    //   tl.current.to(navItemsText.current[0], { autoAlpha: 1, duration: 0.2 });
    //   tl.current.to(navItemsNumber.current[0], { autoAlpha: 1, duration: 0.2 });
    // } else {
    //   tl.current.to(
    //     navItemsText.current[0],
    //     { autoAlpha: 0, duration: 0.2 },
    //     0
    //   );
    //   tl.current.to(
    //     navItemsNumber.current[0],
    //     { autoAlpha: 0, duration: 0.2 },
    //     0
    //   );
    //   tl.current.to(navLottie.current, { autoAlpha: 1, duration: 0.1 });
    // }

    tl.current.to(navItems.current, { height: 0, duration: 0.5 }, 0);
    tl.current.to(navItemsDot.current, { autoAlpha: 0 }, 0);
    tl.current.to(navItemsDot.current[activeSection], { autoAlpha: 1 }, 0);
    tl.current.to(
      navItems.current[activeSection],
      {
        height: data.navigation[activeSection].maxHeight,
        duration: 0.5,
        overwrite: true,
      },
      0
    );

    tl.current.call(
      () => {
        if (activeSection > 4 || activeSection === 0) {
          gsap.to(nav.current, {
            scrollTop: navItems.current[activeSection].offsetTop - 24,
            duration: 0.3,
            ease: "sine.out",
          });
        }
      },
      null,
      0.5
    );
    navItems.current.forEach((n) => {
      n.classList.remove("active");
    });

    if (activeSection !== 3) {
      //console.log("scrolled out of section");
      setActiveCaseStudy(null);
      document.body.classList.remove("greyBackground");
    }
    navItems.current[activeSection].classList.add("active");
  }, [activeSection]);

  const navClicked = (e) => {
    setNavTo(null);
    const id = parseInt(e.currentTarget.getAttribute("data-index"));
    setTimeout(() => {
      setNavTo(id);
    }, 100);
    //console.log("nav clicked", id);
  };

  return (
    <div ref={nav} className="navigation">
      <nav>
        {/* // Iterate over nav elements to build this  */}
        {data.navigation.map((n, i) => {
          return (
            <button
              ref={(el) => (navItems.current[i] = el)}
              className={`nav-item nav-${n.slug} ${n.color} ${n.background}Background`}
              key={"nav-item-" + i}
              data-index={i}
              onClick={navClicked}
              // to={"/" + n.slug}
            >
              <span
                ref={(el) => (navItemsNumber.current[i] = el)}
                className="nav-item-number"
              >
                {i < 10 ? "0" + i : i}
              </span>
              <span
                ref={(el) => (navItemsText.current[i] = el)}
                className="nav-item-text"
              >
                {n.title}
              </span>
              {i > 0 && (
                <span
                  ref={(el) => (navItemsDot.current[i] = el)}
                  className={`nav-dot ${n.color}Background nav-dot-${i}`}
                ></span>
              )}
              {i === 0 && (
                <div ref={navLottie} className={`lottie`}>
                  <Player src={logoFile} loop autoplay />
                </div>
              )}
            </button>
          );
        })}
      </nav>
    </div>
  );
}

export default Navigation;
