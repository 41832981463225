import "./CareersListBlock.scss";
import { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import parse from "html-react-parser";

function CareersListBlock(props) {
  const tl = useRef();
  const positions = useRef([]);
  const [activePosition, setActivePosition] = useState(0);

  useEffect(() => {
    // tl.current = gsap.timeline({});
    // tl.current.fromTo(
    //   logo.current,
    //   { autoAlpha: 0, y: 20 },
    //   { autoAlpha: 1, duration: 2, y: 0 }
    // );
  }, []);

  const careerButtonClicked = (e) => {
    window.open(e.currentTarget.getAttribute("data-url"));
  };

  // const positionClicked = (e) => {
  //   // close all first
  //   //open this one
  //   // Ref ineeds to be array then can target all and the one called
  //   // tl.current = gsap.timeline({});
  //   // tl.current.fromTo(
  //   //   logo.current,
  //   //   { autoAlpha: 0, y: 20 },
  //   //   { autoAlpha: 1, duration: 2, y: 0 }
  //   // );
  // };

  return (
    <div id={props.id} className={`careers-list-block`}>
      {props.data.positions.map((p, i) => {
        const positionClicked = () => {
          // console.log("clicked");
          if (
            positions.current[i] &&
            positions.current[i].classList.contains("open")
          ) {
            positions.current[i].classList.remove("open");
          } else {
            positions.current.forEach((p, i) => {
              p.classList.remove("open");
            });
            positions.current[i].classList.add("open");
          }
        };
        return (
          <div
            key={props.id + "careerslistblockitem" + i}
            className={`career-list-item ${p.color ? p.color : ""} ${
              p.background ? p.background + "Background" : ""
            }`}
          >
            <div
              onClick={positionClicked}
              className="position"
              ref={(el) => (positions.current[i] = el)}
            >
              <div className="position-title">
                <h1 className={`title`}>{parse(p.title)}</h1>
                <h2 className={`category`}>{parse(p.category)}</h2>
              </div>
              <p className="text-1">{parse(p.text[0])}</p>
              <div className="text-2">
                <p className="p-2">{parse(p.text[1])}</p>
                <p className="p-2">{parse(p.text[2])}</p>
              </div>
              <div className="bottom-row">
                <div className="bottom-row-location">
                  <span>{p.locationTitle}</span>
                  <span className="black">{p.location}</span>
                </div>
                <div className="bottom-row-timezone">
                  <span className="black">
                    <a href={p.url} target="_blank">
                      {p.urlText}
                    </a>
                  </span>
                </div>
              </div>
            </div>
            {/* <button
              onClick={careerButtonClicked}
              className="career-button"
              data-id={i}
              data-url={p.url}
            >
              ↘
            </button> */}
          </div>
        );
      })}
      {!props.data.positions ||
        (!props.data.positions.length && (
          <div className={`career-list-item`}>
            <div className={`position no-positions`}>
              <div className="position-title">
                <h1 className={`title`}>{parse(props.data.noPositions)}</h1>
                <h2 className={`category`}></h2>
              </div>
            </div>
          </div>
        ))}
      {/* <div className={`career-list-item`}>
        <div
          className="position"
          onClick={positionClicked}
          data-url={props.data.applyGeneralUrl}
        >
          <div className="position-title">
            <h1 className={`title`}>{parse(props.data.applyGeneralTitle)}</h1>
            <h2 className={`category`}>
              {parse(props.data.applyGeneralCategory)}
            </h2>
          </div>
        </div>
        <button
           onClick={careerButtonClicked}
           className="career-button"
           data-url={props.data.applyGeneralUrl}
         >
           ↘
        </button>
      </div> */}
      {/* <div className="section-labels">
        <div
          className={`section-title ${props.color}`}
        >{`(${props.section})`}</div>
        <div
          className={`section-number`}
        >{`●  0${props.count} / 0${props.total}`}</div>
      </div> */}
    </div>
  );
}

export default CareersListBlock;
