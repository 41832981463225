import "./Footer.scss";
import { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import parse from "html-react-parser";
import icon from "../../assets/images/footer-face-icon.svg";

function Footer(props) {
  const tl = useRef();
  const positions = useRef([]);
  const [activePosition, setActivePosition] = useState(0);

  useEffect(() => {
    // tl.current = gsap.timeline({});
    // tl.current.fromTo(
    //   logo.current,
    //   { autoAlpha: 0, y: 20 },
    //   { autoAlpha: 1, duration: 2, y: 0 }
    // );
  }, []);

  const subscribeButtonClicked = (e) => {
    window.open(e.currentTarget.getAttribute("data-url"));
  };

  return (
    <div id={props.id} className={`footer`}>
      <div className="titles-column">
        <img src={icon} alt="" />
        <div className="titles">
          <h4>{props.data.title}</h4>
          <h4>{props.data.subTitle}</h4>
          <h6>{props.data.year}</h6>
        </div>
      </div>
      <div className="social-links">
        <a href={props.data.contactUrl}>{props.data.contactText}</a>
        {props.data.social.map((s, i) => {
          return (
            <a target="_blank" key={"footer-" + i} href={s.url}>
              {s.title}
            </a>
          );
        })}
      </div>
      <div className="year">{props.data.year}</div>
    </div>
  );
}

export default Footer;
