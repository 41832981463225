import "./App.scss";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Navigate,
  useParams,
} from "react-router-dom";
import TagManager from "react-gtm-module";
import { useEffect } from "react";
import useStore from "./store";
import Navigation from "./components/Navigation/Navigation";
import Content from "./components/Content/Content";
import { Helmet } from "react-helmet";
import { data } from "./data/data.js";
import MobileNavigation from "./components/MobileNavigation/MobileNavigation";
import Transition from "./components/Transition/Transition";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

const tagManagerArgs = {
  gtmId: "GTM-NP9J488",
};

export const RemoveTrailingSlash = ({ ...rest }) => {
  const location = useLocation();

  // If the last character of the url is '/'
  if (location.pathname.match("/.*/$")) {
    return (
      <Navigate
        replace
        {...rest}
        to={{
          pathname: location.pathname.replace(/\/+$/, ""),
          search: location.search,
        }}
      />
    );
  } else return null;
};

function App() {
  const activeSection = useStore((state) => state.activeSection);
  const activeCaseStudy = useStore((state) => state.activeCaseStudy);

  useEffect(() => {
    // upate the addressurl
    if (activeSection === null || activeSection === undefined) return;

    if (activeCaseStudy !== null && activeSection === 3) {
      // hadnle an open case study
    } else {
      // handle default
      window.history.pushState(
        data.navigation[activeSection].title,
        data.navigation[activeSection].title,
        "/" + data.navigation[activeSection].slug
      );
      let dataKey =
        data.navigation[activeSection].slug === "unusual-index"
          ? "unusualIndex"
          : data.navigation[activeSection].slug;
      if (!dataKey) dataKey = "landing";
      document.title = data[dataKey].pageTitle;
      document.description = data[dataKey].pageDescription;
    }
  }, [activeSection]);

  useEffect(() => {
    if (activeCaseStudy === null) return;
    //console.log(activeCaseStudy, data.work.projects[parseInt(activeCaseStudy)]);
    window.history.pushState(
      data.work.projects[activeCaseStudy].title,
      data.work.projects[activeCaseStudy].title,
      "/work/" + data.work.projects[activeCaseStudy].slug
    );
  }, [activeCaseStudy]);

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(ScrollToPlugin);
  }, []);

  return (
    <Router>
      <Transition />
      <Navigation />
      <MobileNavigation />
      <Content />
      <RemoveTrailingSlash />
      <Routes>
        <Route path="/hello" element={<HelloHandler />} />
        <Route path="/approach" element={<ApproachHandler />} />
        <Route path="/work" element={<WorkHandler />} />
        <Route path="/talent" element={<TalentHandler />} />
        <Route path="/careers" element={<CareersHandler />} />
        <Route path="/contact" element={<ContactHandler />} />
        <Route path="/unusual-index" element={<UnusualIndexHandler />} />
        <Route path="/work/:slug" element={<WorkCaseStudyHandler />} />
        <Route path="/" element={<DefaultHandler />} />
      </Routes>
    </Router>
  );
}

const HelloHandler = () => {
  const setNavTo = useStore((state) => state.setNavTo);
  useEffect(() => {
    setTimeout(() => {
      setNavTo(1);
    }, 0);
  }, []);
  return (
    <Helmet>
      <title>{data.hello.pageTitle}</title>
      <meta name="description" content={data.hello.pageDescription} />
      <meta name="twitter:title" content={data.hello.pageTitle} />
      <meta name="twitter:description" content={data.hello.pageDescription} />
    </Helmet>
  );
};

const ApproachHandler = () => {
  const setNavTo = useStore((state) => state.setNavTo);
  useEffect(() => {
    setTimeout(() => {
      setNavTo(2);
    }, 500);
  }, []);
  return (
    <Helmet>
      <title>{data.approach.pageTitle}</title>
      <meta name="description" content={data.approach.pageDescription} />
      <meta name="twitter:title" content={data.approach.pageTitle} />
      <meta
        name="twitter:description"
        content={data.approach.pageDescription}
      />
    </Helmet>
  );
};

const WorkHandler = () => {
  const setNavTo = useStore((state) => state.setNavTo);
  useEffect(() => {
    setTimeout(() => {
      setTimeout(() => {
        setNavTo(3);
      }, 500);
    }, 0);
  }, []);
  return (
    <Helmet>
      <title>{data.work.pageTitle}</title>
      <meta name="description" content={data.work.pageDescription} />
      <meta name="twitter:title" content={data.work.pageTitle} />
      <meta name="twitter:description" content={data.work.pageDescription} />
    </Helmet>
  );
};

const WorkCaseStudyHandler = () => {
  const setNavTo = useStore((state) => state.setNavTo);
  const setActiveCaseStudy = useStore((state) => state.setActiveCaseStudy);

  const params = useParams();

  useEffect(() => {
    //console.log(params.slug);

    // check if slug matches one we have
    let c = null;
    let _i = null;
    data.work.projects.forEach((p, i) => {
      if (p.slug === params.slug) {
        c = p;
        _i = i;
      }
    });

    if (!c || !c.id) {
      setTimeout(() => {
        setTimeout(() => {
          setNavTo(3);
        }, 500);
      }, 0);
    } else {
      setTimeout(() => {
        setTimeout(() => {
          gsap.set(".content", {
            scrollTop:
              document.querySelector("#case-study-" + c.id).offsetTop -
              (window.innerWidth / 100) * 20.83 -
              30,
          });

          window.history.pushState(c.title, c.title, "/work/" + c.slug);
          document.title = c.pageTitle;
          document.description = c.pageDescription;
        }, 500);
        setActiveCaseStudy(_i);
      }, 0);
    }
  }, []);

  return (
    <Helmet>
      <title>{data.work.pageTitle}</title>
      <meta name="description" content={data.work.pageDescription} />
      <meta name="twitter:title" content={data.work.pageTitle} />
      <meta name="twitter:description" content={data.work.pageDescription} />
    </Helmet>
  );
};

const TalentHandler = () => {
  const setNavTo = useStore((state) => state.setNavTo);
  useEffect(() => {
    setTimeout(() => {
      setNavTo(4);
    }, 500);
  }, []);
  return (
    <Helmet>
      <title>{data.talent.pageTitle}</title>
      <meta name="description" content={data.talent.pageDescription} />
      <meta name="twitter:title" content={data.talent.pageTitle} />
      <meta name="twitter:description" content={data.talent.pageDescription} />
    </Helmet>
  );
};

const CareersHandler = () => {
  const setNavTo = useStore((state) => state.setNavTo);
  useEffect(() => {
    setTimeout(() => {
      setNavTo(5);
    }, 500);
  }, []);
  return (
    <Helmet>
      <title>{data.careers.pageTitle}</title>
      <meta name="description" content={data.careers.pageDescription} />
      <meta name="twitter:title" content={data.careers.pageTitle} />
      <meta name="twitter:description" content={data.careers.pageDescription} />
    </Helmet>
  );
};

const ContactHandler = () => {
  const setNavTo = useStore((state) => state.setNavTo);
  useEffect(() => {
    setTimeout(() => {
      setNavTo(6);
    }, 500);
  }, []);
  return (
    <Helmet>
      <title>{data.contact.pageTitle}</title>
      <meta name="description" content={data.contact.pageDescription} />
      <meta name="twitter:title" content={data.contact.pageTitle} />
      <meta name="twitter:description" content={data.contact.pageDescription} />
    </Helmet>
  );
};

const UnusualIndexHandler = () => {
  const setNavTo = useStore((state) => state.setNavTo);
  useEffect(() => {
    setTimeout(() => {
      setNavTo(7);
    }, 500);
  }, []);
  return (
    <Helmet>
      <title>{data.unusualIndex.pageTitle}</title>
      <meta name="description" content={data.unusualIndex.pageDescription} />
      <meta name="twitter:title" content={data.unusualIndex.pageTitle} />
      <meta
        name="twitter:description"
        content={data.unusualIndex.pageDescription}
      />
    </Helmet>
  );
};

const DefaultHandler = () => {
  const setNavTo = useStore((state) => state.setNavTo);
  useEffect(() => {
    setTimeout(() => {
      setNavTo(0);
    }, 0);
  }, []);
  return (
    <Helmet>
      <title>{data.landing.pageTitle}</title>
      <meta name="description" content={data.landing.pageDescription} />
      <meta name="twitter:title" content={data.landing.pageTitle} />
      <meta name="twitter:description" content={data.landing.pageDescription} />
    </Helmet>
  );
};

export default App;
