import "./VoltaTitleBlock.scss";
import { useEffect, useRef } from "react";
import gsap from "gsap";
import parse from "html-react-parser";

function VoltaTitleBlock(props) {
  const tl = useRef();

  useEffect(() => {
    // tl.current = gsap.timeline({});
    // tl.current.fromTo(
    //   logo.current,
    //   { autoAlpha: 0, y: 20 },
    //   { autoAlpha: 1, duration: 2, y: 0 }
    // );
  }, []);

  return (
    <div
      id={props.id}
      className={`volta-title-block ${
        props.background ? props.background + "Background" : ""
      } ${props.data.style ? props.data.style : ""}`}
    >
      <div className={`titles ${props.data.style ? props.data.style : ""}`}>
        {props.data.title.map((t, i) => {
          return (
            <h1
              key={props.id + "titletblock" + i}
              className={`title ${t.color} ${t.align ? t.align : ""} ${
                props.data.style ? props.data.style : ""
              }`}
            >
              {parse(t.text)}
            </h1>
          );
        })}
      </div>
      {props.data.smallText && (
        <div
          className={`small-text ${props.data.style ? props.data.style : ""}`}
        >
          {props.data.smallText}
        </div>
      )}
      {props.data.smallText2 && (
        <div
          className={`small-text-2 ${props.data.style ? props.data.style : ""}`}
        >
          {props.data.smallText2}
        </div>
      )}
      <div className="section-labels">
        <div
          className={`section-title ${props.color}`}
        >{`(${props.section})`}</div>
        <div className={`section-number`}>{`●  ${
          props.count < 10 ? "0" + props.count : props.count
        } / ${props.total < 10 ? "0" + props.total : props.total}`}</div>
      </div>
    </div>
  );
}

export default VoltaTitleBlock;
