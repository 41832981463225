import "./BigDivider7Eleven.scss";
import { useEffect, useRef } from "react";
import gsap from "gsap";

function BigDivider7Eleven(props) {
  const tl = useRef();
  const arrowsLeft = useRef();
  const arrowsRight = useRef();

  useEffect(() => {
    // tl.current = gsap.timeline({});
    // tl.current.fromTo(
    //   [arrowsLeft.current, arrowsRight.current],
    //   { y: -6, autoAlpha: 0 },
    //   {
    //     y: 6,
    //     autoAlpha: 1,
    //     repeat: -1,
    //     duration: 1,
    //     ease: "sine.out",
    //   }
    // );
  }, []);

  return (
    <div
      id={props.id}
      className={`big-divider-7-eleven ${props.background}Background ${props.color}`}
    >
      <span className={`text left ${props.color}`}>{props.data.left}</span>
      <h1 className="title">{props.data.title}</h1>
      <span className={`text right ${props.color}`}>{props.data.right}</span>
    </div>
  );
}

export default BigDivider7Eleven;
