import "./MissionBlock.scss";
import { useEffect, useRef } from "react";
import parse from "html-react-parser";
import gsap from "gsap";

function MissionBlock(props) {
  const tl = useRef();

  useEffect(() => {
    // tl.current = gsap.timeline({});
    // tl.current.fromTo(
    //   logo.current,
    //   { autoAlpha: 0, y: 20 },
    //   { autoAlpha: 1, duration: 2, y: 0 }
    // );
  }, []);

  return (
    <div id={props.id} className={`mission-block`}>
      <div className="rows">
        {props.data.text.map((t, i) => {
          return (
            <div key={props.id + "missionblock" + i} className="row">
              <div className={`cell ${!t.number ? "first-row" : ""}`}>
                <h2 className={`title ${t.color} ${t.align ? t.align : ""}`}>
                  {t.number && (
                    <span className="title-number black">{t.number}</span>
                  )}
                  {t.title}
                </h2>
              </div>
              <div className="cell">
                <h1
                  className={`text ${t.color} ${t.align ? t.align : ""} ${
                    i === props.data.text.length - 1 ? "no-border" : ""
                  }`}
                >
                  {parse(
                    t.text.replace(
                      "<span>",
                      "<span class='" + props.highlight + "'>"
                    )
                  )}
                </h1>
              </div>
            </div>
          );
        })}
      </div>
      <div className="section-labels">
        <div
          className={`section-title ${props.highlight}`}
        >{`(${props.section})`}</div>
        <div className={`section-number`}>{`●  ${
          props.count < 10 ? "0" + props.count : props.count
        } / ${props.total < 10 ? "0" + props.total : props.total}`}</div>
      </div>
    </div>
  );
}

export default MissionBlock;
