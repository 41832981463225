import "./ColorDivider.scss";
import { Fragment, useEffect, useRef } from "react";
import gsap from "gsap";
import parse from "html-react-parser";

function ColorDivider(props) {
  const tl = useRef();

  return (
    <div
      id={props.id}
      className={`color-divider ${props.background}Background ${props.color}`}
    >
      {props.data.colors.map((c, i) => {
        return (
          <div
            key={"color-divider-" + i}
            className="block"
            style={{ background: c }}
          ></div>
        );
      })}
    </div>
  );
}

export default ColorDivider;
