import "./CaseStudyMetaAI.scss";
import { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import MediaBlock from "../MediaBlock/MediaBlock";
import BigDivider from "../BigDivider/BigDivider";
import useStore from "../../store";
import ImageRow from "../ImageRow/ImageRow";
import RoundedDivider from "../RoundedDivider/RoundedDivider";
import Quotes from "../Quotes/Quotes";
import ColorDivider from "../ColorDivider/ColorDivider";
import BigDividerVolta from "../BigDividerVolta/BigDividerVolta";
import MediaBlockAnimatedVolta from "../MediaBlockAnimatedVolta/MediaBlockAnimatedVolta";
import VoltaTitleBlock from "../VoltaTitleBlock/VoltaTitleBlock";
import VoltaMediaGrid from "../VoltaMediaGrid/VoltaMediaGrid";
import BigDividerVoltaAdvanced from "../BigDividerVoltaAdvanced/BigDividerVoltaAdvanced";
import BigDividerVoltaSplit from "../BigDividerVoltaSplit/BigDividerVoltaSplit";
import VoltaBarGraph from "../VoltaBarGraph/VoltaBarGraph";
import MetaTitleBlock from "../MetaTitleBlock/MetaTitleBlock";
import VideoPlayerBlock from "../VideoPlayerBlock/VideoPlayerBlock";
import ImageSequenceBlock from "../ImageSequenceBlock/ImageSequenceBlock";
import MetaAIMediaBlock from "../MetaAIMediaBlock/MetaAIMediaBlock";
import MetaAIMediaBlock2 from "../MetaAIMediaBlock2/MetaAIMediaBlock2";
import MediaGrid from "../MediaGrid/MediaGrid";

function CaseStudyMetaAI(props) {
  const activeCaseStudy = useStore((state) => state.activeCaseStudy);

  const tl = useRef();
  const el = useRef();

  const sectionStart = useRef();
  const sectionEnd = useRef();

  const [isActive, setIsActive] = useState(false);

  useEffect(() => {}, []);

  useEffect(() => {
    //console.log("active Case Study", activeCaseStudy);
    if (tl.current) tl.current.pause();
    // console.log("activeCaseStudy", activeCaseStudy, props.index);
    tl.current = gsap.timeline({
      onComplete: () => {
        ScrollTrigger.refresh();
      },
    });
    if (activeCaseStudy === null) {
      tl.current.call(() => {
        document.body.classList.remove("greyBackground");
      });
    }

    if (activeCaseStudy === props.index) {
      tl.current.call(() => {
        document.body.classList.add("greyBackground");
      });
      tl.current.to([el.current], {
        height: "auto",
        overflow: "unset",
        ease: "circ.in",
        duration: 0,
      });
      tl.current.to(props.el.current, {
        duration: 0.0,
        scrollTo: el.current.offsetTop - (window.innerHeight / 100) * 36,
        ease: "Power1.easeInOut",
      });
    } else {
      //tl.current.set([el.current], { height: "0" });
      if (
        el.current.offsetHeight > 0 &&
        props.el.current.scrollTop > el.current.offsetTop
      ) {
        tl.current.set(
          props.el.current,
          {
            scrollTo: props.el.current.scrollTop - el.current.offsetHeight,
          },
          0
        );
        tl.current.set(
          el.current,
          {
            height: 0,
            overflow: "hidden",
          },
          0
        );
      } else {
        tl.current.to(
          [el.current],
          {
            height: 0,
            ease: "circ.out",
            duration: 0.5,
            overflow: "hidden",
            // delay: 0.5,
          },
          0
        );
      }
    }
  }, [activeCaseStudy]);

  // useEffect(() => {
  //   console.log(isActive, props.index, activeCaseStudy);
  //   if (isActive && props.index === activeCaseStudy) {
  //     document.body.classList.add("greyBackground");
  //   } else {
  //     document.body.classList.remove("greyBackground");
  //   }
  // }, [isActive]);

  // useEffect(() => {
  //   ScrollTrigger.create({
  //     scroller: props.el.current,
  //     // markers: true,
  //     trigger: sectionStart.current,
  //     start: "top 22%",
  //     endTrigger: sectionEnd.current,
  //     end: "bottom top",
  //     invalidateOnRefresh: true,
  //     onToggle: (self) => {
  //       // console.log("toggled, isActive:", self.isActive);
  //       setIsActive(self.isActive);
  //     },
  //     onUpdate: (self) => {
  //       // console.log("progress:", self.progress.toFixed(3));
  //       //setProgress(parseFloat(self.progress.toFixed(3)));
  //     },
  //   });
  // }, []);

  return (
    <div
      id={"case-study-" + props.data.id}
      ref={el}
      className={`case-study-meta-ai`}
    >
      <div ref={sectionStart} className="section-start"></div>
      <MediaBlock
        data={props.data.blocks[0]}
        section={props.data.title}
        count={1}
        color={"white"}
        background={"metaBlue"}
        style={""}
        hideSectionLabels={false}
        total={props.data.blocks.length + 1}
      />
      <MetaTitleBlock
        data={props.data.blocks[1]}
        section={props.data.title}
        count={2}
        background={"metaBlue"}
        color={"black"}
        total={props.data.blocks.length + 1}
        specialIndex={1}
      />
      <MediaBlock
        data={props.data.blocks[2]}
        section={props.data.title}
        count={3}
        color={"white"}
        background={"metaBlue"}
        style={"full-bleed tight"}
        hideSectionLabels={false}
        hideOnMobile={true}
        total={props.data.blocks.length + 1}
      />
      <BigDivider
        data={props.data.blocks[3]}
        background={"metaPurple"}
        color={"black"}
        font={"OptimisticText"}
        total={props.data.blocks.length + 1}
      />
      <MediaBlock
        data={props.data.blocks[4]}
        section={props.data.title}
        count={5}
        color={"black"}
        background={""}
        style={"full-bleed tight"}
        hideSectionLabels={false}
        total={props.data.blocks.length + 1}
      />
      <ImageRow
        data={props.data.blocks[5]}
        count={6}
        total={props.data.blocks.length + 1}
      />
      <RoundedDivider
        data={props.data.blocks[6]}
        background={"white"}
        color={"black"}
        count={7}
        total={props.data.blocks.length + 1}
      />
      <MetaAIMediaBlock
        data={props.data.blocks[7]}
        section={props.data.title}
        count={8}
        color={"black"}
        background={"metaMediumBlue"}
        style={""}
        hideSectionLabels={false}
        total={props.data.blocks.length + 1}
      />
      <MediaBlock
        data={props.data.blocks[8]}
        section={props.data.title}
        count={9}
        color={"white"}
        background={"metaBlue"}
        style={""}
        hideSectionLabels={false}
        total={props.data.blocks.length + 1}
      />
      <MetaTitleBlock
        data={props.data.blocks[9]}
        section={props.data.title}
        count={10}
        background={"metaAIPink"}
        color={"metaAIPlum"}
        total={props.data.blocks.length + 1}
        specialIndex={2}
      />
      <MediaBlock
        data={props.data.blocks[10]}
        section={props.data.title}
        count={11}
        color={"white"}
        background={"metaAIPink"}
        style={"full-bleed tight"}
        hideSectionLabels={false}
        hideOnMobile={true}
        total={props.data.blocks.length + 1}
      />
      <BigDivider
        data={props.data.blocks[11]}
        background={"metaAIAnotherBlue"}
        color={"black"}
        count={12}
        font={"OptimisticText"}
        total={props.data.blocks.length + 1}
      />
      <MediaBlock
        data={props.data.blocks[12]}
        section={props.data.title}
        count={13}
        color={"black"}
        background={""}
        style={"full-bleed tight"}
        hideSectionLabels={false}
        total={props.data.blocks.length + 1}
      />
      <RoundedDivider
        data={props.data.blocks[13]}
        background={"white"}
        color={"black"}
        count={14}
        total={props.data.blocks.length + 1}
      />
      <ImageRow
        data={props.data.blocks[14]}
        count={15}
        total={props.data.blocks.length + 1}
      />
      <MediaBlock
        data={props.data.blocks[15]}
        section={props.data.title}
        count={16}
        color={"black"}
        background={""}
        style={"full-bleed tight"}
        hideSectionLabels={true}
        total={props.data.blocks.length + 1}
      />
      <MetaTitleBlock
        data={props.data.blocks[16]}
        section={props.data.title}
        count={17}
        background={"metaAIRed"}
        color={"black"}
        total={props.data.blocks.length + 1}
        specialIndex={3}
      />
      <MediaBlock
        data={props.data.blocks[17]}
        section={props.data.title}
        count={18}
        color={"black"}
        background={"metaAIRed"}
        style={"full-bleed tight"}
        hideSectionLabels={false}
        hideOnMobile={true}
        total={props.data.blocks.length + 1}
      />
      <BigDivider
        data={props.data.blocks[18]}
        background={"metaAIGold"}
        color={"black"}
        count={19}
        font={"OptimisticText"}
        total={props.data.blocks.length + 1}
      />
      <MetaAIMediaBlock2
        data={props.data.blocks[19]}
        section={props.data.title}
        count={20}
        color={"black"}
        background={"metaAILightPink"}
        style={""}
        hideSectionLabels={false}
        total={props.data.blocks.length + 1}
      />
      <ImageRow
        data={props.data.blocks[20]}
        count={21}
        total={props.data.blocks.length + 1}
      />
      <RoundedDivider
        data={props.data.blocks[21]}
        background={"white"}
        color={"black"}
        count={22}
        total={props.data.blocks.length + 1}
      />
      <MetaTitleBlock
        data={props.data.blocks[22]}
        section={props.data.title}
        count={23}
        background={"metaAIGreen"}
        color={"black"}
        total={props.data.blocks.length + 1}
        specialIndex={4}
      />
      <BigDivider
        data={props.data.blocks[23]}
        background={"metaAIBlue2"}
        color={"black"}
        font={"OptimisticText"}
        count={24}
        total={props.data.blocks.length + 1}
      />
      <MediaGrid
        data={props.data.blocks[24]}
        count={25}
        total={props.data.blocks.length + 1}
      />
      <RoundedDivider
        data={props.data.blocks[25]}
        background={"white"}
        color={"black"}
        count={26}
        total={props.data.blocks.length + 1}
      />
      <MediaBlock
        data={props.data.blocks[26]}
        section={props.data.title}
        count={27}
        color={"black"}
        background={""}
        style={"full-bleed tight"}
        hideSectionLabels={false}
        total={props.data.blocks.length + 1}
      />
      <MetaTitleBlock
        data={props.data.blocks[27]}
        section={props.data.title}
        count={28}
        background={"black"}
        color={"white"}
        specialIndex={5}
        total={props.data.blocks.length + 1}
      />
      <MediaBlock
        data={props.data.blocks[28]}
        section={props.data.title}
        count={29}
        color={"white"}
        background={"black"}
        style={"full-bleed tight"}
        hideSectionLabels={false}
        hideOnMobile={true}
        total={props.data.blocks.length + 1}
      />
      <BigDivider
        data={props.data.blocks[29]}
        background={"metaAIGrey"}
        color={"black"}
        font={"OptimisticText"}
        count={30}
        total={props.data.blocks.length + 1}
      />
      <MediaBlock
        data={props.data.blocks[30]}
        section={props.data.title}
        count={31}
        color={"black"}
        background={""}
        style={"full-bleed tight"}
        hideSectionLabels={false}
        total={props.data.blocks.length + 1}
      />
      <ImageRow data={props.data.blocks[31]} count={32} />
      <MediaBlock
        data={props.data.blocks[32]}
        section={props.data.title}
        count={33}
        color={"black"}
        background={""}
        style={"full-bleed tight"}
        hideSectionLabels={false}
        total={props.data.blocks.length + 1}
      />
      <ImageRow data={props.data.blocks[33]} count={34} />
      <RoundedDivider
        data={props.data.blocks[34]}
        background={"white"}
        color={"black"}
        count={35}
        total={props.data.blocks.length + 1}
      />
      <MediaBlock
        data={props.data.blocks[35]}
        section={props.data.title}
        count={36}
        color={"black"}
        background={"metaAIPeach"}
        style={"full-bleed tight"}
        hideSectionLabels={false}
        total={props.data.blocks.length + 1}
      />
      <ImageRow data={props.data.blocks[36]} count={37} />
      <MediaBlock
        data={props.data.blocks[37]}
        section={props.data.title}
        count={38}
        color={"black"}
        background={""}
        style={"full-bleed tight"}
        hideSectionLabels={false}
        total={props.data.blocks.length + 1}
      />
      <Quotes
        data={props.data.blocks[38]}
        background={"beige"}
        color={"black"}
        highlight={"metaBlue"}
        count={39}
        total={props.data.blocks.length + 1}
      />
      <div ref={sectionEnd} className="section-end"></div>
    </div>
  );
}

export default CaseStudyMetaAI;
