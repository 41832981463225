import "./TalentTitleBlock.scss";
import { Fragment, useEffect, useRef } from "react";
import gsap from "gsap";

function TalentTitleBlock(props) {
  const tl = useRef();

  useEffect(() => {
    // tl.current = gsap.timeline({});
    // tl.current.fromTo(
    //   logo.current,
    //   { autoAlpha: 0, y: 20 },
    //   { autoAlpha: 1, duration: 2, y: 0 }
    // );
  }, []);

  return (
    <Fragment>
      <div id={props.id} className={`talent-title-block`}>
        <div className="titles">
          {props.data.title.map((t, i) => {
            return (
              <div
                key={props.id + "talenttitletblock" + i}
                className={`title ${t.color} ${t.align ? t.align : ""}`}
              >
                <h1 className={`title ${t.color}`}>{t.preText}</h1>
                <h1>
                  <img src={t.image} alt="" />
                  {t.text}
                </h1>
              </div>
            );
          })}
        </div>
        <div className="small-text">{props.data.smallText}</div>
        <div className="section-labels">
          <div
            className={`section-title ${props.color}`}
          >{`(${props.section})`}</div>
          <div className={`section-number`}>{`●  ${
            props.count < 10 ? "0" + props.count : props.count
          } / ${props.total < 10 ? "0" + props.total : props.total}`}</div>
        </div>
      </div>
      <div id={props.id} className={`talent-title-block-mobile`}>
        <div className="titles">
          {props.data.title.map((t, i) => {
            return (
              <div
                key={props.id + "talenttitletblock" + i}
                className={`title ${t.color} ${t.align ? t.align : ""}`}
              >
                <h1 className={`title ${t.color}`}>{t.preText}</h1>
                <h1>
                  <img src={t.image} alt="" />
                  {t.text}
                </h1>
              </div>
            );
          })}
        </div>
        <div className="small-text">{props.data.smallText}</div>
        <div className="section-labels">
          <div
            className={`section-title ${props.color}`}
          >{`(${props.section})`}</div>
          <div className={`section-number`}>{`●  ${
            props.count < 10 ? "0" + props.count : props.count
          } / ${props.total < 10 ? "0" + props.total : props.total}`}</div>
        </div>
      </div>
    </Fragment>
  );
}

export default TalentTitleBlock;
